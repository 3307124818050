import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { PROJECT_PERSIST } from "../config/GlobalKeys";
import GlobalReducer from './global'
import AutenticacaoReducer from '../views/autenticacao/reducer'
import AvaliacoesReducer from '../views/avaliacoes/reducer'
import ItensReducer from '../views/itens/reducer'
import AlunoAvaliacaoReducer from '../views/alunoAvaliacao/reducer'
import DashboardReducer from '../views/dashboard/reducer'

const reducers = combineReducers({
    global: GlobalReducer,
    autenticacao: AutenticacaoReducer,
    dashboard: DashboardReducer,
    avaliacoes: AvaliacoesReducer,
    itens: ItensReducer,
    alunoAvaliacao: AlunoAvaliacaoReducer,
})

const persistConfig = {
    key: PROJECT_PERSIST,
    storage,
    whitelist: ['global']
}

const persistReducers = persistReducer(persistConfig, reducers)

export const store = configureStore({
    reducer: persistReducers,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export const persistor = persistStore(store)
