export const PROJECT_PERSIST = "_mb_bncc";
export const APP_TOKEN = 'dgubuGJKbkugKIbUGibG80ubhBV7888JHBj77bkjmvgvJHV80KJlkjbnkj8';
export const VERSAO_WEB = '0.0.1'
export const KEY_USER_BNCC_APP = 'user@bnccapp@mobimark'

export const KEY_RESPOSTAS_AVALIACAO = 'resposta@avaliacao@bnccapp@mobimark'

export const APP_KEY = '29A1E'


// let obj = {
//     'avaliacao_id@user_id': {
//         avaliacao_id: 'alternativa_id'
//     }
// }