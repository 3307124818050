import ApiRequest from "../../../api/ApiRequest";
import { changeCadastro, changeCidades, changeEstados, changeLogin } from "../reducer";
import { CADASTRA_USUARIO_WS, CIDADES_WS, ENVIA_EMAIL_WS, LOGIN_LEGADO_WS, RESETA_SENHA_WS } from "../../../api/routes";
import { PERFIS } from "../../../config/constants";
import toastr from "toastr";


export default class AutenticacaoApi extends ApiRequest {

    static login(obj, ignoreCache, params = {}, action) {
        return (dispatch) => {
            const success = (obj) => {
                if (obj && obj.id) {
                    action(obj);
                }
                dispatch(changeLogin({ loading: false }))
            }

            const error = (errorMsg) => {
                dispatch(changeLogin({ loading: false }))
            }

            return super.request(params, LOGIN_LEGADO_WS, success, error)
        };
    }

    static getEstados() {
        return (dispatch) => {
            const estados = [
                { value: 'AC', label: 'Acre' },
                { value: 'AL', label: 'Alagoas' },
                { value: 'AP', label: 'Amapá' },
                { value: 'AM', label: 'Amazonas' },
                { value: 'BA', label: 'Bahia' },
                { value: 'CE', label: 'Ceará' },
                { value: 'DF', label: 'Distrito Federal' },
                { value: 'ES', label: 'Espírito Santo' },
                { value: 'GO', label: 'Goías' },
                { value: 'MA', label: 'Maranhão' },
                { value: 'MT', label: 'Mato Grosso' },
                { value: 'MS', label: 'Mato Grosso do Sul' },
                { value: 'MG', label: 'Minas Gerais' },
                { value: 'PA', label: 'Pará' },
                { value: 'PB', label: 'Paraíba' },
                { value: 'PR', label: 'Paraná' },
                { value: 'PE', label: 'Pernambuco' },
                { value: 'PI', label: 'Piauí' },
                { value: 'RJ', label: 'Rio de Janeiro' },
                { value: 'RN', label: 'Rio Grande do Norte' },
                { value: 'RS', label: 'Rio Grande do Sul' },
                { value: 'RO', label: 'Rondônia' },
                { value: 'RR', label: 'Roraíma' },
                { value: 'SC', label: 'Santa Catarina' },
                { value: 'SP', label: 'São Paulo' },
                { value: 'SE', label: 'Sergipe' },
                { value: 'TO', label: 'Tocantins' },
            ]

            dispatch(changeEstados({
                loading: false,
                dados: estados
            }))
        };
    }

    static getCidades(obj, ignoreCache, params = {}) {
        return (dispatch) => {
            const success = (obj) => {
                dispatch(changeCidades({
                    loading: false,
                    dados: obj.map(estado => ({ value: estado.id, label: estado.nome }))
                }))
            }

            const error = (errorMsg) => {
                dispatch(changeCidades({ loading: false }))
            }

            let url = `${CIDADES_WS}?uf=${params.uf}&limit=0`

            return super.request({ ...params }, url, success, error, true, 'get')
        };
    }

    static cadastraUsuario(obj, ignoreCache, params = {}, action) {
        return (dispatch) => {
            const success = (obj) => {
                dispatch(changeCadastro({ loading: false }))
                if (obj && obj.id) {
                    action(obj);
                }
            }

            const error = (e, error) => {
                if (e && e.errors && error) {
                    Object.keys(e.errors).forEach(key => {
                        let msg = e.errors[key];
                        toastr.warning(msg)
                    })
                }
                dispatch(changeCadastro({ loading: false }))
            }

            return super.request({
                ...params,
                run_login: 1,
                // data_nascimento: params.data_nascimento.split('/').reverse().join('-'),
            }, CADASTRA_USUARIO_WS, success, error)
        };
    }

    static enviaEmail(obj, ignoreCache, params = {}, action) {
        return (dispatch) => {
            const success = (obj) => {
                if (obj.code) {
                    action(obj.code)
                    toastr.success(obj.msg)
                }
            }

            const error = (errorMsg) => {
                action()
            }

            return super.request(params, ENVIA_EMAIL_WS, success, error, true)
        };
    }

    static resetaSenha(obj, ignoreCache, params = {}, action) {
        return (dispatch) => {
            const success = (obj) => {
                toastr.success("Senha alterada com sucesso!")
                action(true)
            }

            const error = (errorMsg) => {
                action(false)
            }

            return super.request(params, RESETA_SENHA_WS, success, error, true)
        };
    }


}
