import { createSlice } from "@reduxjs/toolkit";

const INITAL_STATE = {
  avaliacoes: null,
  avaliacaoDetalhes: null,
  avaliacao: {
    id: null,
    data_aplicacao_inicio: "",
    data_aplicacao_fim: "",
    itens_selecionados: {},
    itens_lista: [],
    num_questoes: "",
    descricao: "",
    ensino_id: "",
    serie_ano_id: "",
    serie_ano_sigla: "",
    formError: {},
    campos_selecionados: [],
    loading: false,
  },
  ensinos: null,
  campos_experiencia: null,
  series: null,
  faixas: null,
  habilidades: null,
  itens: null,
  respostasAvaliacao: null,
  avaliacoesAlunoItens: null,
};

const avaliacoesReducer = createSlice({
  name: "avaliacoesReducer",
  initialState: INITAL_STATE,
  reducers: {
    listaAvaliacoes: (state, action) => {
      if (action.payload) {
        if (action.payload.dados) {
          if (action.payload.dados.current_page === 1) {
            state.avaliacoes = {
              ...action.payload,
              dados: { ...action.payload.dados, listAvaliacoes: [...action.payload.dados.data] },
            };
          } else {
            state.avaliacoes = {
              ...action.payload,
              dados: {
                ...action.payload.dados,
                listAvaliacoes: [...state.avaliacoes.dados.listAvaliacoes, ...action.payload.dados.data],
              },
            };
          }
        } else {
          state.avaliacoes = {
            ...action.payload,
            dados: {
              listAvaliacoes: state?.avaliacoes?.dados?.listAvaliacoes ? state.avaliacoes.dados.listAvaliacoes : [],
              total: null,
            },
          };
        }
      } else {
        state.avaliacoes = INITAL_STATE.itens;
      }
    },
    listaEnsinos: (state, action) => {
      state.ensinos = action.payload ? { ...action.payload } : null;
    },
    listaCamposExperiencias: (state, action) => {
      state.campos_experiencia = action.payload ? { ...action.payload } : null;
    },
    listaSeries: (state, action) => {
      state.series = action.payload ? { ...action.payload } : null;
    },
    alteraAvaliacao: (state, action) => {
      if (action.payload) {
        state.avaliacao = { ...state.avaliacao, ...action.payload };
      } else {
        state.avaliacao = INITAL_STATE.avaliacao;
      }
    },
    listaHabilidades: (state, action) => {
      state.habilidades = action.payload ? { ...action.payload } : null;
    },
    alteraDetalhes: (state, action) => {
      state.avaliacaoDetalhes = action.payload ? { ...action.payload } : null;
    },
    listaItems: (state, action) => {
      if (action.payload) {
        if (action.payload.dados) {
          if (action.payload.dados.current_page === 1) {
            state.itens = {
              ...action.payload,
              dados: { ...action.payload.dados, listItens: [...action.payload.dados.data] },
            };
          } else {
            state.itens = {
              ...action.payload,
              dados: {
                ...action.payload.dados,
                listItens: [...state.itens.dados.listItens, ...action.payload.dados.data],
              },
            };
          }
        } else {
          state.itens = {
            ...action.payload,
            dados: { listItens: state?.itens?.dados?.listItens ? state.itens.dados.listItens : [] },
          };
        }
      } else {
        state.itens = INITAL_STATE.itens;
      }
    },
    listaRespostas: (state, action) => {
      if (action.payload) {
        if (action.payload.dados) {
          if (action.payload.dados.current_page === 1) {
            state.respostasAvaliacao = {
              ...action.payload,
              dados: { ...action.payload.dados, listRespostasAvaliacao: [...action.payload.dados.data] },
            };
          } else {
            state.respostasAvaliacao = {
              ...action.payload,
              dados: {
                ...action.payload.dados,
                listRespostasAvaliacao: [
                  ...state.respostasAvaliacao.dados.listRespostasAvaliacao,
                  ...action.payload.dados.data,
                ],
              },
            };
          }
        } else {
          state.respostasAvaliacao = {
            ...action.payload,
            dados: {
              listRespostasAvaliacao: state?.respostasAvaliacao?.dados?.listRespostasAvaliacao
                ? state.respostasAvaliacao.dados.listRespostasAvaliacao
                : [],
              total: null,
            },
          };
        }
      } else {
        state.respostasAvaliacao = INITAL_STATE.respostasAvaliacao;
      }
    },
    listaAvaliacoesAlunoItens: (state, action) => {
      state.avaliacoesAlunoItens = action.payload ? { ...action.payload } : null;
    },
  },
});

export const {
  listaAvaliacoes,
  listaEnsinos,
  alteraAvaliacao,
  listaCamposExperiencias,
  listaItems,
  listaSeries,
  listaHabilidades,
  alteraDetalhes,
  listaRespostas,
  listaAvaliacoesAlunoItens,
} = avaliacoesReducer.actions;
export default avaliacoesReducer.reducer;
