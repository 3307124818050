
import React, { useRef } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Button, CardMedia } from "@mui/material";
import { FaWindowClose } from 'react-icons/fa';
import { useDrag, useDrop } from "react-dnd";
import '../../styles/avaliacoes.css'

const CardItemQuestaoSelecionado = ({ dado_ref, index, item, handleRemoveQuestao, isDragging, questaoInicial, visualizarQuestao }) => {
    
    return (
        <div ref={dado_ref} style={{ minWidth: 200, opacity: isDragging ? 0.5 : 1 }}>
            <Card sx={{ background: "#fff", cursor: 'pointer', borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}>
                <CardContent sx={{ width: '100%', borderBottom: 'solid 1px #ccc', padding: '12px 16px 12px 16px' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography color="#474747" fontSize={14} fontWeight={'600'}>Questão {questaoInicial + index}</Typography>
                        <span style={{ cursor: 'pointer' }} onClick={() => { handleRemoveQuestao(item, index) }}>
                            <FaWindowClose size={'20px'} onMouseOver={({ target }) => target.style.color = "#d61c1c"}
                                onMouseOut={({ target }) => target.style.color = "#9c1717"} color={'#9c1717'} />
                        </span>
                    </div>
                </CardContent>
                <div className="card-avaliacao-item-selecionado" >
                    {item.descricao &&
                        <div className="body">
                            <div className='descricao' dangerouslySetInnerHTML={{ __html: item.descricao }} />
                        </div>
                    }
                    {(item.img && !item.descricao) &&
                        <CardMedia
                            component="img"
                            height="140"
                            image={item.img}
                            style={{ objectFit: 'cover', objectPosition: 'left' }}
                        />
                    }
                    <div className='footer'>
                        <span className='badge-footer'>{item?.alternativas?.length} alternativas</span>
                    </div>
                </div>
            </Card>
            <Button
                variant="contained"
                sx={{
                    borderTopRightRadius: 0,
                    borderTopLeftRadius: 0,
                    fontSize: 12,
                    marginBottom: 2,
                    width: '100%'
                }}
                onClick={() => { visualizarQuestao(item) }} >
                Visualizar
            </Button>
        </div>
    );
}


const DndCard = ({ index, item_quest, handleRemoveQuestao, moveCard, questaoInicial, visualizarQuestao }) => {
    const ref = useRef(null);

    const [, drop] = useDrop({
        accept: 'div',
        hover(item, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;

            if (dragIndex === hoverIndex) {
                return;
            }

            const hoverBoundingRect = ref.current.getBoundingClientRect();

            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

            const clientOffset = monitor.getClientOffset();

            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }

            moveCard(dragIndex, hoverIndex);
            item.index = hoverIndex;
        }
    });
    const [{ isDragging }, drag] = useDrag({
        type: 'div',
        item: { id: index, index: index },
        collect: monitor => ({
            isDragging: monitor.isDragging()
        })
    });
    drag(drop(ref));
    /* const opacity = isDragging ? 0 : 1;
    drag(drop(ref)); */
    return (
        <CardItemQuestaoSelecionado
            dado_ref={ref}
            index={index}
            item={item_quest}
            handleRemoveQuestao={handleRemoveQuestao}
            isDragging={isDragging}
            visualizarQuestao={visualizarQuestao}
            questaoInicial={questaoInicial} />
    );
};
export default DndCard;
