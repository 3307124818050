import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function LinearProgressCustom(props) {

    let style = props.style ? props.style : {}

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', ...style }}>
            <Box sx={{ width: (props.width ? props.width : '100%'), mr: 1 }}>
                <LinearProgress variant="determinate" color='inherit' sx={{ height: 10, borderRadius: 2, color: props.value === 100 ? 'green' : '#1976d2' }} {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body1" color="gray" fontWeight={'bold'}>{(props.arredonda ? Math.round(
                    props.value,
                ) : props.value) + '%'}</Typography>
            </Box>
        </Box>
    );
}

