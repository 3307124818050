export const calcularPorcentagem = (acertos, erros) => {
    const total = acertos + erros;

    if (total === 0) {
        return {acerto: 0, erro: 0};
    }

    const percentualAcertos = (acertos / total) * 100;
    const percentualErros = (erros / total) * 100;

    return {acerto: percentualAcertos.toFixed(2), erro: percentualErros.toFixed(2)};
    
}