import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, LinearProgress, Menu, MenuItem, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import logo from '../../../assets/imgs/logo.png'
import ContainerItem from '../../../components/ContainerItem';
import Autenticacao from './autenticacao';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import AlunoAvaliacaoServices from '../AlunoAvaliacaoServices';
import LoadingData from '../../../components/LoadingData'
import { getDataFormatadaDaDataCompleta } from '../../../utils/formatDate';
import { IoPerson, IoLogOut, IoChevronDown } from 'react-icons/io5'
import { setUser } from '../../../reducers/global';
import toastr from 'toastr';
import useLocalStorage from '../../../hooks/useLocalStorage';
import { KEY_RESPOSTAS_AVALIACAO, KEY_USER_BNCC_APP } from '../../../config/GlobalKeys';
import Item from './itens';
import LinearProgressCustom from '../../../components/LinearProgressCustom';
import './aluno.css'
import { changeAvaliacaoAluno, changeRespostasAluno } from '../reducer';
import { PERFIS } from '../../../config/constants'
import { formataData } from '../../../utils/formatDate'
import { verificaPrazo } from '../../../utils/validations';

const Painel = () => {
    const dispatch = useDispatch()

    const { hash } = useParams()
    const { remove, set, get } = useLocalStorage()
    const { user } = useSelector(state => state.global);
    const { avaliacaoAluno, respostasAluno } = useSelector(state => state.alunoAvaliacao)

    const [alertFinalizaAvaliacao, setAlertFinalizaAvaliacao] = useState()
    const [loadingFinaliza, setLoadingFinaliza] = useState(false)

    const [respostas, setRespostas] = useState({})

    useEffect(() => {
        if (!user) {
            dispatch(changeRespostasAluno(null))
            dispatch(changeAvaliacaoAluno(null))
        }
        AlunoAvaliacaoServices.getAvaliacaoPorHash(dispatch, { hash })
    }, [user])

    useEffect(() => {
        if (user && avaliacaoAluno?.dados) {
            buscaRespostasAluno()
        }
    }, [user, avaliacaoAluno?.dados])

    const buscaRespostasAluno = () => {
        let params = {
            limit: 0,
            avaliacao_id: avaliacaoAluno?.dados?.id,
            aluno_id: user.relacionado.id
        }
        AlunoAvaliacaoServices.getRespostasAluno(dispatch, params)
    }

    useEffect(() => {
        if (
            user &&
            avaliacaoAluno?.dados &&
            respostasAluno?.dados &&
            respostasAluno.dados.length === 0
        ) {
            let respostasStorage = get(KEY_RESPOSTAS_AVALIACAO)
            let key = `${user.relacionado.id}@${avaliacaoAluno?.dados?.id}`
            if (respostasStorage && respostasStorage[key]) {
                setRespostas(respostasStorage[key]);
            }
        }
    }, [user, avaliacaoAluno?.dados, respostasAluno?.dados])

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const finalizaAvaliacao = () => {
        let params = {
            avaliacao_id: avaliacaoAluno.dados.id
        }

        Object.keys(respostas).forEach(res => {
            params[`respostas[${res}]`] = respostas[res];
        })

        const callbackSuccess = () => {
            setLoadingFinaliza(false)
            setAlertFinalizaAvaliacao(undefined)
            buscaRespostasAluno()
        }

        const callbackError = () => setLoadingFinaliza(false)

        setLoadingFinaliza(true);

        AlunoAvaliacaoServices.enviaRespostas(dispatch, params, callbackSuccess, callbackError)
    }

    const logout = () => {
        remove(KEY_USER_BNCC_APP);
        dispatch(setUser(null))
        toastr.success('Usuário deslogado!')
    }

    const alertaFinalizaAvaliacao = () => {
        let temTodasAsRespostas = avaliacaoAluno.dados.itens.length === Object.keys(respostas).length
        let msg = temTodasAsRespostas ?
            'Você deseja finalizar esta avaliação?' :
            'Você ainda não respondeu todas as questões, deseja finalizar assim esta avaliação?'
        setAlertFinalizaAvaliacao(msg)
    }

    const escolheAlternativa = (val) => {

        let avaliacoes = get(KEY_RESPOSTAS_AVALIACAO);
        avaliacoes = avaliacoes ? avaliacoes : {}

        let key = `${user.relacionado.id}@${avaliacaoAluno?.dados?.id}`
        let respostasAtt = { ...respostas, ...val }

        set(KEY_RESPOSTAS_AVALIACAO, { ...avaliacoes, [key]: respostasAtt })
        setRespostas(respostasAtt);
    }

    const renderAvaliacaoFinalizada = () => {
        if (!user || !respostasAluno || !respostasAluno.dados || respostasAluno.dados.length === 0) {
            return null
        }

        if (!verificaPrazo(avaliacaoAluno.dados.data_aplicacao_inicio, avaliacaoAluno.dados.data_aplicacao_fim)) {
            return <Alert color="warning">Avaliação fora do período de aplicação!</Alert>
        }

        let date = new Date(respostasAluno.dados[0].created_at)

        let dataFinalizacao = formataData(date)

        return (
            <>
                <HeaderInfo avaliacao={avaliacaoAluno.dados} />
                <Alert severity='success' style={{ margin: '20px 0px 0px' }} >Avaliação finalizada em {dataFinalizacao}!</Alert>
            </>

        )
    }

    const renderAvaliacao = () => {
        if (!avaliacaoAluno || !avaliacaoAluno.dados || !avaliacaoAluno.dados.itens || !user) {
            return null
        }

        if ((respostasAluno && respostasAluno.dados && respostasAluno.dados.length > 0) || (respostasAluno && respostasAluno.loading)) {
            return null
        }

        if (!verificaPrazo(avaliacaoAluno.dados.data_aplicacao_inicio, avaliacaoAluno.dados.data_aplicacao_fim)) {
            return <Alert color="warning">Avaliação fora do período de aplicação!</Alert>
        }

        return (
            <>
                <HeaderInfo avaliacao={avaliacaoAluno.dados} />
                {user.perfil !== PERFIS.PROFESSOR &&
                    <div className='div-porcentagem'>
                        <LinearProgressCustom
                            value={(Object.keys(respostas).length * 100) / [...avaliacaoAluno.dados.itens].length}
                            arredonda={1} />
                    </div>
                }
                {avaliacaoAluno.dados.itens.map((i, k) =>
                    <Item
                        key={k}
                        item={{ ...i }}
                        index={k + 1}
                        respostas={respostas}
                        isProfessor={user.perfil === PERFIS.PROFESSOR}
                        onClick={val => user.perfil !== PERFIS.PROFESSOR && escolheAlternativa(val)}
                    />)
                }
                {user.perfil !== PERFIS.PROFESSOR &&
                    <Button
                        onClick={alertaFinalizaAvaliacao}
                        style={{ marginTop: 10 }}
                        fullWidth
                        variant='contained'>
                        Finalizar avaliação
                    </Button>
                }
                <Dialog
                    open={!!alertFinalizaAvaliacao}
                    onClose={() => setAlertFinalizaAvaliacao(undefined)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description" >
                    <DialogTitle
                        style={{ fontWeight: 'bold' }}
                        id="alert-dialog-title">
                        {"Finalizar avaliação"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {alertFinalizaAvaliacao}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={loadingFinaliza}
                            color='error'
                            onClick={() => setAlertFinalizaAvaliacao(undefined)}>
                            NÃO
                        </Button>
                        <Button
                            disabled={loadingFinaliza}
                            onClick={() => finalizaAvaliacao()}
                            autoFocus>
                            {loadingFinaliza ? 'FINALIZANDO AVALIAÇÃO...' : 'SIM'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        )

    }

    const renderAutenticacao = () => {
        if (!avaliacaoAluno || !avaliacaoAluno.dados || !!user) {
            return null;
        }

        return (
            <>
                <HeaderInfo avaliacao={avaliacaoAluno.dados} />

                {verificaPrazo(avaliacaoAluno.dados.data_aplicacao_inicio, avaliacaoAluno.dados.data_aplicacao_fim) ?
                    <Autenticacao />
                    :
                    <Alert color="warning">Avaliação fora do período de aplicação!</Alert>
                }
            </>
        )
    }

    return (
        <div className='container-aluno'>
            <div className='container'>
                <div className='header' >
                    <div className='header-title'>
                        <img src={logo} style={{ height: 60 }} />
                        <div style={{ marginLeft: 10 }}>
                            <Typography variant='h6' style={{ fontWeight: '300', }}>{user ? 'AVALIAÇÃO' : 'ACESSAR AVALIAÇÃO'}</Typography>
                            <Typography variant='subtitle2' style={{ fontWeight: '300', marginTop: -5, fontSize: 12, color: '#666' }}>
                                {user ? 'Selecione as respostas e clique em finalizar avaliação, para enviar as suas respostas' : 'Insira seus dados para que tenha acesso a avaliação'}
                            </Typography>
                        </div>
                    </div>
                    {user &&
                        <div>
                            <div className='div-avatar' onClick={handleClick}>
                                <IoPerson size={18} />
                                <div style={{ fontSize: 12, marginLeft: 5, fontWeight: '300' }} >{user.nome}</div>
                                <IoChevronDown size={14} style={{ marginLeft: 5 }} />
                            </div>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{ 'aria-labelledby': 'basic-button', }}>
                                <MenuItem onClick={() => { logout(); handleClose() }}>
                                    Sair
                                </MenuItem>
                            </Menu>
                        </div>
                    }
                </div>
                {avaliacaoAluno && <LoadingData obj={avaliacaoAluno} msg={"Buscando avaliação..."} />}
                {respostasAluno && <LoadingData obj={respostasAluno} msg={"Buscando respostas..."} />}
                {renderAutenticacao()}
                {renderAvaliacao()}
                {renderAvaliacaoFinalizada()}
            </div>
        </div>
    )
}

export default Painel;


const HeaderInfo = ({ avaliacao }) => {
    return (
        <>
            <Grid container spacing={1} style={{ marginTop: 10 }} >
                <Grid item xs={12}>
                    <ContainerItem
                        title={"Descrição:"}
                        subtitle={avaliacao.descricao}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ContainerItem
                        title={"Professor:"}
                        subtitle={avaliacao.professor.nome}
                    />
                </Grid>
                <Grid item xs={12} md={3} sm={3}>
                    <ContainerItem
                        title={"Data início:"}
                        subtitle={getDataFormatadaDaDataCompleta(avaliacao.data_aplicacao_inicio)}
                    />
                </Grid>
                <Grid item xs={12} md={3} sm={3}>
                    <ContainerItem
                        title={"Data final:"}
                        subtitle={getDataFormatadaDaDataCompleta(avaliacao.data_aplicacao_fim)}
                    />
                </Grid>
                <Grid item xs={12} md={3} sm={3}>
                    <ContainerItem
                        title={"Ensino:"}
                        subtitle={avaliacao.ensino.nome}
                    />
                </Grid>
                <Grid item xs={12} md={3} sm={3}>
                    <ContainerItem
                        title={"Ano:"}
                        subtitle={avaliacao.serie_ano.nome}
                    />
                </Grid>
            </Grid>
            <Divider style={{ margin: '20px 0px 0px 0px' }} />
        </>
    )
}