import ApiRequest from "../../../api/ApiRequest";
import {
  alteraAvaliacao,
  alteraDetalhes,
  listaAvaliacoes,
  listaAvaliacoesAlunoItens,
  listaCamposExperiencias,
  listaEnsinos,
  listaHabilidades,
  listaItems,
  listaRespostas,
  listaSeries,
} from "../reducer";
import {
  ANOS_WS,
  AVALIACAO_ALUNO_ITENS,
  AVALIACOES_ALUNO_WS,
  AVALIACOES_WS,
  CAMPOS_EXPERIENCIA_WS,
  ENSINOS_WS,
  HABILIDADES_WS,
  ITENS_WS,
  SHORT_LINK_WS,
} from "../../../api/routes";
import toastr from "toastr";
import AvaliacoesServices from "../AvaliacoesServices";

export default class AutenticacaoApi extends ApiRequest {
  static getAvaliacoes(obj, ignoreCache, params) {
    return (dispatch) => {
      const onResponse = (obj, success) => {
        dispatch(listaAvaliacoes(obj));
      };

      let url = super.getFormatUrl(AVALIACOES_WS, params);
      url = `${url}&with[0]=ensino&with[1]=itens&with[2]=serie_ano&withCount=respostas`;

      return super.requestAuth(
        url,
        { method: "get" },
        obj,
        onResponse,
        "Não foi possível buscar avaliações, tente novamente."
      );
    };
  }

  static getEnsinos(obj, ignoreCache, params) {
    return (dispatch) => {
      const onResponse = (obj, success) => {
        dispatch(listaEnsinos(obj));
      };

      let url = super.getFormatUrl(ENSINOS_WS, params);
      return super.requestAuth(
        url,
        { method: "get" },
        obj,
        onResponse,
        "Não foi possível buscar ensinos, tente novamente."
      );
    };
  }

  static getCamposExperiencia(obj, ignoreCache, params = {}) {
    return (dispatch) => {
      const onResponse = (obj, success) => {
        dispatch(listaCamposExperiencias(obj));
      };

      let url = super.getFormatUrl(CAMPOS_EXPERIENCIA_WS, params);
      return super.requestAuth(url, { method: "get" }, obj, onResponse, "Não foi possível buscar ensinos");
    };
  }
  static getSeries(obj, ignoreCache, params = {}) {
    return (dispatch) => {
      const onResponse = (obj, success) => {
        dispatch(listaSeries(obj));
      };

      let url = super.getFormatUrl(ANOS_WS, params);
      return super.requestAuth(url, { method: "get" }, obj, onResponse, "Não foi possível buscar series");
    };
  }

  static getHabilidades(obj, ignoreCache, params = {}) {
    return (dispatch) => {
      const onResponse = (obj) => {
        let dados = [];
        if (obj.dados) {
          dados = obj.dados.map((habilidade) => ({
            ...habilidade,
            nome: `${habilidade.codigo}: ${habilidade.descricao}`,
          }));
        }
        dispatch(listaHabilidades({ ...obj, dados }));
      };

      let url = super.getFormatUrl(HABILIDADES_WS, params);
      return super.requestAuth(url, { method: "get" }, obj, onResponse, "Não foi possível buscar series");
    };
  }

  static getItems(obj, ignoreCache, params = {}) {
    return (dispatch) => {
      const onResponse = (obj, success) => {
        dispatch(listaItems(obj));
      };

      let url = super.getFormatUrl(ITENS_WS, params);

      url = `${url}&with[0]=ensino&with[1]=habilidade&with[2]=alternativas&with[3]=habilidade.campo_experiencia`;

      return super.requestAuth(
        url,
        { method: "get" },
        obj,
        onResponse,
        "Não foi possível buscar itens, tente novamente."
      );
    };
  }

  static salvaAvaliacao(obj, ignoreCache, params = {}, callback) {
    return (dispatch) => {
      const success = (response) => {
        if (response) {
          toastr.success(params.id ? "Avaliação alterada com sucesso" : "Avaliação cadastrada com sucesso.");
          dispatch(alteraAvaliacao({ loading: false }));
          callback();
        }
      };
      const error = (msg) => {
        toastr.warning(msg);
        dispatch(alteraAvaliacao({ loading: false }));
      };

      dispatch(alteraAvaliacao({ loading: true }));

      let url = AVALIACOES_WS;

      if (params.id) {
        url += `/${params.id}`;
        params["_method"] = "PUT";
      }

      return super.requestCadastro(params, url, success, error, true);
    };
  }

  static excluirAvaliacao(obj, ignoreCache, params, action) {
    return (dispatch) => {
      const onResponse = (obj, success) => {
        if (success) {
          toastr.success("Avaliação excluida com sucesso");
          action(true);
        }

        if (obj.error) {
          toastr.warning(obj.msgError);
          action(false);
        }
      };

      let url = `${AVALIACOES_WS}/${params.id}`;

      return super.requestAuth(
        url,
        { method: "delete" },
        obj,
        onResponse,
        "Não foi possível deletar avaliação, tente novamente."
      );
    };
  }

  static getDetalhes(obj, ignoreCache, params) {
    return (dispatch) => {
      const onResponse = (obj, success) => {
        let dados = AvaliacoesServices.formataAvaliacaoComItens(obj?.dados);
        dispatch(alteraDetalhes({ ...obj, dados }));
      };

      let url = `${AVALIACOES_WS}/${params.id}?with[0]=professor&with[1]=avaliacao_itens.item.habilidade.campo_experiencia`;

      return super.requestAuth(
        url,
        { method: "get" },
        obj,
        onResponse,
        "Não foi possível buscar avaliação, tente novamente."
      );
    };
  }

  static getDadosAvaliacao(obj, ignoreCache, params) {
    return (dispatch) => {
      const onResponse = (obj) => {
        if (obj.dados) {
          let avaliacoes = AvaliacoesServices.formataAvaliacaoComItens(obj.dados);
          let format = AvaliacoesServices.formatAvaliacao(avaliacoes);
          dispatch(alteraAvaliacao(format));
        }
      };

      let url = `${AVALIACOES_WS}/${params.id}?with[0]=serie_ano`;
      return super.requestAuth(
        url,
        { method: "get" },
        obj,
        onResponse,
        "Não foi possível buscar avaliação, tente novamente."
      );
    };
  }

  static getRespostasAlunos(obj, ignoreCache, params) {
    return (dispatch) => {
      const onResponse = (obj, success) => {
        dispatch(listaRespostas(obj));
      };

      let url = super.getFormatUrl(AVALIACOES_ALUNO_WS, params);
      url = `${url}&with[0]=avaliacao&with[1]=aluno&with[2]=status`;

      return super.requestAuth(
        url,
        { method: "get" },
        obj,
        onResponse,
        "Não foi possível buscar respostas, tente novamente."
      );
    };
  }

  static getAvaliacaoAlunoItens(obj, ignoreCache, params) {
    return (dispatch) => {
      const onResponse = (obj, success) => {
        let dados = AvaliacoesServices.formataAvaliacaoAlunoItens(obj?.dados);
        dispatch(listaAvaliacoesAlunoItens({ ...obj, dados }));
      };

      let url = super.getFormatUrl(AVALIACAO_ALUNO_ITENS, params);
      return super.requestAuth(
        url,
        { method: "get" },
        obj,
        onResponse,
        "Não foi possível buscar respostas, tente novamente."
      );
    };
  }
  static getShortLink(obj, ignoreCache, params, callback) {
    return async (dispatch) => {
      // let url = SHORT_LINK_WS.replace(":url", params.url);
      let url = SHORT_LINK_WS;
      let options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, DELETE",
          "Access-Control-Allow-Headers": "Content-Type, Authorization, X-Requested-With",
        },
        body: JSON.stringify({url: params.url}),
      }

      let response = await fetch(url, options);
      try {
        let resJson = await response.json();
        if (resJson.ok) {
          callback(resJson.urlEncurtada);
        } else {
          callback();
        }
      } catch (e) {
        callback();
      }
    };
  }
}
