import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useState } from "react";

const ModalConfirmCustom = ({ open, close, text, title, onConfirm, textButtonConfirm = 'SIM', textButtonCancel = 'NÃO', loading, }) => {


    return (
        <Dialog
            open={open}
            onClose={close}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">
                {title}
            </DialogTitle>
            <DialogContent>
                {text &&
                    <DialogContentText id="alert-dialog-description">
                        {text}
                    </DialogContentText>
                }
            </DialogContent>
            <DialogActions>
                <Button
                    color="error"
                    disabled={loading}
                    onClick={close}>
                    {textButtonCancel}
                </Button>
                <Button
                    disabled={loading}
                    onClick={onConfirm}
                    autoFocus>
                    {!loading ? textButtonConfirm : "Carregando..."}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ModalConfirmCustom