import { KEY_USER_BNCC_APP } from "./GlobalKeys"

export async function setUser(user) {
    return localStorage.setItem(KEY_USER_BNCC_APP, JSON.stringify(user))
}

export async function getUser() {
    let user = await localStorage.getItem(KEY_USER_BNCC_APP)
    return user ? JSON.parse(user).value : null
}

export function logout() {
    localStorage.clear()
}