import Service from "../../api/Service";
import ItensApi from "./api/ItensApi";
import { alteraErro } from "./reducer";
import { validaFormItem } from "./validation";
import toastr from "toastr";

export default class ItensServices {
  static getEnsinos(dispatch, params) {
    Service.factoryRequest(dispatch, ItensApi.getEnsinos, true, params);
  }

  static getItens(dispatch, params) {
    Service.factoryRequest(dispatch, ItensApi.getItens, true, params);
  }

  static getDadosItem(dispatch, params, callback) {
    Service.factoryRequest(dispatch, ItensApi.getDadosItem, true, params, callback);
  }

  static getHabilidades(dispatch, params) {
    Service.factoryRequest(dispatch, ItensApi.getHabilidades, true, params);
  }

  static excluirItem(dispatch, params, callback, callbackError) {
    const action = (success) => {
      if (success) {
        callback();
      } else {
        callbackError();
      }
    };

    Service.factoryRequest(dispatch, ItensApi.excluirItem, true, params, action);
  }

  static getDetalhesItem(dispatch, params) {
    Service.factoryRequest(dispatch, ItensApi.getDetalhesItem, true, params);
  }

  static salvaItem(dispatch, params, callback) {
    let validation = validaFormItem(params);
    if (validation) {
      toastr.warning("Há problemas no formulário!");
      dispatch(alteraErro(validation));
      callback(true);
      return;
    }
    dispatch(alteraErro());
    Service.factoryRequest(dispatch, ItensApi.salvarItem, true, params, callback);
  }

  static formataItem(dados) {
    try {
      let alternativasSorted = [...dados.alternativas].sort((a, b) => (b.sequencial < a.sequencial ? 1 : -1));
      let gabarito = alternativasSorted.findIndex((a) => dados.item_alternativa_id === a.id);

      let item = {
        id: dados.id,
        descricao: dados.descricao,
        descricaoImg: dados.img,
        ensino: dados.ensino,
        habilidade: ItensServices.formataHabilidade(dados.habilidade),
        visibilidade: dados.visibilidade === 0 ? true : false,
        gabarito,
        alternativas: alternativasSorted.map((a) => ({
          id: a.id,
          descricao: a.descricao,
          descricaoImg: a.img,
          sequencial: a.sequencial,
        })),
        item_alternativa_id: dados.item_alternativa_id,
        professor_id: dados.professor_id,
        professor: dados.professor,
      };
      return item;
    } catch (e) {
      return null;
    }
  }

  static formataHabilidade(habilidade) {
    return { ...habilidade, nome: `${habilidade.codigo}: ${habilidade.descricao}` };
  }
}
