import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import { MdClose } from 'react-icons/md'
import '../../index.css'

const StyledDialog = styled(Dialog)`
    .css-hz1bth-MuiDialog-container{
        align-items: flex-start;
    }
   & > .MuiDialog-container > .MuiPaper-root {
     max-width: 90vw;
     min-width: 50vw;
   }
 `;


const ModalTemplate = ({ children, open, title, imgTitle, onConfirm, closeModal, subHeader, habilitarConfirmacao, isItem, style = {}, funcDownload, loading, textConfirm, footerVisible = true }) => {

    return <StyledDialog
        open={open}
        scroll='paper'
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', flexDirection: "row", alignItems: 'center', justifyContent: 'center', height: '95vh', marginTop: '40px', ...style }}>
        <DialogTitle style={{ padding: 16 }} >
            <Grid container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}  >
                    {imgTitle}
                    <Typography variant="h5" component="div">
                        {title}
                    </Typography>
                </div>

                {habilitarConfirmacao && <MdClose size={25} style={{ cursor: 'pointer' }} onClick={closeModal} />}
            </Grid>
            <Grid container>
                {subHeader}
            </Grid>
        </DialogTitle>
        <DialogContent dividers={true} sx={{ background: isItem ? '#ededed' : 'white' }} className={"modal-template-body"}>
            {children}
        </DialogContent>
        {footerVisible ?
            habilitarConfirmacao ?
                <DialogActions>
                    <Grid container justifyContent='flex-end' >
                        <Button
                            disabled={loading}
                            variant='contained'
                            sx={{
                                width: 'min-content',
                                background: '#6c757d',
                                padding: '6px',
                                marginRight: '1rem',
                                "&:hover": {
                                    background: '#54585c',
                                }
                            }}
                            onClick={closeModal}>
                            Cancelar
                        </Button>
                        <Button
                            disabled={loading}
                            variant='contained'
                            sx={{
                                width: 'min-content',
                                background: '#1976d2',
                                padding: '6px',
                            }}
                            onClick={onConfirm}>
                            {loading ? 'Aguarde...' : textConfirm ? textConfirm : 'Confirmar'}
                        </Button>
                    </Grid>
                </DialogActions>
                :
                <DialogActions >
                    <Grid container justifyContent='flex-end' >
                        <Button
                            disabled={loading}
                            variant='contained'
                            sx={{
                                width: 'min-content',
                                background: '#6c757d',
                                padding: '6px',
                                "&:hover": {
                                    background: '#54585c',
                                }
                            }}
                            onClick={closeModal}>
                            FECHAR
                        </Button>
                    </Grid>
                </DialogActions>
            : null}
    </StyledDialog>
}
export default ModalTemplate;