import React from 'react';

const ContainerItem = ({ children, style = {}, title, subtitle }) => {
    return (
        <div style={{ padding: 8, backgroundColor: '#dedede50', ...style }} >
            {title && <span style={{ fontWeight: 'bold', }}>{title}</span>}
            {subtitle && <div style={{ fontSize: 12, color: '#666', marginTop: 5 }} >{subtitle}</div>}
            {children}
        </div>
    )
}

export default ContainerItem;