import { Navigate, Route, Routes, } from 'react-router-dom';
import Dashboard from '../views/dashboard/components';
import Login from '../views/autenticacao/components/login';
import { AVALIACAO_ALUNO, AVALIACOES, AVALIACOES_RESPOSTAS, DASHBOARD, FORM_AVALIACAO, FORM_EDIT_AVALIACAO, FORM_EDIT_ITENS, FORM_ITENS, ITENS, NOVA_AVALIACAO, NOVA_CONTA, RECUPERA_SENHA, PERFIL, ROOT } from './HashRouter';
import PrivateRoute from './PrivateRoute';
import CadastraConta from '../views/autenticacao/components/novaconta';
import RecuperarConta from '../views/autenticacao/components/recuperasenha';
import Avaliacoes from '../views/avaliacoes/components/Avaliacoes';
import PainelFormAvaliacoes from '../views/avaliacoes/components/form/Painel';
import Items from '../views/itens/components/Itens';
import FormItem from '../views/itens/components/form/Painel';
import PainelAluno from '../views/alunoAvaliacao/components/Painel';
import AvaliacoesRespostas from '../views/avaliacoes/components/respostas';
import Perfil from '../views/perfil/components/Painel';

function RoutesApp() {
    return (
        <Routes>
            <Route
                path={ROOT}
                element={<Login />}
            />
            <Route
                path={NOVA_CONTA}
                element={<CadastraConta />}
            />
            <Route
                path={RECUPERA_SENHA}
                element={<RecuperarConta />}
            />
            <Route
                path={AVALIACAO_ALUNO}
                element={<PainelAluno />}
            />
            <Route
                path={DASHBOARD}
                element={
                    <PrivateRoute path={DASHBOARD}>
                        <Dashboard />
                    </PrivateRoute>
                }
            />
            <Route
                path={AVALIACOES}
                element={
                    <PrivateRoute path={AVALIACOES}>
                        <Avaliacoes />
                    </PrivateRoute>
                }
            />
            <Route
                path={ITENS}
                element={
                    <PrivateRoute path={ITENS}>
                        <Items />
                    </PrivateRoute>
                }
            />
            <Route
                path={FORM_ITENS}
                element={
                    <PrivateRoute path={FORM_ITENS}>
                        <FormItem />
                    </PrivateRoute>
                }
            />
            <Route
                path={FORM_EDIT_ITENS}
                element={
                    <PrivateRoute path={FORM_EDIT_ITENS}>
                        <FormItem />
                    </PrivateRoute>
                }
            />
            <Route
                path={FORM_AVALIACAO}
                element={
                    <PrivateRoute path={FORM_AVALIACAO}>
                        <PainelFormAvaliacoes />
                    </PrivateRoute>
                }
            />
            <Route
                path={FORM_EDIT_AVALIACAO}
                element={
                    <PrivateRoute path={FORM_EDIT_AVALIACAO}>
                        <PainelFormAvaliacoes />
                    </PrivateRoute>
                }
            />
            <Route
                path={AVALIACOES_RESPOSTAS}
                element={
                    <PrivateRoute path={AVALIACOES_RESPOSTAS}>
                        <AvaliacoesRespostas />
                    </PrivateRoute>
                }
            />
            <Route
                path={PERFIL}
                element={
                    <PrivateRoute path={PERFIL}>
                        <Perfil />
                    </PrivateRoute>
                }
            />
            <Route path='*' element={<Navigate to={ROOT} />} />
        </Routes>
    )
}

export default RoutesApp;
