import React, { useEffect, useState } from "react";
import {
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterIcon,
  TwitterShareButton,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  LinkedinIcon,
  LinkedinShareButton,
} from "react-share";
import ModalTemplate from "../modal/ModalTemplate";
import { MdLink } from "react-icons/md";
import "./styles.css";
import toastr from 'toastr';

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  row: {
    width: "100%",
    marginTop: 10,
  },
};

const ModalShare = ({ isOpen, closeModal, url, text, loading }) => {
  const [copy, setCopy] = useState(false);

  useEffect(() => {
    setCopy(false);
  }, [isOpen]);

  return (
    <ModalTemplate open={isOpen || loading} closeModal={closeModal} title={"Compartilhar por:"} desabilitarBotao={true}>
      <div className="nt-modal-body">
        {loading && <p>Carregando...</p>}
        {!loading && (
          <>
            <div
              onClick={() => {
                navigator.clipboard.writeText(`${text} ${url}`);
                setCopy(true);
                toastr.success('Link copiado com sucesso!')
              }}
              className="nt-modal-row"
              style={{ cursor: "pointer" }}
            >
              {copy ? <p>Link copiado!</p> : <p>Copiar link</p>}
              <div className="nt-div-link-copy">
                <MdLink color="#fff" size={30} />
              </div>
            </div>
            <WhatsappShareButton url={url} title={text} style={{ ...customStyles.row }}>
              <div className="nt-modal-row">
                <p>Whatsapp</p>
                <WhatsappIcon size={40} round={true} />
              </div>
            </WhatsappShareButton>
            <TelegramShareButton url={text + " " + url}  style={customStyles.row}>
              <div className="nt-modal-row">
                <p>Telegram</p>
                <TelegramIcon size={40} round={true} />
              </div>
            </TelegramShareButton>
            <FacebookMessengerShareButton url={url} title={text} style={customStyles.row}>
              <div className="nt-modal-row">
                <p>Mensenger</p>
                <FacebookMessengerIcon size={40} round={true} />
              </div>
            </FacebookMessengerShareButton>
            <TwitterShareButton url={url} title={text} style={customStyles.row}>
              <div className="nt-modal-row">
                <p>Twitter</p>
                <TwitterIcon size={40} round={true} />
              </div>
            </TwitterShareButton>
            <LinkedinShareButton url={url} title={text} style={customStyles.row}>
              <div className="nt-modal-row">
                <p>Linkedin</p>
                <LinkedinIcon size={40} round={true} />
              </div>
            </LinkedinShareButton>
          </>
        )}
      </div>
    </ModalTemplate>
  );
};

export default ModalShare;
