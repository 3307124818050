import { IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { MdInfo } from "react-icons/md";
import ModalTemplate from "../../../../components/modal/ModalTemplate";
import { LETRAS_ALTERNATIVAS } from "../../../../config/constants";
import { ModalDetalhesItem } from "../../../itens/components/ItemDetalhes";
import "./itens.css";

const Item = ({ item, index, onClick, respostas, isProfessor }) => {
  const alternativas = [...item.alternativas].sort((a, b) => (b.sequencial < a.sequencial ? 1 : -1));
  
  const [imgModal, setImgModal] = useState();

  const [modalDetalhes, setModalDetalhes] = useState(undefined);

  return (
    <>
      <div className="container-item">
        <div className="header">
          <div className="questao_index">
            <span>{index}</span>
          </div>
          <div className="descricao">
            <div dangerouslySetInnerHTML={{ __html: item.descricao }} />
            {item.img && (
              <div>
                <img className={"imagem-descricao"} src={item.img} onClick={() => setImgModal(item.img)} />
                <div style={{ fontSize: 8, color: "#666" }}>Clique na imagem para zoom</div>
              </div>
            )}
          </div>
          <IconButton onClick={() => setModalDetalhes(item)} color="info">
            <MdInfo />
          </IconButton>
        </div>
        <div className="body">
          {alternativas.map((a, k) => (
            <div
              key={k}
              className={isProfessor? 'alternativa-professor' : `alternativa ${a.id === respostas[item.avaliacao_item_id] ? " alternativa_selecionada" : ""}`}
              onClick={() => {
                onClick({ [`${item.avaliacao_item_id}`]: a.id });
              }}
            >
              <div className="letra">{LETRAS_ALTERNATIVAS[a.sequencial]}</div>
              <div>
                <div className="alternativa_descricao" dangerouslySetInnerHTML={{ __html: a.descricao }} />
                {a.img && (
                  <div>
                    <img className={"imagem-descricao"} src={a.img} onClick={() => setImgModal(a.img)} />
                    <div style={{ fontSize: 8, color: "#666" }}>Clique na imagem para zoom</div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <ModalTemplate
        open={!!imgModal}
        closeModal={() => setImgModal(undefined)}
        title={"Detalhes"}
        desabilitarBotao={true}
      >
        <div style={{ textAlign: "center" }}>
          <img className="imagem-modal-detalhes" src={imgModal} />
        </div>
      </ModalTemplate>
      <ModalDetalhesItem dados={modalDetalhes} fechar={() => setModalDetalhes(undefined)} />
    </>
  );
};

export default Item;
