import { Button, FormControl, } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import InputCustom from '../../../../components/InputCustom';
import logo from '../../../../assets/imgs/logo-blank-page.png'
import { useDispatch, useSelector } from 'react-redux';
import { changeLogin } from '../../reducer';
import AutenticacaoServices from '../../AutenticacaoServices';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import { KEY_USER_BNCC_APP } from '../../../../config/GlobalKeys';
import { Navigate, useNavigate } from 'react-router-dom';
import { DASHBOARD, RECUPERA_SENHA, ROOT } from '../../../../routes/HashRouter';
import { NOVA_CONTA } from '../../../../routes/HashRouter'
import { PERFIS } from '../../../../config/constants';
import './styles.css'

const Login = () => {

    const { login: {
        email,
        senha,
        formError,
        loading
    } } = useSelector(state => state.autenticacao)

    const dispatch = useDispatch()
    const { set } = useLocalStorage()
    const { user } = useSelector(state => state.global)
    const navigate = useNavigate()

    const { control, handleSubmit } = useForm({
        defaultValues: {
            email: email,
            senha: senha,
        }
    })

    const onSuccess = (user) => {
        set(KEY_USER_BNCC_APP, user)
        navigate(DASHBOARD)
        dispatch(changeLogin())
    }

    const handleKeyDown = (event) => {
        let teclaSelecionada = event.code;
        if (teclaSelecionada === "Space") {
          event.preventDefault();
        }
      };

    const onSubmit = (dados) => {
        dispatch(changeLogin({ email: dados.email, senha: dados.senha.trim(), }))
        AutenticacaoServices.login(dispatch, dados, onSuccess)
    }

    if (user && [PERFIS.ADMIN, PERFIS.PROFESSOR].includes(user.perfil)) {
        return <Navigate to={DASHBOARD} />
    }

    return (
        <div className='container-login'>
            <div className='div-form-login'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='div-form-login-img'>
                        <img src={logo} style={{ height: 150 }} ></img>
                    </div>
                    <FormControl style={{ width: '100%' }} >
                        <InputCustom
                            control={control}
                            label={'E-mail'}
                            name={'email'}
                            style={{ marginBottom: 10 }}
                            formError={formError}
                            type={'email'}
                            size={"medium"}
                        />
                        <InputCustom
                            control={control}
                            handleKeyDown={handleKeyDown}
                            label={'Senha'}
                            name={'senha'}
                            style={{ marginBottom: 20 }}
                            type={'password'}
                            formError={formError}
                            size={"medium"}
                        />
                        <Button disabled={loading} type='submit' variant="contained">
                            {loading ? 'ENTRANDO...' : 'ENTRAR'}
                        </Button>
                    </FormControl>
                </form>
                <div style={{ marginTop: 10, display: 'flex', justifyContent: 'space-between' }} >
                    <Button onClick={() => navigate(RECUPERA_SENHA)} >RECUPERAR SENHA</Button>
                    <Button onClick={() => navigate(NOVA_CONTA)} >CADASTRE-SE</Button>
                </div>
            </div>
        </div>
    )
}

export default Login;