import Service from "../../api/Service";
import DashboardApi from "./api/DashboardApi";

export default class DashboardServices {
    static getEnsinos(dispatch, params) {
        Service.factoryRequest(dispatch, DashboardApi.getEnsinos, false, params);
    }
    static getSeries(dispatch, params) {
        Service.factoryRequest(dispatch, DashboardApi.getSeries, false, params);
    }
    static getDisciplinas(dispatch, params) {
        Service.factoryRequest(dispatch, DashboardApi.getDisciplinas, false, params);
    }
    static getHabilidades(dispatch, params) {
        Service.factoryRequest(dispatch, DashboardApi.getHabilidades, false, params);
    }
    static getHabilidadesGeral(dispatch, params) {
        Service.factoryRequest(dispatch, DashboardApi.getHabilidadesGeral, false, params);
    }
    static getTotais(dispatch, params) {
        Service.factoryRequest(dispatch, DashboardApi.getTotais, false, params);
    }
    static getAcertosPorHabilidade(dispatch, params) {
        Service.factoryRequest(dispatch, DashboardApi.getAcertosPorHabilidade, false, params);
    }
    static getAcertosPorAreaConhecimento(dispatch, params, action =() => {}) {
        Service.factoryRequest(dispatch, DashboardApi.getAcertosPorAreaConhecimento, false, params, action);
    }
}



