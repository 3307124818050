import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { MdInbox } from "react-icons/md";
import CardItemQuestaoSelecionado from "./CardItemQuestaoSelecionado";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ItemDetalhes from "../../../itens/components/ItemDetalhes";

export default function ItemsSelecionados({ items, handleRemoveQuestao, atualizaListaQuestoes, questaoInicial }) {
  const [itemModalSelecionado, setItemModalSelecionado] = useState();

  const moveCard = (dragIndex, hoverIndex) => {
    atualizaListaQuestoes(dragIndex, hoverIndex);
  };

  return (
    <>
      <Card sx={{ background: "#ECEFF1", boxShadow: 0, marginTop: 1 }}>
        <CardContent style={{ maxHeight: 500, overflow: "auto", padding: "10px 8px" }}>
          <DndProvider backend={HTML5Backend}>
            {items.length === 0 && (
              <div style={{ minHeight: "100%", backgroundColor: "#fff" }}>
                <CardContent sx={{ textAlign: "center" }}>
                  {" "}
                  <div style={{ paddingTop: 8 }}>
                    <MdInbox size={30} color={"black"} />
                    <Typography marginTop={1}>Nenhum item inserido para esta disciplina</Typography>{" "}
                  </div>
                </CardContent>
              </div>
            )}
            {items.map((item, k) => {
              return (
                <CardItemQuestaoSelecionado
                  questaoInicial={questaoInicial}
                  key={k}
                  index={parseInt(k)}
                  item_quest={item}
                  handleRemoveQuestao={handleRemoveQuestao}
                  moveCard={moveCard}
                  visualizarQuestao={setItemModalSelecionado}
                />
              );
            })}
          </DndProvider>
        </CardContent>
      </Card>
      <ItemDetalhes item={itemModalSelecionado} onClose={() => setItemModalSelecionado(undefined)} />
    </>
  );
}
