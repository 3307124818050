import Service from "../../api/Service";
import AutenticacaoApi from "./api/AutenticacaoApi";
import { changeCadastro, changeCidades, changeEstados, changeLogin } from "./reducer";
import { validaCriaConta, validaEnviaEmail, validaLogin, validaResetaSenha } from './validation'

export default class AutenticacaoServices {

    static login(dispatch, params, callback) {
        dispatch(changeLogin({ formError: {}, loading: true }))
        let validacao = validaLogin(params)
        if (validacao) {
            dispatch(changeLogin({ formError: validacao, loading: false }))
            return
        }

        Service.factoryRequest(dispatch, AutenticacaoApi.login, true, params, callback);
    }

    static getEstados(dispatch,) {
        dispatch(changeEstados({ loading: true }))
        Service.factoryRequest(dispatch, AutenticacaoApi.getEstados, true);
    }

    static getCidades(dispatch, params) {
        dispatch(changeCidades({ loading: true }))
        Service.factoryRequest(dispatch, AutenticacaoApi.getCidades, true, params);
    }
    static cadastraUsuario(dispatch, params, callback) {
        dispatch(changeCadastro({ loading: true }))
        let validacao = validaCriaConta(params)

        if (validacao) {
            dispatch(changeCadastro({ formError: validacao, loading: false }))
            return
        }

        Service.factoryRequest(dispatch, AutenticacaoApi.cadastraUsuario, true, params, callback);
    }

    static enviaEmail(dispatch, params, callback, callbackError) {
        let validacao = validaEnviaEmail(params)
        if (validacao) {
            callbackError(validacao)
            return
        }
        Service.factoryRequest(dispatch, AutenticacaoApi.enviaEmail, true, params, callback);
    }

    static resetaSenha(dispatch, params, callback, callbackError) {
        let validacao = validaResetaSenha(params)
        if (validacao) {
            callbackError(validacao)
            return
        }
        Service.factoryRequest(dispatch, AutenticacaoApi.resetaSenha, true, params, callback);
    }
}



