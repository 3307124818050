

import ApiRequest from "../../../api/ApiRequest";
import { listaDisciplinas, listaEnsinos, listaHabilidades, listaHabilidadesGeral, listaSeries, listaTotais, listaAcertosPorHabilidade, listaAcertosPorAreaConhecimento } from "../reducer";
import { ENSINOS_WS, HABILIDADES_WS, ITENS_WS, ANOS_WS, CAMPOS_EXPERIENCIA_WS, DASH_COUNTS, DASH_ACERTOS_POR_HABILIDADE, DASH_ACERTOS_POR_AREA_CONHECIMENTO } from "../../../api/routes";
import toastr from "toastr";
import * as CacheKeys from '../../../config/CacheKeys'
import Dados from "../../../models/Dados";
import Service from "../../../api/Service";


export default class DashboardApi extends ApiRequest {

    static getEnsinos(obj, ignoreCache, params) {
        return dispatch => {
            const key = CacheKeys.KEY_LISTA_ENSINOS
            const exp = CacheKeys.EXP

            let useCache = (dados) => {
                let obj = new Dados();
                obj.dados = dados;
                dispatch(listaEnsinos(obj))
            }

            Service.getCache(ignoreCache, exp, key, useCache)
                .then(cache => {
                    if (!cache) {
                        let response = (obj, success) => {
                            dispatch(listaEnsinos(obj))
                            if (success) {
                                localStorage.setItem(key, JSON.stringify({ dados: obj.dados, data: new Date() }));
                            }
                        }

                        let url = super.getFormatUrl(ENSINOS_WS, params)

                        return super.requestAuth(url, { method: "get" }, obj, response, "Não foi possível buscar ensinos, tente novamente.")
                    }
                })
        }
    }

    static getSeries(obj, ignoreCache, params) {
        return dispatch => {
            const key = CacheKeys.KEY_LISTA_SERIES
            const exp = CacheKeys.EXP

            let useCache = (dados) => {
                let obj = new Dados();
                obj.dados = dados;
                dispatch(listaSeries(obj))
            }

            Service.getCache(ignoreCache, exp, key, useCache)
                .then(cache => {
                    if (!cache) {
                        let response = (obj, success) => {
                            dispatch(listaSeries(obj))
                            if (success) {
                                localStorage.setItem(key, JSON.stringify({ dados: obj.dados, data: new Date() }));
                            }
                        }

                        let url = super.getFormatUrl(ANOS_WS, params)

                        return super.requestAuth(url, { method: "get" }, obj, response, "Não foi possível buscar anos, tente novamente.")
                    }
                })
        }
    }

    static getDisciplinas(obj, ignoreCache, params) {
        return dispatch => {
            const key = CacheKeys.KEY_LISTA_DISCIPLINAS
            const exp = CacheKeys.EXP

            let useCache = (dados) => {
                let obj = new Dados();
                obj.dados = dados;
                dispatch(listaDisciplinas(obj))
            }

            Service.getCache(ignoreCache, exp, key, useCache)
                .then(cache => {
                    if (!cache) {
                        let response = (obj, success) => {
                            dispatch(listaDisciplinas(obj))
                            if (success) {
                                localStorage.setItem(key, JSON.stringify({ dados: obj.dados, data: new Date() }));
                            }
                        }

                        let url = super.getFormatUrl(CAMPOS_EXPERIENCIA_WS, params)

                        url = `${url}&with[0]=area_conhecimento`

                        return super.requestAuth(url, { method: "get" }, obj, response, "Não foi possível buscar campos de experiência, tente novamente.")
                    }
                })
        }
    }


    static getTotais(obj, ignoreCache, params) {
        return dispatch => {
            let response = (obj, success) => {
                dispatch(listaTotais({ ...obj }))
            }

            let url = super.getFormatUrl(DASH_COUNTS, params)

            return super.requestAuth(url, { method: "get" }, obj, response, "Não foi possível buscar totais, tente novamente.")
        }
    }

    static getAcertosPorHabilidade(obj, ignoreCache, params) {
        return dispatch => {
            let response = (obj, success) => {
                dispatch(listaAcertosPorHabilidade({ ...obj }))
            }
            let url = super.getFormatUrl(DASH_ACERTOS_POR_HABILIDADE, params);

            return super.requestAuth(url, { method: "get" }, obj, response, "Não foi possível buscar acertos por habilidades, tente novamente.")
        }
    }
    
    static getAcertosPorAreaConhecimento(obj, ignoreCache, params, action =() => {}) {
        return dispatch => {
            let response = (obj, success) => {
                // dispatch(listaAcertosPorAreaConhecimento({ ...obj }))
                action({...obj})
            }
            let url = super.getFormatUrl(DASH_ACERTOS_POR_AREA_CONHECIMENTO, params);

            return super.requestAuth(url, { method: "get" }, obj, response, "Não foi possível buscar acertos por habilidades, tente novamente.")
        }
    }

    static getHabilidades(obj, ignoreCache, params) {
        return dispatch => {
            let response = (obj, success) => {
                let dados = null
                if (obj?.dados) {
                    if (params.id) {
                        dados = [obj.dados]
                    } else {
                        dados = obj.dados
                    }
                }
                dispatch(listaHabilidades({ ...obj, dados }))
            }

            let url = params.id ? `${HABILIDADES_WS}/${params.id}` : super.getFormatUrl(HABILIDADES_WS, params)

            return super.requestAuth(url, { method: "get" }, obj, response, "Não foi possível buscar habilidades, tente novamente.")
        }
    }

    static getHabilidadesGeral(obj, ignoreCache, params) {
        return dispatch => {
            const key = CacheKeys.KEY_LISTA_GERAL_HABILIDADES
            const exp = CacheKeys.EXP

            let useCache = (dados) => {
                let obj = new Dados();
                obj.dados = dados.map(d => ({ ...d, nome: `${d.codigo}: ${d.descricao}` }));
                dispatch(listaHabilidadesGeral(obj))
            }

            Service.getCache(ignoreCache, exp, key, useCache)
                .then(cache => {
                    if (!cache) {
                        let response = (obj, success) => {
                            let dados = []
                            if (obj?.dados) {
                                dados = obj.dados.map(d => ({ ...d, nome: `${d.codigo}: ${d.descricao}` }))
                            }
                            dispatch(listaHabilidadesGeral({ ...obj, dados }))

                            if (success) {
                                localStorage.setItem(key, JSON.stringify({ dados: obj.dados, data: new Date() }));
                            }
                        }

                        let url = super.getFormatUrl(HABILIDADES_WS, params)

                        return super.requestAuth(url, { method: "get" }, obj, response, "Não foi possível buscar habilidades, tente novamente.")
                    }
                })
        }
    }

}




