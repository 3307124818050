import { Breadcrumbs, Chip, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AVALIACOES, DASHBOARD, FORM_AVALIACAO, FORM_EDIT_ITENS, FORM_ITENS, ITENS, FORM_EDIT_AVALIACAO, AVALIACOES_RESPOSTAS, PERFIL } from "../routes/HashRouter";
import { Link, useLocation, useParams } from "react-router-dom";
import { styled, emphasize } from '@mui/material/styles';
import { useSelector } from "react-redux";

const inputRotas = [
    { nome: 'Dashboard', path: DASHBOARD, caminhos: [] },
    { nome: 'Avaliações', path: AVALIACOES, caminhos: [] },
    { nome: 'Nova avaliação', path: FORM_AVALIACAO, caminhos: [1] },
    { nome: 'Alterar Avaliação', path: FORM_EDIT_AVALIACAO, caminhos: [1] },
    { nome: 'Respostas alunos', path: AVALIACOES_RESPOSTAS, caminhos: [1] },

    { nome: 'Itens', path: ITENS, caminhos: [] },
    { nome: 'Novo item', path: FORM_ITENS, caminhos: [5] },
    { nome: 'Alterar item', path: FORM_EDIT_ITENS, caminhos: [5] },
    { nome: 'Perfil', path: PERFIL, caminhos: [] },
]

const BreadCrumb = ({ path }) => {

    const [rotas, setRotas] = useState(inputRotas);
    const [rotaNome, setRotaNome] = useState('');
    // const location = useLocation();
    const { user } = useSelector(state => state.global);

    useEffect(() => {
        if (user) {
            let auxRotas = [...inputRotas]
            let caminhos = []
            auxRotas.forEach((rota, index) => {
                if (rota.path === path) {
                    setRotaNome(rota.nome)
                    caminhos = rota.caminhos
                }
            })
            auxRotas = auxRotas.filter((val, key) => caminhos.includes(key))
            setRotas(auxRotas)
        }
    }, [path])

    const StyledBreadcrumb = styled(Chip)(({ theme }) => {
        const backgroundColor =
            theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[800];
        return {
            backgroundColor,
            height: theme.spacing(3),
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightRegular,
            '&:hover, &:focus': {
                backgroundColor: emphasize(backgroundColor, 0.06),
            },
            '&:active': {
                boxShadow: theme.shadows[1],
                backgroundColor: emphasize(backgroundColor, 0.12),
            },
        };
    });

    const StyledLink = styled(Link)(({ theme }) => {
        return {
            textDecoration: 'none',
            height: theme.spacing(3),
            color: theme.palette.text.primary,
            fontWeight: theme.typography.fontWeightRegular,
        };
    });

    const showLinks = (breadcrumbs) => {
        let auxbread = []
        breadcrumbs.forEach((bread, index) => {
            auxbread = auxbread.concat(
                <StyledLink
                    key={index}
                    to={bread.path}>
                    <StyledBreadcrumb
                        label={bread.nome}
                    />
                </StyledLink>
            )
        });
        auxbread = auxbread.concat(
            <StyledBreadcrumb
                key={auxbread.length}
                label={rotaNome}
            />
        )

        return auxbread
    }

    return (
        <Grid container sx={{ marginTop: 2, marginBottom: 2 }}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
                {showLinks(rotas)}
            </Breadcrumbs>
        </Grid>
    )
}
export default BreadCrumb;
