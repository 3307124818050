import {
  Avatar,
  Button,
  Card,
  Divider,
  Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FaFile, FaSearch } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../../components/header/Header";
import { useDebounce } from "../../../../hooks/useDebounce";
import { AVALIACOES } from "../../../../routes/HashRouter";
import AvaliacoesServices from "../../AvaliacoesServices";
import LoadingData from "../../../../components/LoadingData";
import { alteraDetalhes, listaRespostas } from "../../reducer";
import AvaliacaoDetalhes from "../AvaliacaoDetalhes";
import ContainerItem from "../../../../components/ContainerItem";
import { getDataFormatadaDaDataCompleta } from "../../../../utils/formatDate";

const AvaliacoesRespostas = () => {
  const [aluno, setAluno] = useState("");

  const [modalAvaliacoes, setModalAvaliacoes] = useState();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { respostasAvaliacao, avaliacaoDetalhes } = useSelector((state) => state.avaliacoes);

  const { id } = useParams();

  const load = (page = 1, nome) => {
    let params = {
      page,
      avaliacao_id: id,
      limit: 20,
    };

    if (nome) {
      params["aluno[nome]"] = nome;
    }

    AvaliacoesServices.getRespostasAlunos(dispatch, params);
  };

  const { setValueDebounce } = useDebounce((val) => load(1, val));

  useEffect(() => {
    load();

    return () => {
      dispatch(listaRespostas());
      dispatch(alteraDetalhes(null));
    };
  }, []);

  useEffect(() => {
    if (id) {
      AvaliacoesServices.getDetalhes(dispatch, { id });
    }
  }, [id]);

  const changeDescricao = (val) => {
    setAluno(val);
    setValueDebounce(val);
  };

  return (
    <>
      <Header
        title={"Respostas da avaliação"}
        onBack={() => navigate(AVALIACOES)}
        tooltip={"Voltar para listagem de avaliações"}
      />
      {avaliacaoDetalhes && avaliacaoDetalhes.dados && Object.keys(avaliacaoDetalhes.dados).length > 0 && (
        <Card style={{ padding: 8, margin: "16px 0px 8px" }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <ContainerItem title={"Descrição:"}>
                <div style={{ fontSize: 12, color: "#666", marginTop: 5 }}>{avaliacaoDetalhes.dados.descricao}</div>
              </ContainerItem>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <ContainerItem title={"Professor:"}>
                <div style={{ fontSize: 12, color: "#666", marginTop: 5 }}>
                  {avaliacaoDetalhes.dados.professor.nome}
                </div>
              </ContainerItem>
            </Grid>
            <Grid item xs={12} md={3} sm={3}>
              <ContainerItem title={"Data início:"}>
                <div style={{ fontSize: 12, color: "#666", marginTop: 5 }}>
                  {getDataFormatadaDaDataCompleta(avaliacaoDetalhes.dados.data_aplicacao_inicio)}
                </div>
              </ContainerItem>
            </Grid>
            <Grid item xs={12} md={3} sm={3}>
              <ContainerItem title={"Data final:"}>
                <div style={{ fontSize: 12, color: "#666", marginTop: 5 }}>
                  {getDataFormatadaDaDataCompleta(avaliacaoDetalhes.dados.data_aplicacao_fim)}
                </div>
              </ContainerItem>
            </Grid>
            <Grid item xs={12} md={3} sm={3}>
              <ContainerItem title={"Ensino:"}>
                <div style={{ fontSize: 12, color: "#666", marginTop: 5 }}>{avaliacaoDetalhes.dados.ensino.nome}</div>
              </ContainerItem>
            </Grid>
            <Grid item xs={12} md={3} sm={3}>
              <ContainerItem title={"Ano:"}>
                <div style={{ fontSize: 12, color: "#666", marginTop: 5 }}>
                  {avaliacaoDetalhes.dados.serie_ano.nome}
                </div>
              </ContainerItem>
            </Grid>
          </Grid>
        </Card>
      )}
      <Card style={{ marginTop: 10, padding: "16px 16px 8px" }}>
        {respostasAvaliacao && !respostasAvaliacao.loading && (
          <Grid container spacing={2}>
            <Grid item md={12}>
              <div>Buscar por:</div>
            </Grid>
            <Grid item md={12} xs={12}>
              <TextField
                fullWidth
                size="small"
                id="textfield-filtrar-escola"
                label={"Buscar por nome"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <FaSearch size={18} color={"#ccc"} />
                    </InputAdornment>
                  ),
                }}
                value={aluno}
                onChange={(e) => changeDescricao(e.target.value)}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <Divider />
            </Grid> */}
          </Grid>
        )}
        {respostasAvaliacao && <LoadingData obj={respostasAvaliacao} msg={"Buscando respostas..."} />}
        {respostasAvaliacao && respostasAvaliacao.dados && respostasAvaliacao.dados.total !== null && (
          <Grid item xs={12} textAlign={"end"} style={{ marginTop: 16 }}>
            <span className="total-avaliacoes">
              Total: {respostasAvaliacao.dados.total} {respostasAvaliacao.dados.total > 1 ? "repostas" : "resposta"}
            </span>
          </Grid>
        )}
        {respostasAvaliacao && respostasAvaliacao.dados && !respostasAvaliacao.loading && (
          <TableContainer style={{ margin: "20px 0px", border: "1px solid #dedede" }}>
            <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: "bold", width: 1 }}>#</TableCell>
                  <TableCell style={{ fontWeight: "bold" }}>Aluno</TableCell>
                  {/* <TableCell style={{ fontWeight: "bold" }}>Avaliação</TableCell> */}
                  <TableCell align="right" style={{ fontWeight: "bold" }}>
                    Nº de questões
                  </TableCell>
                  <TableCell align="right" style={{ fontWeight: "bold" }}>
                    Nº de acertos
                  </TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {respostasAvaliacao.dados.listRespostasAvaliacao.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={5}>Ainda não há respostas!</TableCell>
                  </TableRow>
                )}
                {respostasAvaliacao.dados.listRespostasAvaliacao.map((res, key) => {
                  return (
                    <TableRow key={key} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        <Avatar src={res.aluno.img} />
                      </TableCell>
                      <TableCell>{res.aluno.nome}</TableCell>
                      {/* <TableCell>{res.avaliacao.descricao}</TableCell> */}
                      <TableCell align="right">{res.avaliacao.num_questoes}</TableCell>
                      <TableCell align="right">{res.num_acertos}</TableCell>
                      <TableCell align="right">
                        <Button
                          onClick={() => setModalAvaliacoes({ avaliacao_aluno_id: res.id })}
                          color="info"
                          size="small"
                          variant="contained"
                        >
                          Visualizar respostas
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}{" "}
        {respostasAvaliacao &&
          respostasAvaliacao.dados &&
          respostasAvaliacao.dados.last_page > respostasAvaliacao.dados.current_page && (
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <Button
                style={{ marginTop: 5, marginBottom: 20 }}
                onClick={() => load(respostasAvaliacao.dados.current_page + 1, aluno)}
                variant="contained"
                size="small"
                color="info"
              >
                Ver mais
              </Button>
            </Grid>
          )}
        <AvaliacaoDetalhes
          onClose={() => setModalAvaliacoes(undefined)}
          avaliacao_aluno_id={modalAvaliacoes?.avaliacao_aluno_id}
        />
      </Card>
    </>
  );
};

export default AvaliacoesRespostas;
