import { CircularProgress, Button } from '@mui/material';
import React from 'react'

const LoadingData = (props) => {
    const obj = props.obj;
    const msg = props.msg;
    const showError = !props.noError;
    const showButtonError = !props.noButtonError;

    const cor = props.cor ? { color: props.cor } : {};

    return (obj.loading || obj.error ?
        <div style={{ backgroundColor: '#efefef', padding: 16, textAlign: 'center', margin: '10px 0px', border: '1px solid #dedede', borderRadius: 4, width: '100%' }}>
            {obj.loading && <div style={{ justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>
                <CircularProgress size={25} />
                <div style={cor}>{msg}</div>
            </div>}
            {showError &&
                obj.error && <div style={{ justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>
                    <div style={{ fontSize: 16, color: 'red', marginBottom: 10 }}>{obj.msgError}</div>
                    {showButtonError &&
                        <Button
                            variant="contained"
                            onClick={obj.action}>
                            Atualizar
                        </Button>
                    }
                </div>
            }
        </div>
        : <></>
    );
}

export default LoadingData;