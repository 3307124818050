import { IconButton, Tooltip } from '@mui/material';
import React from 'react';

const IconButtonCustom = ({ action, color, tooltip, children, style = {} }) => {
    return (
        <Tooltip title={tooltip}>
            <IconButton sx={{ width: 'min-content', border: 'solid 1px', color: color, padding: '5px', ...style }} onClick={action}>
                {children}
            </IconButton>
        </Tooltip>
    )
}

export default IconButtonCustom;