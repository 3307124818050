import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { DropContainer, UploadMessage } from './styles';

export default class Upload extends Component {

  renderDragMessage=(isDragActive, isDragReject)=>{
    
    if(!isDragActive) return <UploadMessage type={this.props.type || 'default'}>{this.props.message ? this.props.message :  'Clique ou Arraste para cá!'}</UploadMessage>

    if(isDragReject) return <UploadMessage type="error">Arquivo Não Suportado!</UploadMessage>

    return <UploadMessage type="success">Solte o arquivo aqui!</UploadMessage>
  }

  render() {
    let {onUpload, multiple, onlyImage, onlyVideo, onlyAudio, imgAndPdf} = this.props
    
    const fileTypes = () => {
      if(imgAndPdf){return ["image/*", "application/pdf"]}
      if(onlyImage){return ["image/*"]}
      if(onlyAudio){return ["audio/*"]}
      if(onlyVideo){return ["video/*"]}
      return ["application/pdf"]
    }

    return (
      <Dropzone
        accept={fileTypes()}
        multiple={multiple}
        onDropAccepted={onUpload}
      >
        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
          <DropContainer 
          {...getRootProps()}
          isDragActive={isDragActive}
          isDragReject={isDragReject}
          >
            {this.props.children}
            <input {... getInputProps()} />
            {this.renderDragMessage(isDragActive, isDragReject)}
          </DropContainer>
        )}
      </Dropzone>
    );
  }
}
