const DOMAIN = "https://bncc.educaapis.info";

const DOMAIN_NEW_API = "https://apibncc.educaapis.info";

const DOMAIN_OLD_API = 'https://api-old.educaapis.info'

export const LOGIN_LEGADO_WS = DOMAIN_NEW_API + "/acesso/legado";
export const LOGIN_WS = DOMAIN_NEW_API + "/acesso/login";

export const CIDADES_WS = DOMAIN_NEW_API + "/cidades";
export const CADASTRA_USUARIO_WS = DOMAIN_NEW_API + "/cadastro/professor";
export const CADASTRA_USUARIO_ALUNO_WS = DOMAIN_NEW_API + "/cadastro/aluno";

export const ENVIA_RESPOSTAS_WS = DOMAIN_NEW_API + "/avaliacao/avaliacao_aluno_itens/saveAll";
export const AVALIACAO_ALUNO_ITENS = DOMAIN_NEW_API + "/avaliacao/avaliacao_aluno_itens";

export const AVALIACOES_ALUNO_WS = DOMAIN_NEW_API + "/avaliacao/avaliacao_alunos";
export const AVALIACOES_WS = DOMAIN_NEW_API + "/avaliacao/avaliacoes";
export const AVALIACOES_HASH_WS = DOMAIN_NEW_API + "/avaliacao/avaliacoes/hash";
export const ENSINOS_WS = DOMAIN_NEW_API + "/ensinos";
export const ITENS_WS = DOMAIN_NEW_API + "/avaliacao/itens";
export const HABILIDADES_WS = DOMAIN_NEW_API + "/bncc/habilidades";
export const ANOS_WS = DOMAIN_NEW_API + "/series_anos";
export const CAMPOS_EXPERIENCIA_WS = DOMAIN_NEW_API + "/bncc/campos_experiencia";

export const ENVIA_EMAIL_WS = DOMAIN_NEW_API + "/acesso/recuperar_senha";
export const RESETA_SENHA_WS = DOMAIN_NEW_API + "/acesso/recuperar_senha_atualizar";

export const UPDATE_PERFIL = DOMAIN_NEW_API + "/professores/update";

// export const SHORT_LINK_WS = "https://api.shrtco.de/v2/shorten?url=:url";
export const SHORT_LINK_WS = "https://api.encurtador.dev/encurtamentos";

export const DASH_COUNTS = DOMAIN_NEW_API + "/geral/dashboard/counts";
export const DASH_ACERTOS_POR_HABILIDADE = DOMAIN_NEW_API + "/geral/dashboard/acertos_por_habilidade";
export const DASH_ACERTOS_POR_AREA_CONHECIMENTO = DOMAIN_NEW_API + "/geral/dashboard/acertos_por_campo_experiencia";
