export const ROOT = "/";
export const DASHBOARD = "/dashboard/";
export const NOVA_CONTA = "/nova-conta/";
export const RECUPERA_SENHA = "/recupera-senha/";

export const AVALIACOES = "/avaliacoes/";
export const FORM_AVALIACAO = "/avaliacoes/formulario/";
export const FORM_EDIT_AVALIACAO = "/avaliacoes/formulario/:id";
export const AVALIACOES_RESPOSTAS = "/avaliacoes/:id/respostas";

export const ITENS = "/itens/";
export const FORM_ITENS = "/itens/formulario/";
export const FORM_EDIT_ITENS = "/itens/formulario/:id";

export const AVALIACAO_ALUNO = '/avaliacao/:hash'

export const PERFIL = '/perfil'

