import { Button, Card, Grid, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaTimes } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import InputCustom from '../../../../components/InputCustom';
import SelectCustom from '../../../../components/SelectCustom';
import AvaliacoesServices from '../../AvaliacoesServices';
import { alteraAvaliacao } from '../../reducer';

const CamposExperiencia = ({ renderFooter }) => {

    const {
        avaliacao: {
            campos_selecionados,
            ensino_id,
        },
        campos_experiencia
    } = useSelector(state => state.avaliacoes)

    const dispatch = useDispatch()

    const [formError, setFormError] = useState({})

    useEffect(() => {
        AvaliacoesServices.getCamposExperiencia(dispatch, {
            ensino_id,
            limit: 0
        })
    }, [])

    const { control, handleSubmit, reset } = useForm({
        defaultValues: {
            campo_id: "",
            quantidade_questoes: "",
            formError: {}
        }
    })

    const onSubmit = (dados) => {
        let erros = {}
        let { campo_id, quantidade_questoes } = dados

        if (!campo_id) {
            erros['campo_id'] = "Disciplina obrigatória";
        }
        if (!quantidade_questoes) {
            erros['quantidade_questoes'] = "Número de questões obrigatório";
        }

        if (Object.keys(erros).length > 0) {
            setFormError(erros)
        } else {
            let campos = [...campos_selecionados];
            let campo_nome = campos_experiencia.dados.find(c => c.id === campo_id)?.nome
            let questao_inicial = 1;
            campos.forEach(c => { questao_inicial += parseInt(c.quantidade_questoes) })
            campos.push({ campo_id, campo_nome, quantidade_questoes, questao_inicial })
            dispatch(alteraAvaliacao({ campos_selecionados: campos }))
            reset()
        }
    }

    const verificaSeTemCamposExperienciasAdicionados = () => {
        if (campos_selecionados.length > 0) {
            let num_questoes = 0
            campos_selecionados.forEach(campo => {
                num_questoes += parseInt(campo.quantidade_questoes)
            });
            dispatch(alteraAvaliacao({ num_questoes }))
            return true
        } else {
            return false
        }
    }

    const limpaTodosCamposExperiencias = () => {
        dispatch(alteraAvaliacao({ campos_selecionados: [], num_questoes: "" }))
        return true
    }

    const removeCampoExperiencia = (campo_id) => {
        let campos = [...campos_selecionados].filter(c => c.campo_id !== campo_id);
        dispatch(alteraAvaliacao({ campos_selecionados: campos }))
    }

    let lista_campos_experiencias = []

    if (campos_experiencia?.dados) {
        if (campos_selecionados.length > 0) {
            lista_campos_experiencias = campos_experiencia.dados.filter(campo => !campos_selecionados.some(c => c.campo_id === campo.id))
        } else {
            lista_campos_experiencias = campos_experiencia.dados;
        }
    }

    return (
        <>
            <Card style={{ padding: '16px', }}>
                <form onSubmit={handleSubmit(onSubmit)} >
                    <Grid container spacing={2}>
                        <Grid item md={6} sm={6} xs={12}>
                            <SelectCustom
                                control={control}
                                name={'campo_id'}
                                label={"Disciplinas"}
                                items={lista_campos_experiencias.map(campo => ({ label: campo.nome, value: campo.id }))}
                                formError={formError}
                                loading={campos_experiencia?.loading}
                            />
                        </Grid>
                        <Grid item md={4} sm={4} xs={12}>
                            <InputCustom
                                control={control}
                                label={'Nº de Questões'}
                                name={'quantidade_questoes'}
                                type={"number"}
                                formError={formError}
                            />
                        </Grid>
                        <Grid item md={2} sm={2} xs={12}>
                            <Button
                                type={'submit'}
                                fullWidth
                                onClick={() => null}
                                variant="outlined">
                                ADICIONAR
                            </Button>
                        </Grid>
                    </Grid>
                    {campos_selecionados.length > 0 &&
                        <Grid container style={{ marginTop: 10 }} >
                            {campos_selecionados.map((c, k) => (
                                <Grid key={k} item xs={12} style={{ margin: '5px 0px' }}>
                                    <div style={{ padding: 8, position: 'relative', backgroundColor: '#dedede40', borderRadius: 4 }} >
                                        <Typography variant='h6'>{c.campo_nome}</Typography>
                                        <Typography variant='subtitle2' style={{ color: '#666' }}>Número de questões: {c.quantidade_questoes}</Typography>
                                        <IconButton
                                            onClick={() => removeCampoExperiencia(c.campo_id)}
                                            style={{ position: 'absolute', top: 5, right: 5 }}>
                                            <FaTimes size={18} color={'red'} />
                                        </IconButton>
                                    </div>
                                </Grid>
                            ))}
                        </Grid>
                    }
                    {renderFooter({ nextAction: verificaSeTemCamposExperienciasAdicionados, backAction: limpaTodosCamposExperiencias })}
                </form>
            </Card>
        </>
    )
}

export default CamposExperiencia;