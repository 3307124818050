import ApiRequest from "../../../api/ApiRequest";
import { UPDATE_PERFIL, } from "../../../api/routes";
import toastr from "toastr";

export default class PerfilApi extends ApiRequest {

    static editarPerfil(obj, ignoreCache, params, action) {
        return (dispatch) => {
            const success = (response) => {
                toastr.success("Perfil editado com sucesso.");
                action(response, true)
            }
            const error = (msg, error, result) => {
                let erros = result && result.errors ? Object.keys(result.errors).map(el => `${el}: ${result.errors[el]}`).join('<br>') : ''
                toastr.warning(`${msg} <br> ${erros}`)
                action(msg, false)
            }

            let url = UPDATE_PERFIL

            let parametros = { ...params };

            if (params.id) {
                url += `/${params.id}`
            }

            return super.requestCadastro(parametros, url, success, error)
        };
    }


}
