import { Button, Divider } from "@mui/material";
import { useState } from "react";
import ItemDetalhes from "../../../itens/components/ItemDetalhes";
import '../../styles/avaliacoes.css'


const CardItem = ({ item, adicionarItem, disabled }) => {

    const [itemModalSelecionado, setItemModalSelecionado] = useState()

    return (
        <>
            <div className="card-avaliacao-item" >
                <div className="header" >
                    <div className="badge-header">
                        <span style={{ fontSize: 12, fontWeight: 'bold' }} >{item.habilidade.codigo}</span>
                    </div>
                    <div style={{ display: 'flex' }} >
                        <Button
                            style={{ marginRight: 10 }}
                            size='small'
                            variant='outlined'
                            onClick={() => setItemModalSelecionado(item)}>
                            Expandir
                        </Button>
                        <Button
                            disabled={disabled}
                            size='small'
                            variant='contained'
                            onClick={() => adicionarItem(item)} >
                            Adicionar
                        </Button>
                    </div>
                </div>
                <Divider />
                <div style={{ padding: 10 }}>
                    {!!item.descricao &&
                        <div className='body'>
                            <div className='descricao' dangerouslySetInnerHTML={{ __html: item.descricao }} />
                        </div>
                    }
                    {
                        item.img &&
                        <div className="descricao-img" >
                            <img src={item.img} style={{ height: '100%', width: '100%', objectFit: 'cover' }} />
                        </div>
                    }
                    <div className='footer'>
                        <span className='badge-footer'>{item?.alternativas?.length} alternativas</span>
                    </div>
                </div>
            </div>
            <ItemDetalhes
                item={itemModalSelecionado}
                onClose={() => setItemModalSelecionado(undefined)}
            />
        </>
    )
}

export default CardItem;