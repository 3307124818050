import { FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';

const SelectCustom = ({ control, name, rules = {}, label, placeholder, style = {}, formError = {}, items = [], loading, size, disabled }) => {
    return (
        <Controller
            control={control}
            rules={rules}
            name={name}
            render={({ field }) =>
                <FormControl style={{ width: '100%' }} size={size ? size : "small"} error={!!formError[name]}>
                    <InputLabel id="test-select-label">{label}</InputLabel>
                    <Select
                        {...field}
                        fullWidth
                        label={label}
                        placeholder={placeholder}
                        style={style}
                        disabled={loading || disabled}
                        labelId="test-select-label">
                        {items.map((item, key) =>
                            <MenuItem key={key} value={item.value}>{item.label}</MenuItem>
                        )}
                    </Select>
                    {formError[name] && <FormHelperText>{formError[name]}</FormHelperText>}
                </FormControl >
            }>
        </Controller >
    )
}

export default SelectCustom;