import { createSlice } from '@reduxjs/toolkit'

const INITAL_STATE = {
    user: null
}

const globalSlice = createSlice({
    name: 'globalReducer',
    initialState: INITAL_STATE,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload ? { ...action.payload } : null
        }
    },
})


export const { setUser } = globalSlice.actions
export default globalSlice.reducer