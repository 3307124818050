import { useEffect, useState } from "react";

const useDebounce = (funcao) => {

    const [timeInput, setTimeInput] = useState(null)
    const [value, setValueDebounce] = useState('')

    useEffect(() => {
        if (timeInput === null) setTimeInput(setTimeout(() => { }, 1000));
    }, [timeInput]);

    useEffect(() => {
        if (timeInput) {
            clearTimeout(timeInput);
            setTimeInput(setTimeout(() => funcao(value), 800));
        }
    }, [value]);

    return { setValueDebounce }
}

export { useDebounce };