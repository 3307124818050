import { Button, Card, Grid, } from '@mui/material';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import InputCustom from '../../../../components/InputCustom';
import SelectCustom from '../../../../components/SelectCustom';
import { getDateByDateStr } from '../../../../utils/formatDate';
import { validaData } from '../../../../utils/validations';
import AvaliacoesServices from '../../AvaliacoesServices';
import { alteraAvaliacao } from '../../reducer';
import toastr from 'toastr'

const FormAvaliacao = ({ renderFooter }) => {

    const {
        avaliacao: {
            id,
            data_aplicacao_inicio,
            data_aplicacao_fim,
            num_questoes,
            descricao,
            ensino_id,
            serie_ano_id,
            formError,
        },
        ensinos,
        series,
    } = useSelector(state => state.avaliacoes)


    const { control, getValues, watch, setValue } = useForm({
        defaultValues: {
            data_aplicacao_inicio,
            data_aplicacao_fim,
            num_questoes,
            descricao,
            ensino_id,
            serie_ano_id
        }
    })

    const dispatch = useDispatch()

    useEffect(() => {
        if (id) {
            setValue("data_aplicacao_inicio", data_aplicacao_inicio)
            setValue("data_aplicacao_fim", data_aplicacao_fim)
            setValue("num_questoes", num_questoes)
            setValue("descricao", descricao)
            setValue("ensino_id", ensino_id)
            setValue("serie_ano_id", serie_ano_id)
        }
    }, [id])

    useEffect(() => {
        let ensino_id_form = getValues("ensino_id");
        if (ensino_id_form) {
            AvaliacoesServices.getSeries(dispatch, { ensino_id: ensino_id_form, limit: 0 })
            if (!id) {
                setValue("serie_ano_id", "")
            }
        }
    }, [watch('ensino_id')])


    const onSubmit = () => {
        let data_aplicacao_inicio = getValues("data_aplicacao_inicio")
        let data_aplicacao_fim = getValues("data_aplicacao_fim")
        let descricao = getValues("descricao")
        let ensino_id = getValues("ensino_id")
        let serie_ano_id = getValues("serie_ano_id")

        let erros = {}

        if (!data_aplicacao_inicio) {
            erros[`data_aplicacao_inicio`] = 'Data aplicação de início obrigatório'
        }

        if (!validaData(data_aplicacao_inicio)) {
            erros['data_aplicacao_inicio'] = 'Data de aplicação inválida'
        }

        if (!data_aplicacao_fim) {
            erros[`data_aplicacao_fim`] = 'Data de fim da aplicação obrigatório'
        }

        if (!validaData(data_aplicacao_fim)) {
            erros['data_aplicacao_fim'] = 'Data de fim da aplicação inválida'
        }

        if (Object.keys(erros).length === 0) {
            let data_ini = getDateByDateStr(data_aplicacao_inicio)
            let data_fim = getDateByDateStr(data_aplicacao_fim)
            let hoje = new Date()
            hoje.setHours(0, 0, 0, 0)

            if (data_fim.getTime() < data_ini.getTime()) {
                toastr.warning("Data de fim não pode ser menor que data de início");
                return
            }

            if (hoje.getTime() > data_ini.getTime() && !id) {
                toastr.warning("Data de início não pode ser menor que a data de hoje");
                return
            }
        }

        if (!descricao) {
            erros[`descricao`] = 'Descrição obrigatória'
        }
        if (!ensino_id) {
            erros[`ensino_id`] = 'Ensino obrigatório'
        }
        if (!serie_ano_id) {
            erros[`serie_ano_id`] = 'Ano obrigatório'
        }

        if (Object.keys(erros).length > 0) {
            dispatch(alteraAvaliacao({ formError: erros }))
            return false
        } else {
            let serie_ano_sigla = series.dados.find(s => s.id === serie_ano_id).sigla;

            dispatch(alteraAvaliacao({ data_aplicacao_inicio, data_aplicacao_fim, descricao, ensino_id, serie_ano_id, serie_ano_sigla, formError: {} }))
            return true
        }
    }

    return (
        <Card style={{ padding: '16px 16px 8px', }} >
            <Grid container spacing={2}  >
                <Grid item xs={12} >
                    <InputCustom
                        control={control}
                        label={'Descrição'}
                        name={'descricao'}
                        formError={formError}
                    />
                </Grid>
                <Grid item xs={12} md={6} sm={6}>
                    <SelectCustom
                        control={control}
                        name={'ensino_id'}
                        label={'Selecione o ensino'}
                        disabled={!!id}
                        formError={formError}
                        items={ensinos?.dados ? ensinos.dados.map(ensino => ({ label: ensino.nome, value: ensino.id })) : []}
                        loading={ensinos?.loading}
                    />
                </Grid>
                <Grid item xs={12} md={6} sm={6}>
                    <SelectCustom
                        control={control}
                        name={'serie_ano_id'}
                        label={'Selecione o ano'}
                        disabled={!!id}
                        formError={formError}
                        items={series?.dados ? series.dados.map(serie => ({ label: serie.nome, value: serie.id })) : []}
                        loading={series?.loading}
                    />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12} >
                    <InputCustom
                        control={control}
                        label={'Data de início de aplicação'}
                        name={'data_aplicacao_inicio'}
                        formError={formError}
                        mask={"99/99/9999"}
                    />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                    <InputCustom
                        control={control}
                        label={'Data de fim de aplicação'}
                        name={'data_aplicacao_fim'}
                        formError={formError}
                        mask={"99/99/9999"}
                    />
                </Grid>
            </Grid>
            {renderFooter({ backDisabled: true, nextAction: onSubmit })}
        </Card >
    )
}

export default FormAvaliacao;