import Service from "../../api/Service";
import { getDataFormatadaDaDataCompleta } from "../../utils/formatDate";
import AvaliacoesApi from "./api/AvaliacoesApi";
import { alteraAvaliacao } from "./reducer";

export default class AvaliacoesServices {
  static getAvaliacoes(dispatch, params) {
    Service.factoryRequest(dispatch, AvaliacoesApi.getAvaliacoes, true, params);
  }
  static getEnsinos(dispatch, params) {
    Service.factoryRequest(dispatch, AvaliacoesApi.getEnsinos, true, params);
  }
  static getCamposExperiencia(dispatch, params) {
    Service.factoryRequest(dispatch, AvaliacoesApi.getCamposExperiencia, true, params);
  }
  static getItems(dispatch, params) {
    Service.factoryRequest(dispatch, AvaliacoesApi.getItems, true, params);
  }
  static getSeries(dispatch, params) {
    Service.factoryRequest(dispatch, AvaliacoesApi.getSeries, true, params);
  }
  static getHabilidades(dispatch, params) {
    Service.factoryRequest(dispatch, AvaliacoesApi.getHabilidades, true, params);
  }
  static salvaAvaliacao(dispatch, params, callback) {
    Service.factoryRequest(dispatch, AvaliacoesApi.salvaAvaliacao, true, params, callback);
  }
  static excluirAvaliacao(dispatch, params, callback, callbackError) {
    const action = (success) => (success ? callback() : callbackError());
    Service.factoryRequest(dispatch, AvaliacoesApi.excluirAvaliacao, true, params, action);
  }
  static getDetalhes(dispatch, params) {
    Service.factoryRequest(dispatch, AvaliacoesApi.getDetalhes, true, params);
  }
  static getDadosAvaliacao(dispatch, params) {
    Service.factoryRequest(dispatch, AvaliacoesApi.getDadosAvaliacao, true, params);
  }
  static getRespostasAlunos(dispatch, params) {
    Service.factoryRequest(dispatch, AvaliacoesApi.getRespostasAlunos, true, params);
  }
  static getAvaliacaoAlunoItens(dispatch, params) {
    Service.factoryRequest(dispatch, AvaliacoesApi.getAvaliacaoAlunoItens, true, params);
  }
  static getShortLink(dispatch, params, callback) {
    Service.factoryRequest(dispatch, AvaliacoesApi.getShortLink, true, params, callback);
  }
  static formatAvaliacao(avaliacao) {
    let itens = {};
    avaliacao.itens.forEach((i) => {
      let campo_id = i.habilidade.campo_experiencia_id;
      if (itens[campo_id]) {
        itens[campo_id].push(i);
      } else {
        itens[campo_id] = [i];
      }
    });

    let data_aplicacao_inicio = getDataFormatadaDaDataCompleta(avaliacao.data_aplicacao_inicio);
    let data_aplicacao_fim = getDataFormatadaDaDataCompleta(avaliacao.data_aplicacao_fim);
    let itens_selecionados = itens;
    let itens_lista = avaliacao.itens;
    let num_questoes = avaliacao.num_questoes;
    let descricao = avaliacao.descricao;
    let ensino_id = avaliacao.ensino.id;
    let id = avaliacao.id;
    let serie_ano_id = avaliacao.serie_ano_id;
    let serie_ano_sigla = avaliacao.serie_ano.sigla;

    return {
      id,
      data_aplicacao_inicio,
      data_aplicacao_fim,
      itens_selecionados,
      itens_lista,
      num_questoes,
      descricao,
      ensino_id,
      serie_ano_id,
      serie_ano_sigla,
    };
  }

  static getCamposSelecionadosByItens(itens, itensLista, campos) {
    let lista_campos_experiencia = [];

    Object.keys(itens).forEach((i) => {
      let campo_nome = campos.find((c) => c.id === parseInt(i))?.nome;
      let questao_inicial = itensLista.findIndex((item) => item.habilidade.campo_experiencia_id === parseInt(i));

      let campo = {
        campo_id: parseInt(i),
        campo_nome,
        quantidade_questoes: itens[i].length,
        questao_inicial: questao_inicial + 1,
      };
      lista_campos_experiencia.push(campo);
    });

    return lista_campos_experiencia.sort((a, b) => (b.questao_inicial < a.questao_inicial ? 1 : -1));
  }

  static formataAvaliacaoComItens(avaliacao) {
    if (avaliacao) {
      if (avaliacao.avaliacao_itens) {
        let avaliacao_itens = avaliacao.avaliacao_itens.sort((a, b) => (b.sequencial < a.sequencial ? 1 : -1));
        return { ...avaliacao, itens: avaliacao_itens.map((a) => ({ ...a.item, avaliacao_item_id: a.id })) };
      } else {
        return avaliacao;
      }
    } else {
      return null;
    }
  }

  static formataAvaliacaoAlunoItens(itens) {
    if (itens) {
      let obj = {};
      itens.forEach((i) => {
        obj[`${i.item_id}`] = i.item_alternativa_id;
      });

      return obj;
    } else {
      return null;
    }
  }
}
