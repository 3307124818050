import { Button } from '@mui/material';
import React from 'react';
import InputFileCustom from '../../../../components/InputFileCustom/InputFileCustom';
import '../../styles/itens.css'

const ItemAlternativa = ({ index, alternativa, correta, excluir }) => {

    const { descricao, descricaoImg, } = alternativa
    const color = correta ? 'green' : 'lightslategray'

    return (
        <div className='div-container-alternativa' style={{ borderColor: color }} >
            <div className='div-container-alternativa-text' >
                <div className='div-container-alternativa-index' style={{ backgroundColor: color }}>
                    <span>{index}</span>
                </div>
                {!!descricao &&
                    <div style={{ marginLeft: 10, flex: 1 }}>
                        <div
                            style={{ maxHeight: 130, overflow: 'hidden' }}
                            dangerouslySetInnerHTML={{ __html: descricao }} />
                    </div>
                }
                {descricaoImg &&
                    <div className='alternativa-imagem'>
                        <InputFileCustom
                            height={80}
                            image={descricaoImg}
                            name={descricaoImg?.name}
                        />
                    </div>
                }
            </div>
            <Button
                onClick={() => excluir(index)}
                style={{ padding: '1px 8px' }}
                color='error'
                variant='contained'
                size='small'>
                Apagar
            </Button>
        </div>
    )
}

export default ItemAlternativa;