import { createSlice } from '@reduxjs/toolkit'

const INITAL_STATE = {
    login: {
        email: '',
        senha: '',
        formError: {},
        loading: false
    },
    cadastro: {
        nome: '',
        email: '',
        senha: '',
        cpf: '',
        telefone: '',
        estado_id: '',
        cidade_id: '',
        data_nascimento: '',
        tipo: '',
        loading: false,
        formError: {}
    },
    cidades: {
        dados: [],
        loading: false
    },
    estados: {
        dados: [],
        loading: false
    },
}

const autenticacaoSlice = createSlice({
    name: 'autenticacaoReducer',
    initialState: INITAL_STATE,
    reducers: {
        changeLogin: (state, action) => {
            if (action.payload) {
                state.login = { ...state.login, ...action.payload }
            } else {
                state.login = INITAL_STATE.login
            }
        },
        changeCadastro: (state, action) => {
            if (action.payload) {
                state.cadastro = { ...state.cadastro, ...action.payload }
            } else {
                state.cadastro = INITAL_STATE.cadastro
            }
        },
        changeCidades: (state, action) => {
            state.cidades = { ...state.cidades, ...action.payload }
        },
        changeEstados: (state, action) => {
            state.estados = { ...state.estados, ...action.payload }
        },
    },
})


export const { changeLogin, changeCadastro, changeCidades, changeEstados } = autenticacaoSlice.actions
export default autenticacaoSlice.reducer