import ApiRequest from "../../../api/ApiRequest";
import { alteraDetalhes, alteraItem, alteraPaginaAtual, listaEnsinos, listaHabilidades, listaItems } from "../reducer";
import { ENSINOS_WS, HABILIDADES_WS, ITENS_WS, } from "../../../api/routes";
import toastr from "toastr";
import ItensServices from "../ItensServices";

export default class ItensApi extends ApiRequest {

    static getEnsinos(obj, ignoreCache, params) {
        return (dispatch) => {
            const onResponse = (obj, success) => {
                dispatch(listaEnsinos(obj))
            }

            let url = super.getFormatUrl(ENSINOS_WS, params)
            return super.requestAuth(url, { method: "get" }, obj, onResponse, "Não foi possível buscar ensinos, tente novamente.")
        };
    }

    static getDadosItem(obj, ignoreCache, dados, callback) {
        return (dispatch) => {
            const onResponse = (obj, success) => {
                let { dados } = obj

                if (dados) {
                    let item = ItensServices.formataItem(dados)
                    dispatch(alteraItem(item))
                }

                if (success || obj.error) {
                    callback()
                }
            }

            let url = `${ITENS_WS}/${dados.id}`
            return super.requestAuth(url, { method: "get" }, obj, onResponse, "Não foi possível buscar dados do item, tente novamente.")
        };
    }

    static getDetalhesItem(obj, ignoreCache, dados) {
        return (dispatch) => {
            const onResponse = (obj, success) => {
                let dados = {}

                if (obj.dados) {
                    dados = ItensServices.formataItem(obj.dados)
                }
                dispatch(alteraDetalhes({ ...obj, dados }))
            }

            let url = `${ITENS_WS}/${dados.id}?with[0]=professor&with[1]=habilidade.campo_experiencia`
            return super.requestAuth(url, { method: "get" }, obj, onResponse, "Não foi possível buscar dados do item, tente novamente.")
        };
    }

    static getItens(obj, ignoreCache, dados) {
        return (dispatch) => {
            const onResponse = (obj, success) => {
                dispatch(listaItems(obj))
                dispatch(alteraPaginaAtual(dados.page + 1))
            }

            if (dados.limpaLista) {
                dispatch(listaItems())
            }

            let url = `${ITENS_WS}?with[0]=ensino&with[1]=habilidade&with[2]=alternativas&with[3]=professor&with[4]=habilidade.campo_experiencia&limit=9&page=${dados.page}`

            if (dados.descricao) {
                url += `&descricao=${dados.descricao}`
            }

            if (dados.ensino_id) {
                url += `&ensino_id=${dados.ensino_id}`
            }

            if (dados.habilidade_id) {
                url += `&habilidade_id=${dados.habilidade_id}`
            }

            if (dados.visibilidade !== undefined) {
                url += `&visibilidade=${dados.visibilidade}`
            }

            return super.requestAuth(url, { method: "get" }, obj, onResponse, "Não foi possível buscar itens, tente novamente.")
        };
    }

    static getHabilidades(obj, ignoreCache, params,) {
        return (dispatch) => {
            const onResponse = (obj, success) => {
                let dados = []
                if (obj.dados) {
                    dados = obj.dados.map(habilidade => ItensServices.formataHabilidade(habilidade))
                }
                dispatch(listaHabilidades({ ...obj, dados }))
            }

            let url = super.getFormatUrl(HABILIDADES_WS, params)
            return super.requestAuth(url, { method: "get" }, obj, onResponse, "Não foi possível buscar habilidades, tente novamente.")
        };
    }

    static salvarItem(obj, ignoreCache, params, callback) {
        return (dispatch) => {

            const success = (response) => {
                if (response) {
                    toastr.success(params.id ? "Item alterado com sucesso" : "Item cadastrado com sucesso.");
                    callback()
                }
            }
            const error = (msg, error, results) => {
                let msgError = msg
                if(results.errors){
                    Object.keys(results.errors).forEach(el => {
                        results.errors[el].forEach(erro => {
                            msgError += `<br>- ${erro}`
                        })
                    })
                }
                toastr.warning(msgError)
                callback(true)
            }


            let parametros = {};
            parametros['habilidade_id'] = params.habilidade.id
            parametros['ensino_id'] = params.ensino.id
            parametros['visibilidade'] = !params.visibilidade ? '1' : '0'
            parametros['descricao'] = params.descricao
            parametros[`alternativas[${params.gabarito}][gabarito]`] = 1

            if (params.descricaoImg) {
                parametros['img'] = params.descricaoImg
            }
            params.alternativas.forEach((a, k) => {
                if (a.descricao) {
                    parametros[`alternativas[${k}][descricao]`] = a.descricao
                }
                if (a.descricaoImg) {
                    parametros[`item_alternativa_${k}`] = a.descricaoImg
                }
            })

            let url = ITENS_WS

            if (params.id) {
                url += `/${params.id}`
                parametros['_method'] = 'PUT'
            }

            return super.requestCadastro(parametros, url, success, error)
        };
    }

    static excluirItem(obj, ignoreCache, params, action) {
        return (dispatch) => {
            const onResponse = (obj, success) => {

                if (success) {
                    toastr.success("Item excluido com sucesso")
                    action(true)
                }

                if (obj.error) {
                    toastr.warning(obj.msgError)
                    action(false)
                }
            }


            let url = `${ITENS_WS}/${params.id}`

            return super.requestAuth(url, { method: "delete" }, obj, onResponse, "Não foi excluir item, tente novamente")
        };
    }

}
