import { createSlice } from '@reduxjs/toolkit'

const INITAL_STATE = {
    listaItens: [],
    itens: null,
    paginaAtual: 1,
    ensinos: null,
    habilidades: null,
    itemDetalhes: null,
    item: {
        descricaoImg: undefined,
        descricao: '',
        ensino: undefined,
        habilidade: undefined,
        visibilidade: false,
        alternativas: [],
        gabarito: '',
    },
    erros: {}
}

const itensReducer = createSlice({
    name: 'itensReducer',
    initialState: INITAL_STATE,
    reducers: {
        listaItems: (state, action) => {
            if (action.payload) {
                if (action.payload.dados) {
                    state.listaItens =
                        action.payload.dados.current_page === 1 ?
                            [...action.payload.dados.data] :
                            [...state.listaItens, ...action.payload.dados.data]
                }
                state.itens = { ...action.payload }
            } else {
                state.itens = INITAL_STATE.itens;
                state.listaItens = INITAL_STATE.listaItens
            }
        },
        alteraItem: (state, action) => {
            state.item = action.payload ? { ...state.item, ...action.payload } : INITAL_STATE.item
        },
        listaEnsinos: (state, action) => {
            state.ensinos = action.payload ? { ...action.payload } : INITAL_STATE.ensinos
        },
        listaHabilidades: (state, action) => {
            state.habilidades = action.payload ? { ...action.payload } : INITAL_STATE.habilidades
        },
        alteraErro: (state, action) => {
            state.erros = action.payload ? { ...action.payload } : INITAL_STATE.erros
        },
        alteraPaginaAtual: (state, action) => {
            state.paginaAtual = action.payload
        },
        alteraDetalhes: (state, action) => {
            state.itemDetalhes = action.payload
        },

    },
})

export const { listaEnsinos, listaItems, alteraItem, listaHabilidades, alteraErro, alteraPaginaAtual, alteraDetalhes } = itensReducer.actions
export default itensReducer.reducer