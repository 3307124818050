export const PERFIS = {
  ADMIN: 1,
  PROFESSOR: 2,
  ALUNO: 3,
};

export const NOME_PERFIL = {
  1: "Gestor",
  2: "Professor",
  3: "Aluno",
};

export const TIPOS = [
  { id: "1", nome: "PRIVADO" },
  { id: "0", nome: "PÚBLICO" },
];

export const LETRAS_ALTERNATIVAS = {
  1: "A",
  2: "B",
  3: "C",
  4: "D",
  5: "E",
};

export const configCkEditor = {
  toolbar: [
    "heading",
    "|",
    "bold",
    "italic",
    "strikethrough",
    "underline",
    "link",
    "|",
    "numberedList",
    "bulletedList",
    "|",
    "outdent",
    "indent",
    "alignment",
    "|",
    "undo",
    "redo",
  ],
  heading: {
    options: [
      { model: "paragraph", title: "Normal", class: "ck-heading_paragraph" },
      { model: "heading1", view: "h1", title: "h1", class: "ck-heading_heading1" },
      { model: "heading2", view: "h2", title: "h2", class: "ck-heading_heading2" },
      { model: "heading3", view: "h3", title: "h3", class: "ck-heading_heading3" },
    ],
  },
};

export const configApexChart = {
  chart: {
    type: "bar",
    height: 350,
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "40%",
      endingShape: "rounded",
    },
    dataLabels: {
      position: "top",
      enabled: true,
    },
  },
  dataLabels: {
    enabled: true,
    formatter: function (val) {
      return val + "%";
    },
    style: {
      fontSize: "10px",
    },
  },
  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"],
  },
  xaxis: {
    categories: [],
    labels: {
      rotate: 0,
      rotateAlways: false,
      style: {
        fontSize: "10px",
      },
    },
  },
  yaxis: {
    title: {
      text: "Percentual (%)",
    },
  },
  fill: {
    opacity: 1,
  },
  tooltip: {
    y: {
      formatter: function (val) {
        return val + "%";
      },
    },
  },
};
