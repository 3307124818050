import { Button, Card, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ModalTemplate from '../../../../components/modal/ModalTemplate';
import ItensServices from '../../../itens/ItensServices';
import { Item } from '../../../itens/components/ItemDetalhes'

const FiltrosCamposExperiencia = ({ ano, ensino, setCampoSelecionado, campoSelecionado, itens_selecionados, campos }) => {

    const [itensFormatados, setItensFormatados] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);

    useEffect(() => {
        let formats = []
        Object.keys(itens_selecionados).forEach((item) => {
            itens_selecionados[item].forEach((i, k) => {
                let format = ItensServices.formataItem(i);
                let questaoInicial = campos.find(c => format.habilidade.campo_experiencia_id === c.campo_id)?.questao_inicial
                formats.push({ ...i, index: (questaoInicial + k) });
            })
        })
        let sorted = formats.sort((a, b) => b.index < a.index ? 1 : -1);
        setItensFormatados(sorted)
    }, [itens_selecionados, campos])


    const DisciplinaItem = ({ campo, index }) => {
        return (
            <div
                onClick={() => setCampoSelecionado(campo.campo_id)}
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: 8,
                    padding: '10px 4px',
                    border: '1.5px solid #dedede',
                    borderRadius: 4,
                    fontSize: 12,
                    fontWeight: 'bold',
                    cursor: 'pointer',
                    backgroundColor: campoSelecionado === campo.campo_id ? '#dedede80' : 'transparent'
                }} >
                <span>{index + 1}. {campo.campo_nome}</span>
                <span>{itens_selecionados[campo.campo_id] ? itens_selecionados[campo.campo_id].length : 0}/{campo.quantidade_questoes}</span>
            </div>
        )
    }

    return (
        <>
            <Card style={{ padding: 8 }}>
                <div
                    style={{
                        textAlign: 'center',
                        fontSize: 12,
                        margin: '5px 0px 10px',
                        padding: 4,
                        backgroundColor: '#2e7d32',
                        borderRadius: 4,
                        border: '1px solid #dedede',
                        color: '#fff'
                    }}>
                    {ano} - {ensino}
                </div>
                {campos.map((c, k) => {
                    return <DisciplinaItem campo={c} key={k} index={k} />
                })}
                <div style={{ marginTop: 15, textAlign: 'end' }} >
                    <Button
                        size={'small'}
                        variant='contained'
                        color='primary'
                        onClick={() => setModalVisible(true)}>
                        <span style={{ fontSize: 10 }} >Visualizar avaliação</span>
                    </Button>
                </div>
            </Card>
            <ModalTemplate
                open={modalVisible}
                closeModal={() => setModalVisible(false)}
                title={"Avaliação"}
                desabilitarBotao={true}>
                <Grid container spacing={1} >
                    {itensFormatados.length === 0 && <div style={{ margin: 8, fontSize: 14 }} >Nenhum item adicionado.</div>}
                    {itensFormatados.map((i, k) => {
                        return <Item key={k} dados={i} questaoInicial={i.index} />
                    })}
                </Grid>
            </ModalTemplate>
        </>
    )
}

export default FiltrosCamposExperiencia;