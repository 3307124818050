import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FaSearch, FaTrash } from 'react-icons/fa';
import ModalTemplate from '../modal/ModalTemplate';
import './styles.css'

const InputFileCustom = ({ image, name, remover, height = 200, styleImage = {} }) => {

    const [modal, setModal] = useState(false)

    let src = image?.name ? URL.createObjectURL(image) : image


    return (
        <>
            <div
                onClick={() => setModal(true)}
                className='div-container-fileinput'
                style={{ height }}>
                <div
                    className='div-container-image'
                    style={{ height: height - 16 }}>
                    <div
                        className='div-container-fileinput-hover'>
                        <FaSearch size={30} />
                    </div>
                    <img src={src} style={{ width: '100%', ...styleImage, objectFit: 'contain' }} />
                    {
                        name &&
                        <div className='div-container-image-name'>
                            <span style={{ color: '#fff', fontSize: 12 }} >{name}</span>
                        </div>
                    }
                    {remover &&
                        <Button
                            onClick={remover}
                            color='error'
                            variant='contained'
                            size='small'
                            style={{ position: 'absolute', top: 5, right: 5, padding: 0, fontSize: 11 }}>
                            Remover
                        </Button>
                    }
                </div>
            </div>
            <ModalTemplate
                open={modal}
                closeModal={() => setModal(false)}
                title={name}
                desabilitarBotao={true}>
                <div style={{ textAlign: 'center' }} >
                    <img
                        className='imagem-modal-detalhes'
                        src={src} />
                </div>
            </ModalTemplate>
        </>

    )
}

export default InputFileCustom;