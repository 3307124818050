import React from 'react';
import '../styles.css'

const PercentualAcertoErro = ({ acerto, erro, title }) => {
    return (
        <div className='card-info-dashboard' >
            <div>{title}</div>
            <div className='container'>
                <div>{acerto}%</div>
                <div className='container-porcentagem'>
                    <div className='porcentagem' style={{ backgroundColor: `#00e396`, width: `${acerto}%`, borderTopLeftRadius: 5, borderBottomLeftRadius: 5, }} />
                    <div className='porcentagem' style={{ backgroundColor: `#ff607b`, width: `${erro}%`, borderTopRightRadius: 5, borderBottomRightRadius: 5 }} />
                </div>
                <div>{erro}%</div>
            </div>
        </div>
    )

}

export default PercentualAcertoErro;