import ApiRequest from "../../../api/ApiRequest";
import { changeAvaliacaoAluno, changeAutenticacao, changeRespostasAluno } from "../reducer";
import toastr from "toastr";
import { AVALIACOES_ALUNO_WS, AVALIACOES_HASH_WS, AVALIACOES_WS, CADASTRA_USUARIO_ALUNO_WS, ENVIA_RESPOSTAS_WS, LOGIN_LEGADO_WS, LOGIN_WS, } from "../../../api/routes";
import AvaliacoesServices from "../../avaliacoes/AvaliacoesServices";

export default class AlunoAvaliacaoApi extends ApiRequest {

    static getAvaliacaoPorHash(obj, ignoreCache, params) {
        return (dispatch) => {
            const onResponse = (obj, success) => {
                let dados = AvaliacoesServices.formataAvaliacaoComItens(obj?.dados)
                dispatch(changeAvaliacaoAluno({ ...obj, dados }));
            }
            let url = `${AVALIACOES_HASH_WS}/${params.hash}?with[0]=professor&with[1]=avaliacao_itens.item.habilidade.campo_experiencia`
            return super.requestAuth(url, { method: "get" }, obj, onResponse, "Não foi possível encontrar avaliação, tente novamente.")
        };
    }

    static cadastraAluno(obj, ignoreCache, params = {}, action) {
        return (dispatch) => {
            const success = (obj) => {
                dispatch(changeAutenticacao({ loadingCadastro: false }))
                if (obj && obj.id) {
                    action(obj);
                }
            }

            const error = (e, error) => {
                if (e && e.errors && error) {
                    Object.keys(e.errors).forEach(key => {
                        let msg = e.errors[key];
                        toastr.warning(msg)
                    })
                }
                dispatch(changeAutenticacao({ loadingCadastro: false }))
            }

            let body = {
                ...params,
                run_login: 1,
                long_token: 1,
                data_nascimento: params.data_nascimento.split('/').reverse().join('-')
            }

            dispatch(changeAutenticacao({ loadingCadastro: true }))

            return super.request(body, CADASTRA_USUARIO_ALUNO_WS, success, error)
        };
    }

    static login(obj, ignoreCache, params = {}, action) {
        return (dispatch) => {
            const success = (obj) => {
                if (obj && obj.id) {
                    action(obj);
                }
                dispatch(changeAutenticacao({ loading: false }))
            }

            const error = (e) => {
                dispatch(changeAutenticacao({ loading: false }))
                if (e?.errors) {
                    Object.keys(e.errors).forEach(key => {
                        let msg = e.errors[key];
                        toastr.warning(msg)
                    })
                }

            }

            dispatch(changeAutenticacao({ loading: true }))

            return super.request(params, LOGIN_WS, success, error)
        };
    }

    static getRespostasAluno(obj, ignoreCache, params) {
        return (dispatch) => {
            const onResponse = (obj, success) => {
                dispatch(changeRespostasAluno(obj))
            }

            let url = super.getFormatUrl(AVALIACOES_ALUNO_WS, params)
            return super.requestAuth(url, { method: "get" }, obj, onResponse, "Não foi possivel buscar dados da avaliacao.")
        };
    }

    static enviaRespostas(obj, ignoreCache, params = {}, callback) {
        return (dispatch) => {
            const success = (response) => {
                if (response) {
                    toastr.success("Avaliação enviada com sucesso");
                    callback(true)
                }
            }
            const error = (msg) => {
                toastr.warning(msg)
                callback(false)
            }

            return super.requestCadastro(params, ENVIA_RESPOSTAS_WS, success, error, true)
        };
    }

}
