import { Button, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import toastr from 'toastr';
import InputFileCustom from '../../../../components/InputFileCustom/InputFileCustom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '../../styles/itens.css'
import { configCkEditor } from '../../../../config/constants';

const TitleInput = ({ children }) => {
    return <Typography variant='subtitle1'>{children}</Typography>
}

const FormAlternativa = ({ onSave, onClose }) => {

    const ref = useRef()

    const [descricao, setDescricao] = useState("")
    const [descricaoImg, setDescricaoImg] = useState()

    const submit = (e) => {
        e.preventDefault()
        salva()
        setDescricao('')
        setDescricaoImg(undefined)
    }

    const salva = () => {
        if (descricao.length === 0 && descricaoImg === undefined) {
            toastr.warning('Digite uma descrição ou adicione alguma imagem');
            return
        }
        onSave({ descricao, descricaoImg });
    }

    return (
        <>
            <div className='div-container-form-alternativa'>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12}>
                        <div className='div-title-questao-item'>
                            <TitleInput>Descrição da alternativa</TitleInput>
                            <label htmlFor='contained-button-file-alternative'>
                                <Button
                                    component="span"
                                    size='small'
                                    variant='contained'>
                                    {descricaoImg ? `Alterar imagem` : `Adicionar imagem`}
                                </Button>
                            </label>
                            <input
                                id={"contained-button-file-alternative"}
                                ref={ref}
                                type="file"
                                style={{ display: 'none' }}
                                accept="image/*"
                                onChange={(e) => setDescricaoImg(e.target.files[0])}
                            />
                        </div>
                    </Grid>
                    {descricaoImg &&
                        <Grid item xs={12} md={4}>
                            <InputFileCustom
                                height={125}
                                remover={() => {
                                    setDescricaoImg(undefined);
                                    ref.current.value = null
                                }}
                                image={descricaoImg}
                                name={descricaoImg.name}
                            />
                        </Grid>
                    }
                    <Grid className='form-alternativa' item xs={12} md={descricaoImg ? 8 : 12}>
                        <CKEditor
                            editor={ClassicEditor}
                            data={descricao}
                            onChange={(event, editor) => {
                                const data = editor.getData();
                                setDescricao(data)
                            }}
                            config={configCkEditor}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} style={{ textAlign: 'end' }} >
                        <Button
                            onClick={onClose}
                            size='small'
                            color='inherit'
                            style={{ marginRight: 10 }}>
                            FECHAR
                        </Button>
                        <Button
                            onClick={submit}
                            size='small'
                            variant='contained'
                            color='info' >
                            ADICIONAR
                        </Button>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default FormAlternativa;