import { Autocomplete, Box, CircularProgress, FormControl, styled, TextField } from '@mui/material';
import React from 'react';

const Input = styled(TextField)`
  background: #fff;
  border-radius: 4px;
`;

const AutoCompleteCustom = ({ size, options, value, optionEqualName, optionLabel, onChange, label, placeholder, empty, error, loading, style, disabled }) => {

    return (
        <FormControl fullWidth size={size ?? "small"} style={style}>
            <Autocomplete
                options={options}
                autoHighlight
                freeSolo
                noOptionsText={empty ? empty : 'Nenhuma item encontrado'}
                value={value ?? ''}
                loading={loading}
                disabled={disabled}
                loadingText={"Carregando..."}
                isOptionEqualToValue={(option, value) => option[optionEqualName ?? 'id'] + "" === value[optionEqualName ?? "id"] + ""}
                onChange={(event, newValue) => onChange(newValue)}
                getOptionLabel={(option) => option[optionLabel ?? "nome"] || ""}
                renderOption={(props, option) => (
                    <Box component="li"  {...props} >
                        {option[optionLabel ?? 'nome']}
                    </Box>
                )}
                renderInput={(params) => (
                    <Input
                        {...params}
                        size={size ?? "small"}
                        error={!!error}
                        helperText={error ?? ""}
                        label={label}
                        placeholder={placeholder ?? "Selecione"}
                        value={value}
                        sx={{ background: "#fff" }}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            )
                        }}
                        InputLabelProps={{ shrink: true, fontSize: "0.75rem", }}
                        inputProps={{
                            ...params.inputProps,
                            style: { fontSize: "14px", height: 23 },
                        }}
                    />
                )}
            />
        </FormControl>
    )
}

export default AutoCompleteCustom;