import Service from "../../api/Service";
import AlunoAvaliacaoApi from "./api/AlunoAvaliacaoApi";

export default class AlunoAvaliacaoServices {

    static getAvaliacaoPorHash(dispatch, params) {
        Service.factoryRequest(dispatch, AlunoAvaliacaoApi.getAvaliacaoPorHash, true, params);
    }

    static cadastraAluno(dispatch, params, callback) {
        Service.factoryRequest(dispatch, AlunoAvaliacaoApi.cadastraAluno, true, params, callback);
    }

    static loginAluno(dispatch, params, callback) {
        Service.factoryRequest(dispatch, AlunoAvaliacaoApi.login, true, params, callback);
    }

    static getRespostasAluno(dispatch, params) {
        Service.factoryRequest(dispatch, AlunoAvaliacaoApi.getRespostasAluno, true, params);
    }

    static enviaRespostas(dispatch, params, callbackSuccess, callbackError) {
        let callback = (success) => success ? callbackSuccess() : callbackError()
        Service.factoryRequest(dispatch, AlunoAvaliacaoApi.enviaRespostas, true, params, callback);
    }
}



