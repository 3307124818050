import { createSlice } from "@reduxjs/toolkit"

const INITIAL_STATE = {
    ensinos: null,
    series: null,
    disciplinas: null,
    habilidades: null,
    habilidadesGeral: null,
    totais: null,
    acertosPorHabilidade: null,
    acertosPorAreaConhecimento: null,
}

const dashboardSlice = createSlice({
    initialState: INITIAL_STATE,
    name: 'dashboardReducer',
    reducers: {
        listaEnsinos: (state, action) => {
            state.ensinos = action.payload ? { ...action.payload } : null
        },
        listaSeries: (state, action) => {
            state.series = action.payload ? { ...action.payload } : null
        },
        listaDisciplinas: (state, action) => {
            state.disciplinas = action.payload ? { ...action.payload } : null
        },
        listaTotais: (state, action) => {
            state.totais = action.payload ? { ...action.payload } : null
        },
        listaAcertosPorHabilidade: (state, action) => {
            state.acertosPorHabilidade = action.payload ? { ...action.payload } : null
        },
        listaAcertosPorAreaConhecimento: (state, action) => {
            state.acertosPorAreaConhecimento = action.payload ? { ...action.payload } : null
        },
        listaHabilidadesGeral: (state, action) => {
            state.habilidadesGeral = action.payload ? { ...action.payload } : null
        },
        listaHabilidades: (state, action) => {
            if (action.payload) {
                if (action.payload.dados) {
                    if (Array.isArray(action.payload.dados)) {
                        state.habilidades = { ...action.payload, dados: { listHabilidades: [...action.payload.dados] } }
                    } else {
                        if (action.payload.dados.current_page === 1) {
                            state.habilidades = { ...action.payload, dados: { ...action.payload.dados, listHabilidades: [...action.payload.dados.data] } }
                        } else {
                            state.habilidades = { ...action.payload, dados: { ...action.payload.dados, listHabilidades: [...state.habilidades.dados.listHabilidades, ...action.payload.dados.data] } }
                        }
                    }

                } else {
                    state.habilidades = { ...action.payload, dados: { listHabilidades: state?.habilidades?.dados?.listHabilidades ? state.habilidades.dados.listHabilidades : [] } }
                }

            } else {
                state.habilidades = INITIAL_STATE.habilidades;
            }
        },
    }
})


export const { listaDisciplinas,
    listaEnsinos,
    listaSeries,
    listaHabilidades,
    listaHabilidadesGeral,
    listaTotais,
    listaAcertosPorHabilidade,
    listaAcertosPorAreaConhecimento
} = dashboardSlice.actions

export default dashboardSlice.reducer