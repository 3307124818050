import React, { } from 'react';


const CardInfo = ({ title, rightTitle, children, style = {} }) => {
    return (
        <div className='dashboard_div_card' style={style} >
            <div className='header'>
                <div>{title}</div>
                {rightTitle && rightTitle()}
            </div>
            {children}
        </div>
    )
}

export default CardInfo;