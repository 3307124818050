import React, { useState } from 'react';
import { Button, Container, Divider, Grid, Typography } from '@mui/material';
import logo from '../../../../assets/imgs/logo.png'
import './styles.css'
import { useForm } from 'react-hook-form';
import InputCustom from '../../../../components/InputCustom';
import { useNavigate } from 'react-router-dom';
import { ROOT } from '../../../../routes/HashRouter';
import AutenticacaoServices from '../../AutenticacaoServices';
import { useDispatch } from 'react-redux';
import toastr from 'toastr';

const RecuperarSenha = () => {

    const [loading, setLoading] = useState(false)
    const [formError, setFormError] = useState({})
    const [codigoServer, setCodigoServer] = useState()
    const [inserirSenha, setInserirSenha] = useState(false)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { handleSubmit, control } = useForm({
        defaultValues: {
            email: '',
            codigo: '',
            senha: '',
            confirmaSenha: ''
        }
    })

    const callbackError = (erro) => { setFormError(erro); setLoading(false) }

    const onSubmit = (dados) => {

        if (inserirSenha) {
            if (dados.senha == dados.confirmaSenha) {
                setLoading(true)

                AutenticacaoServices.resetaSenha(
                    dispatch,
                    {
                        codigo: dados.codigo,
                        email: dados.email,
                        senha: dados.senha
                    },
                    (success) => success ? navigate(ROOT) : setLoading(false),
                    callbackError)

            } else {
                toastr.warning("Senhas não conferem")
            }
            return
        }

        if (!!codigoServer) {
            if (dados.codigo.trim().toLowerCase() == codigoServer.trim().toLowerCase()) {
                setInserirSenha(true)
            } else {
                toastr.warning("Código inválido")
            }
            return
        }

        setLoading(true)
        AutenticacaoServices.enviaEmail(
            dispatch,
            { tipo: 2, email: dados.email },
            codigoServer => { setCodigoServer(codigoServer); setLoading(false); setFormError({}) },
            callbackError)
    }

    return (
        <div className='container-recupera-senha' >
            <Container maxWidth={'sm'}>
                <div className='div-form-recupera-senha'>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }} >
                        <img src={logo} style={{ height: 40, }} />
                        <div style={{ marginLeft: 10 }}>
                            <Typography variant='h6' style={{ fontWeight: '300', }}>RECUPERAR SENHA</Typography>
                            <Typography variant='subtitle2' style={{ fontWeight: '300', marginTop: -5, fontSize: 12, color: '#666' }}>
                                {codigoServer ?
                                    'Informe o código, que enviamos para o seu e-mail' :
                                    'Insira seu e-mail para enviarmos um link de recuperação de senha.'}
                            </Typography>
                        </div>
                    </div>
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        style={{ marginTop: 20 }} >
                        <Grid container spacing={2}>
                            <Grid item md={12} xs={12} >
                                <InputCustom
                                    style={{ width: '100%' }}
                                    control={control}
                                    name={'email'}
                                    label={'Digite seu e-mail'}
                                    type={'email'}
                                    formError={formError}
                                />
                            </Grid>
                            {
                                !!codigoServer &&
                                <Grid item md={12} xs={12} >
                                    <InputCustom
                                        style={{ width: '100%' }}
                                        control={control}
                                        name={'codigo'}
                                        label={'Digite o código enviado'}
                                    />
                                </Grid>
                            }
                            {
                                inserirSenha &&
                                <>
                                    <Grid item md={12} xs={12} >
                                        <InputCustom
                                            style={{ width: '100%' }}
                                            control={control}
                                            name={'senha'}
                                            label={'Digite uma nova senha para seu usuário'}
                                            type={'password'}
                                        />
                                    </Grid>
                                    <Grid item md={12} xs={12} >
                                        <InputCustom
                                            style={{ width: '100%' }}
                                            control={control}
                                            name={'confirmaSenha'}
                                            label={'Confirme a senha digitada acima'}
                                            type={'password'}
                                        />
                                    </Grid>
                                </>
                            }
                        </Grid>
                        <Divider style={{ margin: '20px 0px 10px' }} />
                        <Grid
                            container
                            justifyContent={'flex-end'}
                            spacing={2}>
                            <Grid item md={2} >
                                <Button
                                    onClick={() => navigate(ROOT)}
                                    style={{ width: '100%', color: '#666' }} >Voltar</Button>
                            </Grid>
                            <Grid item md={2}>
                                <Button
                                    type='submit'
                                    disabled={loading}
                                    style={{ width: '100%' }}
                                    variant='contained'>
                                    {loading ? 'ENVIANDO...' : 'ENVIAR'}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Container>
        </div>
    )
}

export default RecuperarSenha;