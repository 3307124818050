import React, { useEffect, useState } from 'react'
import Main from '../views/template/Main';
import { ROOT } from './HashRouter';
import { Navigate } from 'react-router-dom';
import useLocalStorage from '../hooks/useLocalStorage'
import { KEY_USER_BNCC_APP } from '../config/GlobalKeys'
import { useDispatch } from 'react-redux';
import { setUser } from '../reducers/global'
import { PERFIS } from '../config/constants';
import toastr from 'toastr';

const PrivateRoute = (props) => {

    const { get } = useLocalStorage()
    const dispatch = useDispatch()

    const [userState, setUserState] = useState()

    useEffect(() => {
        let user = get(KEY_USER_BNCC_APP);
        dispatch(setUser(user))

        if (user?.perfil === PERFIS.ALUNO) {
            setUserState(null)
            toastr.warning("Você não tem acesso a este modulo!");
        } else {
            setUserState(user)
        }

    }, [])

    if (userState === undefined) {
        return <p>Carregando...</p>
    }

    if (!userState) {
        return <Navigate to={ROOT} />;
    }

    return <Main {...props} />
}


export default PrivateRoute;
