import { TextField } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import Masked from 'vanilla-masker'

const InputCustom = ({ control, name, rules = {}, label, placeholder, style = {}, type, formError = {}, mask, size, handleKeyDown = () => {} }) => {

    const masked = (value) => {
        if (mask) {
            return Masked.toPattern(value, mask)
        } else {
            return value
        }
    }

    return (
        <Controller
            control={control}
            rules={rules}
            name={name}
            mask={mask}
            render={({ field }) =>
                <TextField
                    {...field}
                    onChange={(e) => field.onChange(masked(e.target.value))}
                    label={label}
                    onKeyDown={handleKeyDown}
                    fullWidth
                    style={style}
                    placeholder={placeholder}
                    size={size ? size : 'small'}
                    type={type ? type : 'text'}
                    error={!!formError[name]}
                    helperText={formError[name]}
                    variant="outlined" />}>
        </Controller>
    )
}

export default InputCustom;