export const validaCriaConta = (obj) => {
    let erros = {}

    // if (!obj.tipo) {
    //     erros['tipo'] = 'Campo obrigatório'
    // }

    // if (!obj.data_nascimento) {
    //     erros['data_nascimento'] = 'Campo obrigatório'
    // }

    if (!obj.nome) {
        erros['nome'] = 'Campo obrigatório'
    }

    if (!obj.estado_id) {
        erros['estado_id'] = 'Campo obrigatório'
    }
    if (!obj.cidade_id) {
        erros['cidade_id'] = 'Campo obrigatório'
    }

    if (!obj.email) {
        erros['email'] = 'Campo obrigatório'
    }

    if (!obj.senha) {
        erros['senha'] = 'Campo obrigatório'
    }
    if (obj && obj.senha.length <= 4) {
        erros['senha'] = 'A senha precisa ter mais de 4 caracteres'
    }

    // if (!obj.cpf) {
    //     erros['cpf'] = 'Campo obrigatório'
    // }

    // if (!obj.telefone) {
    //     erros['telefone'] = 'Campo obrigatório'
    // }
    // console.log(erros)
    return Object.keys(erros).length > 0 ? erros : null
}

export const validaLogin = (obj) => {
    let erros = {}
    if (!obj.email) {
        erros[`email`] = 'Campo obrigatório'
    }
    if (!obj.senha) {
        erros[`senha`] = 'Campo obrigatório'
    }

    return Object.keys(erros).length > 0 ? erros : null
}

export const validaEnviaEmail = (obj) => {
    let erros = {}

    var re = /\S+@\S+\.\S+/;
    if (!re.test(obj.email)) {
        erros[`email`] = 'Formato inválido';
    }

    return Object.keys(erros).length > 0 ? erros : null
}

export const validaResetaSenha = (obj) => {
    let erros = {}

    if (!obj.senha) {
        erros[`senha`] = 'Campo obrigatório';
    }

    return Object.keys(erros).length > 0 ? erros : null
}