import { Button, Grid, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import InputCustom from '../../../../components/InputCustom';
import ModalTemplate from '../../../../components/modal/ModalTemplate';
import { KEY_USER_BNCC_APP } from '../../../../config/GlobalKeys';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import { setUser } from '../../../../reducers/global';
import AlunoAvaliacaoServices from '../../AlunoAvaliacaoServices';
import { changeAutenticacao } from '../../reducer';
import { validaLoginAluno } from '../../validation';
import logo from '../../../../assets/imgs/logo.png'

const Autenticacao = () => {

    const {
        autenticacao: {
            formErrorCadastro,
            formError,
            loading,
            loadingCadastro
        },
    } = useSelector(state => state.alunoAvaliacao)

    const { control, handleSubmit, reset } = useForm({
        defaultValues: {
            cpf: '',
            data_nascimento: '',
            telefone: '',
            email: '',
            nome: '',
            senha: '',
            senha_cadastro: '',
            cpf_cadastro: ''
        }
    })

    const dispatch = useDispatch()
    const { set } = useLocalStorage()

    const [modalCadastro, setModalCadastro] = useState(false)

    const validaFormulario = (dados) => {
        dispatch(changeAutenticacao({ formError: {}, formErrorCadastro: {} }))
        let validation = validaLoginAluno(dados, modalCadastro);
        if (validation) {
            let obj = {}
            if (modalCadastro) {
                obj['formErrorCadastro'] = validation
            } else {
                obj['formError'] = validation
            }
            dispatch(changeAutenticacao(obj))
            return false
        }
        return true
    }

    const callback = (user) => {
        setModalCadastro(false)
        reset()
        set(KEY_USER_BNCC_APP, user)
        dispatch(setUser(user))
    }

    const realizaLogin = (dados) => {
        let valid = validaFormulario(dados)
        if (valid) {
            AlunoAvaliacaoServices.loginAluno(dispatch, dados, callback)
        }
    }

    const realizaCadastro = (dados) => {
        let valid = validaFormulario(dados)
        if (valid) {
            AlunoAvaliacaoServices.cadastraAluno(dispatch, { ...dados, cpf: dados.cpf_cadastro, senha: dados.senha_cadastro }, callback)
        }
    }

    const submit = (dados) => {
        if (!modalCadastro) {
            realizaLogin(dados)
        } else {
            realizaCadastro(dados);
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit(submit)} >
                <Grid container spacing={1} >
                    <Grid xs={12} item style={{ marginBottom: 4, marginTop: 16 }} >
                        <div style={{ fontWeight: 'bold', }} >Dados de acesso</div>
                    </Grid>
                    <Grid item xs={12}>
                        <InputCustom
                            size={"small"}
                            control={control}
                            name="cpf"
                            label={"Insira seu CPF"}
                            formError={formError}
                            mask={"999.999.999-99"}
                        />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 4 }} >
                        <InputCustom
                            size={"small"}
                            control={control}
                            name="senha"
                            label={"Informe sua senha"}
                            formError={formError}
                            type={"password"}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} style={{ marginTop: 4 }} >
                        <Button
                            size='small'
                            disabled={loading}
                            type='submit'
                            variant='contained'>
                            {!loading ? 'ACESSAR' : 'ACESSANDO...'}
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} className={'button-cadastro'}>
                        <Button size='small' onClick={() => setModalCadastro(true)}>NÃO POSSUI CADASTRO?</Button>
                    </Grid>
                </Grid>
            </form>
            <ModalTemplate
                imgTitle={<img src={logo} style={{ height: 40, marginRight: 10 }} />}
                open={modalCadastro}
                closeModal={() => setModalCadastro(false)}
                title={"Cadastre seu usuário"}
                habilitarConfirmacao={true}
                onConfirm={handleSubmit(submit)}
                loading={loadingCadastro}
                textConfirm={"Salvar"} >
                <Grid container spacing={1}>
                    <Grid item xs={12} style={{ marginTop: 4 }} >
                        <InputCustom
                            size={"small"}
                            control={control}
                            name="nome"
                            label={"Informe seu nome"}
                            formError={formErrorCadastro}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} sm={12} style={{ marginTop: 4 }} >
                        <InputCustom
                            size={"small"}
                            control={control}
                            name="email"
                            label={"Informe seu e-mail"}
                            formError={formErrorCadastro}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} sm={6} style={{ marginTop: 4 }} >
                        <InputCustom
                            size={"small"}
                            control={control}
                            name="cpf_cadastro"
                            label={"Informe seu CPF"}
                            formError={formErrorCadastro}
                            mask={"999.999.999-99"}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} sm={6} style={{ marginTop: 4 }} >
                        <InputCustom
                            size={"small"}
                            control={control}
                            name="senha_cadastro"
                            label={"Informe sua senha"}
                            formError={formErrorCadastro}
                            type={"password"}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} sm={6} style={{ marginTop: 4 }} >
                        <InputCustom
                            size={"small"}
                            control={control}
                            name="data_nascimento"
                            label={"Informe sua data de nascimento"}
                            formError={formErrorCadastro}
                            mask={"99/99/9999"}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} sm={6} style={{ marginTop: 4 }} >
                        <InputCustom
                            size={"small"}
                            control={control}
                            name="telefone"
                            label={"Informe seu telefone"}
                            formError={formErrorCadastro}
                            mask={"(99) 99999-9999"}
                        />
                    </Grid>
                </Grid>
            </ModalTemplate>
        </>
    )
}

export default Autenticacao;