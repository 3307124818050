import { Card, Divider, Grid, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputFileCustom from "../../../components/InputFileCustom/InputFileCustom";
import LoadingData from "../../../components/LoadingData";
import ModalTemplate from "../../../components/modal/ModalTemplate";
import ItensServices from "../ItensServices";
import { alteraDetalhes } from "../reducer";
import { MdInfo } from "react-icons/md";
import { LETRAS_ALTERNATIVAS } from "../../../config/constants";

const ContainerItem = ({ children, style = {}, title, isLetra }) => {
  return (
    <div style={{ padding: 8, backgroundColor: "#dedede50", ...style }}>
      {title && (
        <span className={isLetra ? "alternativa-letra" : ""} style={{ fontWeight: "bold" }}>
          {title}
        </span>
      )}
      {children}
    </div>
  );
};

export const Item = ({ dados, questaoInicial, resposta_id }) => {
  const [modalDetalhes, setModalDetalhes] = useState();

  const styleAlternativa = (a) => {
    if (resposta_id) {
      if (resposta_id === a.id) {
        if (dados.item_alternativa_id === resposta_id) {
          return {
            backgroundColor: "#0ebc0e10",
            border: "1px solid green",
          };
        } else {
          return {
            backgroundColor: "#ff000040",
            border: "1px solid red",
          };
        }
      }
    }
    return dados.item_alternativa_id === a.id
      ? {
        backgroundColor: "#0ebc0e10",
        border: "1px solid green",
      }
      : {
        backgroundColor: "#fff",
        border: "1px solid #dedede",
      };
  };

  return (
    <>
      <Grid
        xs={12}
        item
        style={{ margin: "10px 0px 0px 8px", padding: 8, border: "1px solid #dedede", borderRadius: 4 }}
      >
        {questaoInicial && (
          <Grid
            item
            xs={12}
            style={{ marginBottom: 10, display: "flex", justifyContent: "space-between", alignItems: "center" }}
          >
            <div
              style={{
                fontSize: 14,
                backgroundColor: "#EEE",
                width: 80,
                textAlign: "center",
                borderRadius: 2,
                padding: 4,
                marginLeft: 6,
                marginTop: 5,
              }}
            >
              Questão {questaoInicial}
            </div>
            <IconButton onClick={() => setModalDetalhes(dados)} color="info">
              <MdInfo />
            </IconButton>
          </Grid>
        )}
        <Grid item xs={12}>
          <ContainerItem
            title={questaoInicial ? "Descrição:" : "Item:"}
            style={{ backgroundColor: "#fff", border: "0px", borderRadius: 4 }}
          >
            {dados.descricao && <div style={{ marginTop: 10 }} dangerouslySetInnerHTML={{ __html: dados.descricao }} />}
            {dados.descricaoImg && <img src={dados.descricaoImg} className={"imagem-descricao-item"} />}
          </ContainerItem>
        </Grid>
        {dados.alternativas.map((a, k) => (
          <Grid item xs={12} key={k} style={{ marginTop: 8 }}>
            <ContainerItem
              isLetra={true}
              title={LETRAS_ALTERNATIVAS[a.sequencial]}
              style={{
                borderRadius: 4,
                display: "flex",
                ...styleAlternativa(a),
              }}
            >
              {a.descricao && (
                <div style={{ color: "#666", marginTop: 5 }} dangerouslySetInnerHTML={{ __html: a.descricao }} />
              )}
              {a.descricaoImg && <img src={a.descricaoImg} className={"imagem-descricao-item"} />}
            </ContainerItem>
          </Grid>
        ))}
        {/* {resposta_id && (
          <>
            <Divider style={{ margin: "15px 0px" }} />
            <div
              style={{
                padding: 8,
                backgroundColor: resposta_id === dados.item_alternativa_id ? "#0ebc0e10" : "#ff000040",
                borderRadius: 4,
              }}
            >
              <div style={{ fontWeight: "bold" }}>Resposta selecionada: </div>
              <div
                style={{ color: "#666", marginTop: 5 }}
                dangerouslySetInnerHTML={{ __html: dados.alternativas.find((al) => al.id === resposta_id)?.descricao }}
              />
            </div>
          </>
        )} */}
      </Grid>
      <ModalDetalhesItem dados={modalDetalhes} fechar={() => setModalDetalhes(undefined)} />
    </>
  );
};

const ItemDetalhes = ({ id, onClose, item }) => {
  const [itemFormatado, setItemFormatado] = useState();

  const { itemDetalhes } = useSelector((state) => state.itens);

  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      ItensServices.getDetalhesItem(dispatch, { id });
    }
  }, [id]);

  useEffect(() => {
    if (item) {
      let itemFormat = ItensServices.formataItem(item);
      setItemFormatado(itemFormat);
    }
  }, [item]);

  const fechar = () => {
    dispatch(alteraDetalhes(null));
    onClose();
  };

  let dados = itemFormatado ? itemFormatado : itemDetalhes && itemDetalhes.dados;

  return (
    <ModalTemplate
      style={{ width: "100%" }}
      open={!!id || !!item}
      closeModal={fechar}
      title={"Detalhes item"}
      desabilitarBotao={true}
    >
      {itemDetalhes && <LoadingData obj={itemDetalhes} msg="Buscando dados..." />}
      <Grid container spacing={1}>
        {dados && Object.keys(dados).length > 0 && (
          <>
            <Grid item xs={12}>
              <ContainerItem title={"Habilidade:"}>
                <div style={{ fontSize: 12, color: "#666", marginTop: 5 }}>
                  {dados.habilidade.codigo}: {dados.habilidade.descricao}
                </div>
              </ContainerItem>
            </Grid>
            <Grid
              item
              xs={12}
              md={dados.habilidade.campo_experiencia ? 4 : 6}
              sm={dados.habilidade.campo_experiencia ? 4 : 6}
            >
              <ContainerItem title={"Ensino:"}>
                <div style={{ fontSize: 12, color: "#666", marginTop: 5 }}>{dados.ensino.nome}</div>
              </ContainerItem>
            </Grid>
            {dados.habilidade.campo_experiencia && (
              <Grid item xs={12} md={4} sm={4}>
                <ContainerItem title={"Disciplina:"}>
                  <div style={{ fontSize: 12, color: "#666", marginTop: 5 }}>
                    {dados.habilidade.campo_experiencia.nome}
                  </div>
                </ContainerItem>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              md={dados.habilidade.campo_experiencia ? 4 : 6}
              sm={dados.habilidade.campo_experiencia ? 4 : 6}
            >
              <ContainerItem title={"Tipo:"}>
                <div style={{ fontSize: 12, color: "#666", marginTop: 5 }}>
                  {dados.visibilidade ? "PÚBLICO" : "PRIVADO"}
                </div>
              </ContainerItem>
            </Grid>
            {dados.professor && (
              <Grid item xs={12}>
                <ContainerItem title={"Criado por:"}>
                  <div style={{ fontSize: 12, color: "#666", marginTop: 5 }}>{dados.professor.nome}</div>
                </ContainerItem>
              </Grid>
            )}
            <Item dados={dados} />
          </>
        )}
      </Grid>
    </ModalTemplate>
  );
};

export default ItemDetalhes;
export const ModalDetalhesItem = ({ dados, fechar }) => {
  const { campos_experiencia } = useSelector((state) => state.avaliacoes);
  if (dados && dados.habilidade && !dados.habilidade.campo_experiencia) {
    let campo_experiencia_aux = campos_experiencia.dados.find(el => el.id + "" === dados.habilidade.campo_experiencia_id + "")
    dados = { ...dados, habilidade: { ...dados.habilidade, campo_experiencia: campo_experiencia_aux } }
  }
  return (
    <ModalTemplate
      style={{ width: "100%" }}
      open={!!dados}
      closeModal={fechar}
      title={"Detalhes item"}
      desabilitarBotao={true}
    >
      <Grid container spacing={1}>
        {dados && (
          <>
            <Grid item xs={12}>
              <ContainerItem title={"Disciplina:"}>
                <div style={{ fontSize: 12, color: "#666", marginTop: 5 }}>
                  {dados?.habilidade?.campo_experiencia?.nome}
                </div>
              </ContainerItem>
            </Grid>
            <Grid item xs={12}>
              <ContainerItem title={"Habilidade:"}>
                <div style={{ fontSize: 12, color: "#666", marginTop: 5 }}>
                  {dados?.habilidade?.codigo}: {dados?.habilidade?.descricao}
                </div>
              </ContainerItem>
            </Grid>
          </>
        )}
      </Grid>
    </ModalTemplate>
  );
};
