import React, { useEffect } from 'react';
import { Button, Container, Divider, Grid, Typography } from '@mui/material';
import logo from '../../../../assets/imgs/logo.png'
import InputCustom from '../../../../components/InputCustom';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { DASHBOARD, ROOT } from '../../../../routes/HashRouter';
import SelectCustom from '../../../../components/SelectCustom';
import { useDispatch, useSelector } from 'react-redux';
import { changeCadastro } from '../../reducer';
import AutenticacaoServices from '../../AutenticacaoServices';
import toastr from 'toastr';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import { KEY_USER_BNCC_APP } from '../../../../config/GlobalKeys';
import './styles.css'

const perfis = [
    { value: 1, label: 'Estudante' },
    { value: 2, label: 'Professor' },
    { value: 3, label: 'Coordenador' },
    { value: 4, label: 'Diretor' },
    { value: 5, label: 'Gestor' },
    { value: 6, label: 'Outro' }
]

const handleKeyDown = (event) => {
    let teclaSelecionada = event.code;
    if (teclaSelecionada === "Space") {
      event.preventDefault();
    }
  };

const NovaConta = () => {

    const dispatch = useDispatch()

    const { set } = useLocalStorage()

    const {
        cadastro: {
            nome,
            email,
            senha,
            // cpf,
            // telefone,
            estado_id,
            cidade_id,
            tipo,
            loading,
            // data_nascimento,
            formError,
        },
        estados: {
            dados: estados,
            loading: loadingEstados
        },
        cidades: {
            dados: cidades,
            loading: loadingCidades
        }
    } = useSelector(state => state.autenticacao)

    const { control, handleSubmit, watch, setValue } = useForm({
        defaultValues: {
            nome: nome,
            email: email,
            senha: senha,
            // cpf: cpf,
            // telefone: telefone,
            estado_id: estado_id,
            cidade_id: cidade_id,
            tipo: tipo,
            // data_nascimento: data_nascimento
        }
    })

    useEffect(() => {
        AutenticacaoServices.getEstados(dispatch)
    }, [])

    useEffect(() => {
        const estado = watch('estado_id');
        if (estado) {
            setValue('cidade_id', '');
            AutenticacaoServices.getCidades(dispatch, { uf: estado })
        }
    }, [watch('estado_id')])

    useEffect(() => {
        return () => {
            dispatch(changeCadastro())
        }
    }, [])

    const navigate = useNavigate()

    const onSubmit = (dados) => {
        const callbackLogin = (user) => {
            dispatch(changeCadastro(dados))
            set(KEY_USER_BNCC_APP, user)
            toastr.success('Usuário cadastrado com sucesso!');
            navigate(DASHBOARD);
        }
        dispatch(changeCadastro(dados))
        AutenticacaoServices.cadastraUsuario(dispatch, dados, callbackLogin)
    }

    return (
        <div className='container-nova-conta'>
            <Container maxWidth={'md'} >
                <div className='div-form-nova-conta'>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }} >
                        <img src={logo} style={{ height: 40, }} />
                        <div style={{ marginLeft: 10 }}>
                            <Typography variant='h6' style={{ fontWeight: '300', }}>CADASTRAR NOVA CONTA</Typography>
                            <Typography variant='subtitle2' style={{ fontWeight: '300', marginTop: -5, fontSize: 12, color: '#666' }}>
                                Insira seus dados para que tenha acesso a plataforma BNCC Consult
                            </Typography>
                        </div>
                    </div>
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        style={{ marginTop: 20 }} >
                        <Grid container spacing={2}>
                            <Grid item md={12} xs={12}>
                                <InputCustom
                                    size={"medium"}
                                    control={control}
                                    name={'nome'}
                                    label={'Digite seu nome'}
                                    formError={formError}
                                />
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <InputCustom
                                    control={control}
                                    size={"medium"}
                                    name={'email'}
                                    label={'Digite seu email'}
                                    formError={formError}
                                />
                            </Grid>
                            <Grid item md={12} xs={12}>
                                <InputCustom
                                    control={control}
                                    size={"medium"}
                                    name={'senha'}
                                    label={'Digite sua senha'}
                                    handleKeyDown={handleKeyDown}
                                    formError={formError}
                                    type={'password'}
                                />
                            </Grid>
                            {/* <Grid item md={4} xs={12}>
                                <InputCustom
                                    control={control}
                                    size={"medium"}
                                    name={'cpf'}
                                    label={'Digite seu CPF'}
                                    formError={formError}
                                    mask={"999.999.999-99"}
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <InputCustom
                                    control={control}
                                    size={"medium"}
                                    name={'telefone'}
                                    label={'Digite seu telefone'}
                                    formError={formError}
                                    mask={"(99) 99999-9999"}
                                />
                            </Grid>
                            <Grid item md={4} xs={12}>
                                <InputCustom
                                    control={control}
                                    size={"medium"}
                                    name={'data_nascimento'}
                                    label={'Digite sua data de nascimento'}
                                    formError={formError}
                                    mask={"99/99/9999"}
                                />
                            </Grid> */}
                            <Grid item md={6} xs={12}>
                                <SelectCustom
                                    control={control}
                                    name={'estado_id'}
                                    label={'Selecione seu estado'}
                                    formError={formError}
                                    items={estados}
                                    loading={loadingEstados}
                                    size={"medium"}
                                />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <SelectCustom
                                    control={control}
                                    name={'cidade_id'}
                                    label={'Selecione sua cidade'}
                                    formError={formError}
                                    items={cidades}
                                    loading={loadingCidades}
                                    size={"medium"}
                                />
                            </Grid>
                        </Grid>
                        <Divider style={{ margin: '20px 0px 10px' }} />
                        <Grid
                            container
                            justifyContent={'flex-end'}
                            spacing={2}>
                            <Grid item md={2} >
                                <Button
                                    onClick={() => navigate(ROOT)}
                                    style={{ width: '100%', color: '#666' }} >Voltar</Button>
                            </Grid>
                            <Grid item md={2}>
                                <Button
                                    type='submit'
                                    disabled={loading}
                                    style={{ width: '100%' }}
                                    variant='contained'>
                                    {loading ? 'SALVANDO...' : 'SALVAR'}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </Container >
        </div >
    )
}

export default NovaConta;