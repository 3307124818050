import { Container, IconButton, Menu, MenuItem, Avatar } from '@mui/material';
import * as React from 'react';
import { FaBars, FaChevronDown, FaUserCircle } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import logo from '../../assets/imgs/logo.png'
import { KEY_USER_BNCC_APP } from '../../config/GlobalKeys';
import useLocalStorage from '../../hooks/useLocalStorage';
import toastr from 'toastr';
import './nav.css'
import { setUser } from '../../reducers/global';
import { NOME_PERFIL } from '../../config/constants';
import { useNavigate } from 'react-router-dom';
import { PERFIL } from '../../routes/HashRouter'

function Nav({ onOpen }) {
    const navigate = useNavigate()

    const { remove } = useLocalStorage()
    const dispatch = useDispatch()
    const { user } = useSelector(state => state.global)

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const logout = () => {
        remove(KEY_USER_BNCC_APP);
        dispatch(setUser(null))
        window.location.reload()
    }

    return (
        <div className='nav-bncc'>
            <Container
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                maxWidth='xl' >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton onClick={onOpen} >
                        <FaBars color='#fff' />
                    </IconButton>
                    <img src={logo} style={{ marginLeft: 20, width: 40, height: 40 }} />
                    <span style={{ marginLeft: 10, fontSize: 20, color: '#fff', fontWeight: '600' }} >BNCC Consult</span>
                </div>
                <div className='div-user' onClick={(event) => setAnchorEl(event.currentTarget)} >
                    <Avatar src={user?.relacionado?.thumb} size={25} />
                    <div style={{ marginLeft: 10 }}>
                        <div style={{ color: '#fff', }}>{user?.relacionado?.nome}</div>
                        <div style={{ fontSize: 12, color: '#dedede' }} >{NOME_PERFIL[user?.perfil]}</div>
                    </div>
                    <FaChevronDown size={12} style={{ marginLeft: 10 }} />
                </div>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => setAnchorEl(null)}
                    MenuListProps={{ 'aria-labelledby': 'basic-button', }}>
                    <MenuItem onClick={() => { navigate(PERFIL) }}>
                        Perfil
                    </MenuItem>
                    <MenuItem onClick={() => { logout(); setAnchorEl(null) }}>
                        Sair
                    </MenuItem>
                </Menu>
            </Container>
        </div>
    )
}
export default Nav;