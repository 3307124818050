import { AppKey, APP_KEY, APP_TOKEN } from "../config/GlobalKeys";
import Dados from "../models/Dados";
import toastr from "toastr";
import { getUser, logout } from "../config/auth";

export default class ApiRequest {

    static TIMEOUT = 40000;

    static setError(e, obj, msg_error, update) {
        console.log('error', e)
        obj.clear();
        obj.msgError = msg_error;
        obj.error = true;
        update(obj);
    }

    static getFormatUrl(url, obj) {
        let formBody = [];
        for (let property in obj) {
            let encodedKey = encodeURIComponent(property);
            let encodedValue = encodeURIComponent(obj[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");

        return `${url}?${formBody}`;
    }

    static getParamsFormData(obj) {
        let formData = new FormData();
        for (let k in obj) {
            if (obj[k])
                formData.append(k, obj[k]);
        }
        // formData.append("token", APP_TOKEN)
        return formData;
    }

    static getParams(obj) {
        let formBody = [];
        for (let property in obj) {
            let encodedKey = encodeURIComponent(property);
            let encodedValue = encodeURIComponent(obj[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        return formBody;
    }

    static async request(dados, url, success, error, tokenHeader = true, method = 'post') {

        let req = undefined;
        let headers = tokenHeader ? new Headers({ 'AppKey': APP_KEY }) : new Headers();

        let controller = new AbortController();

        if (method === 'post' || method === 'put' || method === 'delete') {
            let body = ApiRequest.getParamsFormData(dados)
            req = fetch(url, {
                method,
                headers,
                body: body,
                signal: controller.signal
            })
        }

        if (method === 'get') {
            req = fetch(`${url}`, { method, headers, })
        }


        req.then(response => {
            if (response.ok) {
                response.json().then(result => {
                    success(result);
                }).catch(e => {
                    if (e.message) {
                        toastr.warning(e.message);
                        error(e, true);
                    }
                });
            } else {
                response.json().then(result => {
                    if (response.status === 500) {
                        if (result.message === "Token expirado, efetue login novamente!") {
                            logout();
                            window.location.reload()
                        } else {
                            toastr.warning(result.message);
                            error(result, true);
                        }
                        return
                    }

                    if (response.status === 403) {
                        logout();
                        window.location.reload()
                    }

                    toastr.warning(result.message);
                    error(result, true);
                })
            }
        }).catch(e => {
            error(e);
            if (e.message) {
                toastr.warning(e.message);
                error(e, true);
            }
        });
    }

    static async requestAuth(url, options, obj, update, msg_error) {

        if (!options.headers) {
            options.headers = {}
        }

        options.headers['AppKey'] = APP_KEY;
        options.headers['Content-type'] = 'application/json';
        options['signal'] = obj.controller.signal;

        let user = await getUser()
        try {
            options.headers['TokenUser'] = user.id + ':' + user.token + ':' + APP_KEY;
        } catch (e) { }

        obj.loading = true;
        update(obj);

        const idTimeout = setTimeout(() => {
            obj.controller.abort();
            ApiRequest.setError("", obj, msg_error, update);

        }, ApiRequest.TIMEOUT);

        fetch(url, options).then(
            response => {
                if (response.ok) {
                    response.json()
                        .then(result => {
                            obj.clear();
                            obj.dados = result;
                            update(obj, true);
                            clearTimeout(idTimeout);
                        })
                        .catch(e => {
                            ApiRequest.setError(e, obj, e.message || msg_error, update);
                            clearTimeout(idTimeout);
                        })
                } else {

                    if (response.status === 401) {
                        // localStorage.clear()
                        // window.location.reload()
                        response.json()
                            .then(result => {
                                ApiRequest.setError("", obj, result.message, update);
                            }).catch(e => console.log(e))
                        return
                    }

                    if (response.status === 403) {
                        response.json()
                            .then(result => {
                                if (result.message === "Token expirado, efetue login novamente!") {
                                    logout();
                                    window.location.reload();
                                } else {
                                    ApiRequest.setError("", obj, result.message || msg_error, update);
                                    clearTimeout(idTimeout);
                                }
                            }).catch(e => {
                                ApiRequest.setError(e, obj, e.message || msg_error, update);
                                clearTimeout(idTimeout);
                            })
                        return
                    }

                    if (response.status === 410) {
                        response.json()
                            .then(result => {
                                ApiRequest.setError("", obj, result.message, update);
                            }).catch(e => console.log(e))
                        return
                    }

                    if (response.status === 420) {
                        response.json()
                            .then(result => {
                                ApiRequest.setError("", obj, result.message, update);
                            }).catch(e => console.log(e))
                        return
                    }

                    if (response.status === 500) {
                        response.json()
                            .then(result => {
                                if (result.message === "Token expirado, efetue login novamente!") {
                                    logout();
                                    window.location.reload();
                                } else {
                                    ApiRequest.setError("", obj, result.message || msg_error, update);
                                    clearTimeout(idTimeout);
                                }
                            }).catch(e => {
                                ApiRequest.setError(e, obj, e.message || msg_error, update);
                                clearTimeout(idTimeout);
                            })
                        return
                    }

                    ApiRequest.setError(response, obj, msg_error, update);
                    clearTimeout(idTimeout);
                }
            }
        ).catch(e => {
            ApiRequest.setError(e, obj, msg_error, update);
            clearTimeout(idTimeout);
        });
    }

    static async requestCadastro(dados, url, success, error, auth = true, method = 'post') {
        let formData = null;
        formData = ApiRequest.getParamsFormData(dados);

        let headers = {}
        if (auth) {
            let user = await getUser()
            try {
                headers['TokenUser'] = user.id + ':' + user.token + ':' + APP_KEY;
            } catch (e) { }
        } else {
            headers['AppKey'] = APP_KEY;
        }

        let controller = new AbortController();

        fetch(url, {
            method,
            headers,
            body: formData,
            signal: controller.signal
        }).then(response => {
            if (response.ok) {
                response.json().then(result => {
                    success(result);
                }).catch(e => {
                    if (e.message) {
                        toastr.warning(e.message);
                        error(e.message, true);
                    }
                });
            } else {

                if (response.status === 401) {
                    localStorage.clear()
                    window.location.reload()
                }

                if (response.status === 400) {
                    response.json()
                        .then(result => {
                            error(result.message, true, result)
                        }).catch(e => console.log(e))
                    return
                }

                if (response.status === 410) {
                    response.json()
                        .then(result => {
                            error(result.message, true)
                        }).catch(e => console.log(e))
                    return
                }

                if (response.status === 420) {
                    response.json()
                        .then(result => {
                            error(result.message, true)
                        }).catch(e => console.log(e))
                    return
                }

                if (response.status === 500) {
                    response.json()
                        .then(result => {
                            if (result.message === "Token expirado, efetue login novamente!") {
                                logout();
                                window.location.reload();
                            } else {
                                error(result.message, true)
                            }
                        }).catch(e => {
                            error(e.message, true)
                        })
                    return
                }

                error("error")
            }
        }).catch(e => {
            if (e.message) {
                toastr.warning(e.message);
                error(e.message, true);
            } else {
                error(e)
            }
        });
    }

}