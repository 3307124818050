import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../../../../components/header/Header';
import { AVALIACOES } from '../../../../routes/HashRouter';
import { Button, Card, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, Grid, Step, StepLabel, Stepper } from '@mui/material';
import FormAvaliacao from './Form';
import AvaliacoesServices from '../../AvaliacoesServices';
import { useDispatch, useSelector } from 'react-redux';
import CamposExperiencia from './CamposExperiencia';
import Items from './Items';
import { alteraAvaliacao, listaCamposExperiencias } from '../../reducer';

const Painel = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { id } = useParams()

    const [modalConfirmacao, setModalConfirmacao] = useState(false)
    const [step, setStep] = useState(0)

    const {
        ensinos,
        campos_experiencia,
        avaliacao: {
            data_aplicacao_inicio,
            data_aplicacao_fim,
            itens_selecionados,
            itens_lista,
            campos_selecionados,
            num_questoes,
            descricao,
            ensino_id,
            serie_ano_id,
            loading
        }
    } = useSelector(state => state.avaliacoes)

    //busca dados p/ edição de avaliação
    useEffect(() => {
        if (id) {
            dispatch(listaCamposExperiencias())
            AvaliacoesServices.getDadosAvaliacao(dispatch, { id })
        }
    }, [id])

    //busca lista de campos de experiencia p/edicao de avaliacao
    useEffect(() => {
        if (id && ensino_id) {
            AvaliacoesServices.getCamposExperiencia(dispatch, { ensino_id, limit: 0 })
        }
    }, [id, ensino_id])

    //busca e monta campos de experiencias selecionados p/ edicao de avaliacao
    useEffect(() => {
        if (
            id &&
            itens_lista.length > 0 &&
            campos_experiencia?.dados?.length > 0
        ) {
            let campos_selecionados = AvaliacoesServices.getCamposSelecionadosByItens(itens_selecionados, itens_lista, campos_experiencia?.dados)
            dispatch(alteraAvaliacao({ campos_selecionados }))
        }
    }, [id, campos_experiencia?.dados, itens_lista])


    useEffect(() => {
        if (!ensinos) {
            AvaliacoesServices.getEnsinos(dispatch, { limit: 0 })
        }
    }, [ensinos])

    useEffect(() => {
        return () => {
            dispatch(alteraAvaliacao())
        }
    }, [])

    const renderPage = () => {
        switch (step) {
            case 0:
                return <FormAvaliacao renderFooter={Footer} />
            case 1:
                return <CamposExperiencia renderFooter={Footer} />
            case 2:
                return <Items renderFooter={Footer} />
            default:
                return null;
        }
    }

    const submit = () => {
        let params = {
            id,
            ensino_id,
            descricao,
            num_questoes,
            data_aplicacao_inicio,
            data_aplicacao_fim,
            serie_ano_id
        }

        let aux = 0;
        let campos_sorted = [...campos_selecionados].sort((a, b) => b.questao_inicial < a.questao_inicial ? 1 : -1).map(c => c.campo_id);

        campos_sorted.forEach(c => {
            if (itens_selecionados[c]) {
                itens_selecionados[c].forEach((item) => {
                    params[`itens[${aux}]`] = item.id
                    aux++
                })
            }
        })

        AvaliacoesServices.salvaAvaliacao(dispatch, params, () => { dispatch(alteraAvaliacao()); navigate(AVALIACOES) })
    }

    const Footer = ({ backDisabled, nextDisabled, nextAction, backAction }) => {

        const voltar = () => {
            let nextStep = id ? 2 : 1

            if (backAction) {
                let valid = backAction()
                if (valid) {
                    setStep(st => st - nextStep);
                }
            } else {
                setStep(st => st - nextStep);
            }
        }

        const avancar = () => {
            let nextStep = id ? 2 : 1

            if (nextAction) {
                let valid = nextAction();
                if (valid) {
                    if (step === 2) {
                        submit()
                    } else {
                        setStep(st => st + nextStep)
                    }
                }
            } else {
                setStep(st => st + nextStep)
            }
        }

        return (
            <>
                <Divider style={{ margin: '20px 0px 10px' }} />
                <Grid
                    container
                    justifyContent={'end'}>
                    <Grid item style={{ marginRight: 10 }} >
                        <Button
                            disabled={!!backDisabled || loading}
                            onClick={() => setModalConfirmacao(true)}>
                            VOLTAR
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            disabled={!!nextDisabled || loading}
                            onClick={avancar}
                            variant="contained">
                            {loading ? 'CONCLUINDO...' : step === 2 ? 'CONCLUIR' : 'AVANÇAR'}
                        </Button>
                    </Grid>
                </Grid>
                <ModalDialogo onBack={voltar} />
            </>
        )
    }

    const ModalDialogo = ({ onBack }) => {
        return (
            <Dialog
                open={modalConfirmacao}
                onClose={() => setModalConfirmacao(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">
                    {"Atenção"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Voltando para o passo anterior seu progresso nesta etapa será perdido. Deseja realmente voltar?
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'flex-end', display: 'flex' }} >
                    <Button
                        style={{ width: 60 }}
                        onClick={() => setModalConfirmacao(false)}>
                        Não
                    </Button>
                    <Button
                        style={{ width: 60 }}
                        onClick={() => { setModalConfirmacao(false); onBack(); }}
                        autoFocus>
                        Sim
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <>
            <Header
                tooltip={"Voltar para listagem"}
                onBack={() => {
                    dispatch(alteraAvaliacao())
                    navigate(AVALIACOES)
                }}
                title={id ? "Alterar avaliação" : "Cadastrar nova avaliação"}
            />
            <Stepper
                activeStep={step}
                sx={{ marginBottom: "24px", marginTop: '24px' }} >
                {['Avaliação', 'Disciplinas', 'Concluir'].map((label) => {
                    return (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {renderPage()}
        </>
    )
}

export default Painel;