import { Button, Card, Divider, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LinearProgressCustom from "../../../../components/LinearProgressCustom";
import AvaliacoesServices from "../../AvaliacoesServices";
import { alteraAvaliacao } from "../../reducer";
import ItemsSelecionados from "./ItemsSelecionados";
import toastr from "toastr";
import ModalTemplate from "../../../../components/modal/ModalTemplate";
import AutoCompleteCustom from "../../../../components/AutoCompleteCustom";
import LoadingData from "../../../../components/LoadingData";
import CardItem from "./CardItem";
import IconButtonCustom from "../../../../components/IconButtonCustom";
import { FaEye } from "react-icons/fa";
import FiltrosCamposExperiencia from "./FiltrosCamposExperiencia";
import Painel from "../../../itens/components/form/Painel";
import { alteraItem, listaHabilidades, listaItems } from "../../../itens/reducer";

const Items = ({ renderFooter }) => {
  const {
    avaliacao: { id, num_questoes, itens_selecionados, campos_selecionados, ensino_id, serie_ano_id, serie_ano_sigla },
    itens,
    habilidades,
    ensinos,
    series,
  } = useSelector((state) => state.avaliacoes);

  const [campoSelecionado, setCampoSelecionado] = useState();
  const [habilidadeSelecionada, setHabilidadeSelecionada] = useState();
  const [novaHabilidade, setNovaHabilidade] = useState();
  const [modalNovoItem, setModalNovoItem] = useState(false);
  const [totalSelecionados, setTotalSelecionados] = useState(0);
  
  const dispatch = useDispatch();

  useEffect(() => {
    let total = 0;
    Object.keys(itens_selecionados).forEach((i) => {
      total += itens_selecionados[i].length;
    });
    setTotalSelecionados(total);
  }, [{ ...itens_selecionados }]);

  useEffect(() => {
    setCampoSelecionado(campos_selecionados[0]?.campo_id);

    if (!id) {
      let itens = {};
      campos_selecionados.forEach((c) => {
        itens[`${c.campo_id}`] = [];
      });
      dispatch(alteraAvaliacao({ itens_selecionados: itens }));
    }
  }, []);

  useEffect(() => {
    if (campoSelecionado) {
      dispatch(listaHabilidades(null));
      setHabilidadeSelecionada(null)
      AvaliacoesServices.getHabilidades(dispatch, {
        ensino_id,
        campo_experiencia_id: campoSelecionado,
        ano: serie_ano_sigla,
        // possui_itens: 1,
        limit: 0,
      });
    }

  }, [campoSelecionado]);

  useEffect(() =>{
    if(!habilidadeSelecionada && habilidades && !habilidades.loading && habilidades.dados && habilidades.dados.length > 0){
      setHabilidadeSelecionada(habilidades.dados[0])
    }
  }, [habilidades])

  useEffect(() => {
    if (habilidades && !habilidades.loading && campoSelecionado && habilidadeSelecionada && habilidadeSelecionada.id) {

      load(1, habilidadeSelecionada);
    }
  }, [habilidadeSelecionada]);

  useEffect(() => {
    if (novaHabilidade && novaHabilidade.id) {
      let habilidadeNova = habilidades && habilidades.dados && habilidades.dados.find(el => novaHabilidade.id === el.id)
      if (!habilidadeNova) {
        let aux = habilidades.dados.concat(novaHabilidade)
        dispatch(listaHabilidades({ ...habilidades, dados: aux }));
      }
    }
  }, [novaHabilidade]);

  const load = (page, habilidade) => {
    let params = {
      page: page,
      limit: 15,
    };

    if (habilidade) {
      params["habilidade_id"] = habilidade.id;
    } else {
      params["campo_experiencia_id"] = campoSelecionado;
      params["ano"] = serie_ano_sigla;
    }

    AvaliacoesServices.getItems(dispatch, params);
  };

  const adicionarItem = (item) => {
    if (!campoSelecionado || Object.keys(itens_selecionados).length === 0) {
      return;
    }
    let itemsSelecionados = { ...itens_selecionados };
    if (itemsSelecionados[campoSelecionado].find((i) => i.id === item.id)) {
      toastr.warning("Este item já foi selecionado como outra questão!");
      return;
    }

    let quantidadeQuestoes = campos_selecionados.find((c) => c.campo_id === campoSelecionado).quantidade_questoes;

    if (itemsSelecionados[campoSelecionado].length === parseInt(quantidadeQuestoes)) {
      toastr.warning("Limite total de itens já adicionado!");
      return;
    }
    dispatch(
      alteraAvaliacao({
        itens_selecionados: {
          ...itemsSelecionados,
          [campoSelecionado]: [...itens_selecionados[campoSelecionado], item],
        },
      })
    );
  };
  
  const removerItem = (item) => {
    if (!campoSelecionado || Object.keys(itens_selecionados).length === 0) {
      return;
    }
    let itemsSelecionados = { ...itens_selecionados };
    dispatch(
      alteraAvaliacao({
        itens_selecionados: {
          ...itemsSelecionados,
          [campoSelecionado]: itens_selecionados[campoSelecionado].filter((i) => i.id !== item.id),
        },
      })
    );
  };

  const atualizaItem = (dragIndex, hoverIndex) => {
    if (!campoSelecionado || Object.keys(itens_selecionados).length === 0) {
      return;
    }

    let itemsSelecionados = { ...itens_selecionados };
    let items = [...itemsSelecionados[campoSelecionado]];
    let cutOut = items.splice(dragIndex, 1)[0];
    items.splice(hoverIndex, 0, cutOut);

    dispatch(
      alteraAvaliacao({
        itens_selecionados: {
          ...itemsSelecionados,
          [campoSelecionado]: items,
        },
      })
    );
  };

  const validaTotalItens = () => {
    if (totalSelecionados !== num_questoes) {
      toastr.warning("Adicione a quantidade total de itens.");
      return false;
    }
    return true;
  };

  return (
    <>
      <Card style={{ padding: 8 }}>
        <Typography variant="h6">Status de seleção dos itens</Typography>
        <Box sx={{ width: "100%" }}>
          <LinearProgressCustom value={(totalSelecionados * 100) / num_questoes} arredonda={1} />
        </Box>
      </Card>
      <Grid container spacing={2} style={{ marginTop: 4 }}>
        <Grid item xs={12} md={3} sm={3}>
          <FiltrosCamposExperiencia
            ano={series?.dados?.find((s) => s.id === serie_ano_id)?.nome}
            ensino={ensinos?.dados ? ensinos.dados.find((e) => e.id === ensino_id)?.nome : ""}
            setCampoSelecionado={setCampoSelecionado}
            campoSelecionado={campoSelecionado}
            campos={campos_selecionados}
            itens_selecionados={itens_selecionados}
          />
        </Grid>
        <Grid item xs={12} md={6} sm={6}>
          <Card style={{ padding: 8 }}>
            <AutoCompleteCustom
              label={"Filtre por habilidade"}
              placeholder={"Selecione uma habilidade"}
              onChange={(value) => setHabilidadeSelecionada(value)}
              options={habilidades?.dados ? habilidades.dados : []}
              value={habilidadeSelecionada ?? ""}
              empty={"Nenhuma habilidade encontrada"}
            />
          </Card>
          <Card style={{ maxHeight: 550, overflowY: "auto", marginTop: 10 }}>
            {!itens?.loading && itens?.dados?.listItens?.length === 0 && (
              <div style={{ margin: 16, fontSize: 12, fontWeight: "bold" }}>Nenhum item encontrado!</div>
            )}
            {!((itens && itens.loading) || (habilidades && habilidades.loading)) && itens?.dados?.listItens &&
              itens.dados.listItens.map((i, k) => <CardItem adicionarItem={adicionarItem} disabled={(itens && itens.loading) || (habilidades && habilidades.loading)} item={i} key={k} />)}
            {itens && itens.dados && itens.dados.last_page > itens.dados.current_page && (
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Button
                  style={{ marginTop: 5, marginBottom: 20 }}
                  onClick={() => load(itens.dados.current_page + 1, habilidadeSelecionada)}
                  variant="contained"
                  size="small"
                  color="info"
                >
                  Ver mais
                </Button>
              </Grid>
            )}
            {itens && <LoadingData obj={itens} msg="Carregando itens..." />}
            <Button
              size="small"
              variant="contained"
              style={{ margin: "0px 8px 8px" }}
              color="info"
              onClick={() => {
                dispatch(alteraItem());
                setModalNovoItem(true);
              }}
            >
              Cadastrar novo item
            </Button>
          </Card>
        </Grid>
        <Grid item xs={12} md={3} sm={3}>
          <Card style={{ padding: 8, maxHeight: 550 }}>
            <span style={{ fontSize: 12, fontWeight: "bold" }}>Itens selecionados</span>
            <ItemsSelecionados
              items={itens_selecionados[campoSelecionado] ? itens_selecionados[campoSelecionado] : []}
              questaoInicial={campos_selecionados.find((c) => campoSelecionado === c.campo_id)?.questao_inicial}
              atualizaListaQuestoes={atualizaItem}
              handleRemoveQuestao={removerItem}
            />
          </Card>
        </Grid>
        <Grid item xs={12} style={{ marginBottom: 20 }}>
          {renderFooter({
            backAction: () => dispatch(alteraAvaliacao({ itens_selecionados: id ? itens_selecionados : {} })),
            nextAction: () => validaTotalItens(),
          })}
        </Grid>
      </Grid>
      {modalNovoItem && (
        <ModalTemplate
          title={"Cadastrar novo item"}
          footerVisible={false}
          closeModal={() => setModalNovoItem(false)}
          open={true}
        >
          <Painel
            onClose={() => setModalNovoItem(false)}
            headerVisible={false}
            onCallback={(habilidade) => {
              setModalNovoItem(false);
              load(1, habilidade);
              setHabilidadeSelecionada(habilidade)
              setNovaHabilidade(habilidade)
            }}
            ensino_id={ensino_id}
            campoSelecionado={campoSelecionado}
            habilidadeSelecionada={habilidadeSelecionada}
          />
        </ModalTemplate>
      )}
    </>
  );
};

export default Items;
