export const validaFormItem = (obj) => {
    let erros = {}

    if (!obj.descricao && !obj.descricaoImg) {
        erros['descricao'] = 'Descrição obrigatória';
    }

    if (!obj.ensino) {
        erros['ensino'] = 'Ensino obrigatório'
    }

    if (!obj.habilidade) {
        erros['habilidade'] = 'Habilidade obrigatória'
    }

    if (obj.alternativas && obj.alternativas.length === 0) {
        erros['alternativas'] = 'Alternativas obrigatórias'
    }

    if (obj.alternativas && obj.alternativas.length === 1) {
        erros['alternativas'] = 'Adicione mais de 1 alternativa'
    }

    if (obj.gabarito == null) {
        erros['gabarito'] = 'Gabarito obrigatório';
    }

    return Object.keys(erros).length > 0 ? erros : null
}