import { createSlice } from '@reduxjs/toolkit'

const INITAL_STATE = {
    autenticacao: {
        formError: {},
        loading: false,
        loadingCadastro: false,
        formErrorCadastro: {}
    },
    avaliacaoAluno: null,
    respostasAluno: null
}

const alunoSlice = createSlice({
    name: 'alunoAvaliacaoReducer',
    initialState: INITAL_STATE,
    reducers: {
        changeAutenticacao: (state, action) => {
            if (action.payload) {
                state.autenticacao = { ...state.autenticacao, ...action.payload }
            } else {
                state.autenticacao = INITAL_STATE.autenticacao
            }
        },
        changeAvaliacaoAluno: (state, action) => {
            state.avaliacaoAluno = action.payload ? { ...action.payload } : null
        },
        changeRespostasAluno: (state, action) => {
            state.respostasAluno = action.payload ? { ...action.payload } : null
        }
    },
})


export const { changeAutenticacao, changeAvaliacaoAluno, changeRespostasAluno } = alunoSlice.actions
export default alunoSlice.reducer