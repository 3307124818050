import {
  Alert,
  Button,
  Card,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Header from "../../../components/header/Header";
import { MdGroup, MdModeEditOutline, MdOutlineAdd, MdShare } from "react-icons/md";
import AutoCompleteCustom from "../../../components/AutoCompleteCustom";
import { FaSearch } from "react-icons/fa";
import AvaliacoesServices from "../AvaliacoesServices";
import { useDispatch, useSelector } from "react-redux";
import LoadingData from "../../../components/LoadingData";
import { useNavigate } from "react-router-dom";
import { AVALIACOES_RESPOSTAS, FORM_AVALIACAO, FORM_EDIT_AVALIACAO } from "../../../routes/HashRouter";
import IconButtonCustom from "../../../components/IconButtonCustom";
import { IoMdEye, IoMdTrash } from "react-icons/io";
import { listaEnsinos, listaSeries } from "../reducer";
import { useDebounce } from "../../../hooks/useDebounce";
import ModalConfirmCustom from "../../../components/ModalConfirmCustom";
import AvaliacaoDetalhes from "./AvaliacaoDetalhes";
import { formataData, getDataFormatadaDaDataCompleta } from "../../../utils/formatDate";
import ModalShare from "../../../components/ModalShare/ModalShare";

const Avaliacoes = () => {
  const [descricao, setDescricao] = useState("");
  const [ensino, setEnsino] = useState();
  const [serie, setSerie] = useState();

  const [modalDetalhes, setModalDetalhes] = useState();
  const [itemExcluir, setItemExcluir] = useState();
  const [loadingExcluir, setLoadingExcluir] = useState(false);

  const [modalShare, setModalShare] = useState();
  const [loadingShortUrl, setLoadingShortUrl] = useState(false);

  const loadDebounce = useCallback(
    (text) => {
      load(1, text, ensino?.id, serie?.id);
    },
    [ensino, serie]
  );

  const { setValueDebounce } = useDebounce(loadDebounce);

  const { ensinos, avaliacoes, series } = useSelector((state) => state.avaliacoes);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(listaSeries());
    dispatch(listaEnsinos());
    AvaliacoesServices.getEnsinos(dispatch, { limit: 0 });
    load(1);
  }, []);

  useEffect(() => {
    if (ensino) {
      AvaliacoesServices.getSeries(dispatch, { ensino_id: ensino.id, limit: 0 });
    }
  }, [ensino]);

  const load = (page, descricao, ensino_id, serie_ano_id) => {
    let params = {
      limit: 9,
      page,
    };

    if (descricao) {
      params["descricao"] = descricao;
    }

    if (ensino_id) {
      params["ensino_id"] = ensino_id;
    }

    if (serie_ano_id) {
      params["serie_ano_id"] = serie_ano_id;
    }

    AvaliacoesServices.getAvaliacoes(dispatch, params);
  };

  const renderButton = () => {
    return (
      <Button onClick={() => navigate(FORM_AVALIACAO)} endIcon={<MdOutlineAdd />} variant="contained">
        Cadastrar nova avaliação
      </Button>
    );
  };

  const changeDescricao = (value) => {
    setDescricao(value);
    setValueDebounce(value);
  };

  const changeEnsino = (value) => {
    setEnsino(value);
    setSerie(undefined);
    dispatch(listaSeries());
    load(1, descricao, value?.id);
  };

  const changeSerie = (value) => {
    setSerie(value);
    load(1, descricao, ensino?.id, value);
  };

  const excluir = () => {
    const callback = () => {
      setLoadingExcluir(false);
      setItemExcluir(undefined);
      load(1);
    };

    const callbackError = () => {
      setLoadingExcluir(false);
    };

    setLoadingExcluir(true);

    AvaliacoesServices.excluirAvaliacao(dispatch, { id: itemExcluir.id }, callback, callbackError);
  };

  const gerarLinkEncurtado = (url) => {
    setModalShare(url);
    // const callback = (shortUrl) => {
    //   setModalShare(shortUrl ? shortUrl : url);
    //   setLoadingShortUrl(false);
    // };
    // setLoadingShortUrl(true);
    // AvaliacoesServices.getShortLink(dispatch, { url }, callback);
  };

  const CardAvaliacao = ({ avaliacao }) => {
    return (
      <Grid item md={4} xs={12} sm={6}>
        <Card className="card-avaliacao">
          <div>
            <div className="header">
              <span className="badge">
                {avaliacao.serie_ano.nome} - {avaliacao.ensino.nome}
              </span>
              <span className="badge">{avaliacao.itens.length} item(s)</span>
            </div>
            {!!avaliacao.descricao && (
              <div className="body">
                <div className="title" dangerouslySetInnerHTML={{ __html: avaliacao.descricao }} />
              </div>
            )}
          </div>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", margin: "2px 0px" }}>
            <div>
              <div style={{ fontSize: 12 }}>Criada em:</div>
              <div style={{ fontSize: 12, fontWeight: "bold", marginTop: 3 }}>
                {formataData(new Date(avaliacao.created_at))}
              </div>
            </div>
            <div style={{ textAlign: "end" }}>
              <div style={{ fontSize: 12 }}>Período de aplicação:</div>
              <div style={{ fontSize: 12, fontWeight: "bold", marginTop: 3 }}>
                {getDataFormatadaDaDataCompleta(avaliacao.data_aplicacao_inicio)} a{" "}
                {getDataFormatadaDaDataCompleta(avaliacao.data_aplicacao_fim)}
              </div>
            </div>
          </div>
          <div className="footer">
            <div style={{ fontSize: 12, color: "#666" }}>{avaliacao.respostas_count} RESPOSTA(S)</div>
            <div className="footer-buttons">
              <IconButtonCustom
                style={{ marginRight: 1 }}
                tooltip={"Compartilhar acesso"}
                color={"#4db966"}
                action={() => gerarLinkEncurtado(`${window.location.origin}/avaliacao/${avaliacao.hash}`)}
              // action={() => {
              //     navigator.clipboard.writeText(`${window.location.origin}/avaliacao/${avaliacao.hash}`)
              //     toastr.success("Link copiado")
              // }}
              >
                <MdShare size={19} />
              </IconButtonCustom>
              <IconButtonCustom
                style={{ marginRight: 1 }}
                tooltip={"Respostas alunos"}
                color={"#363a3c"}
                action={() => navigate(AVALIACOES_RESPOSTAS.replace(":id", avaliacao.id))}
              >
                <MdGroup size={19} />
              </IconButtonCustom>
              <IconButtonCustom
                style={{ marginRight: 1 }}
                tooltip={"Visualizar"}
                color={"#9d268c"}
                action={() => setModalDetalhes(avaliacao.id)}
              >
                <IoMdEye size={19} />
              </IconButtonCustom>
              <IconButtonCustom
                style={{ marginRight: 1 }}
                tooltip={"Editar"}
                color={"#19a1bd"}
                action={() => navigate(FORM_EDIT_AVALIACAO.replace(":id", avaliacao.id))}
              >
                <MdModeEditOutline size={19} />
              </IconButtonCustom>
              <IconButtonCustom tooltip={"Excluir"} color={"red"} action={() => setItemExcluir(avaliacao)}>
                <IoMdTrash size={19} />
              </IconButtonCustom>
            </div>
          </div>
        </Card>
      </Grid>
    );
  };

  return (
    <>
      <Header title={"Avaliações"} renderRight={renderButton()} />
      <Card style={{ padding: 8, marginTop: 16 }}>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <div>Buscar por:</div>
          </Grid>
          <Grid item md={12} xs={12}>
            <TextField
              fullWidth
              size="small"
              id="textfield-filtrar-escola"
              label={"Buscar por nome"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <FaSearch size={18} color={"#ccc"} />
                  </InputAdornment>
                ),
              }}
              value={descricao}
              onChange={(e) => changeDescricao(e.target.value)}
            />
          </Grid>
          <Grid item md={7} xs={12}>
            <AutoCompleteCustom
              label={"Selecione ensino"}
              placeholder={"Selecione ensino"}
              onChange={(value) => changeEnsino(value)}
              options={ensinos && ensinos?.dados ? ensinos?.dados : []}
              value={ensino}
              empty={"Nenhum ensino encontrado"}
            />
          </Grid>
          <Tooltip title={!ensino || (series && series?.loading) ? "Para selecionar o ano, selecione um ensino" : ""}>
            <Grid item md={5} xs={12}>
              <FormControl style={{ width: "100%" }} size={"small"}>
                <InputLabel id="test-select-label">{"Selecione o ano"}</InputLabel>
                <Select
                  value={serie ? serie : ""}
                  fullWidth
                  onChange={(val) => changeSerie(val.target.value)}
                  label={"Selecione o ano"}
                  placeholder={"Selecione o ano"}
                  disabled={((series?.loading) || !ensino)}
                  labelId="test-select-label"
                >
                  {series?.dados?.map((item, key) => (
                    <MenuItem key={key} value={item.id}>
                      {item.nome}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Tooltip>
        </Grid>
      </Card>
      <Grid container style={{ marginTop: 0 }} spacing={2}>
        {!avaliacoes?.loading && avaliacoes?.dados?.listAvaliacoes?.length === 0 && (
          <Grid item md={12} xs={12}>
            <Alert color="info">Nenhuma avaliação encontrada</Alert>
          </Grid>
        )}
        {avaliacoes && avaliacoes.dados && avaliacoes.dados.total !== null && (
          <Grid item xs={12} textAlign={"end"}>
            <span className="total-avaliacoes">
              Total: {avaliacoes.dados.total} {avaliacoes.dados.total > 1 ? "avaliações" : "avaliação"}
            </span>
          </Grid>
        )}
        {avaliacoes &&
          avaliacoes.dados &&
          avaliacoes.dados.listAvaliacoes.map((avaliacao, key) => <CardAvaliacao avaliacao={avaliacao} key={key} />)}
        {avaliacoes && avaliacoes.dados && avaliacoes.dados.last_page > avaliacoes.dados.current_page && (
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Button
              style={{ marginTop: 5, marginBottom: 20 }}
              onClick={() => load(avaliacoes.dados.current_page + 1, null, ensino?.id, serie?.id)}
              variant="contained"
              size="small"
              color="info"
            >
              Ver mais
            </Button>
          </Grid>
        )}
        <Grid item md={12} xs={12}>
          {ensinos && <LoadingData msg={"Carregando ensinos..."} obj={ensinos} />}
          {avaliacoes && <LoadingData msg={"Carregando avaliações..."} obj={avaliacoes} />}
        </Grid>
      </Grid>
      <ModalConfirmCustom
        open={!!itemExcluir}
        title={"Deseja excluir esta avaliação?"}
        text="Ao excluir você não terá mais acesso ao mesmo"
        onConfirm={excluir}
        close={() => setItemExcluir(undefined)}
        loading={loadingExcluir}
      />
      <AvaliacaoDetalhes onClose={() => setModalDetalhes(undefined)} id={modalDetalhes} />
      <ModalShare
        isOpen={!!modalShare}
        closeModal={() => setModalShare(undefined)}
        text={"Acesse a avaliação por este link:"}
        url={modalShare}
        loading={loadingShortUrl}
      />
    </>
  );
};

export default Avaliacoes;
