import { Alert, Button, Card, Divider, FormControl, FormControlLabel, FormHelperText, Grid, InputLabel, MenuItem, Select, Switch, TextField, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { MdPlusOne } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AutoCompleteCustom from '../../../../components/AutoCompleteCustom';
import Header from '../../../../components/header/Header';
import InputFileCustom from '../../../../components/InputFileCustom/InputFileCustom';
import { ITENS } from '../../../../routes/HashRouter';
import ItensServices from '../../ItensServices';
import { alteraErro, alteraItem } from '../../reducer';
import FormAlternativa from './FormAlternativa';
import ItemAlternativa from './ItemAlternativa';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import LoadingData from '../../../../components/LoadingData';
import { configCkEditor } from '../../../../config/constants';
import '../../styles/itens.css'

const TitleInput = ({ children }) => {
    return <Typography variant='subtitle1'>{children}</Typography>
}

const Painel = ({ headerVisible = true, onClose = false, onCallback = null, campoSelecionado = null, ensino_id = null, habilidadeSelecionada }) => {

    const descricaoImgRef = useRef()

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [loadingDados, setLoadingDados] = useState(false)

    const [formAlternativeVisible, setFormAlternativeVisible] = useState(false)
    const [loadingSalvaItem, setLoadingSalvaItem] = useState(false)

    const { ensinos, habilidades } = useSelector(state => state.itens)

    let { id } = useParams();

    const {
        item,
        erros,
    } = useSelector(state => state.itens)

    const { user } = useSelector(st => st.global)

    useEffect(() => {
        if (id) {
            setLoadingDados(true)
            ItensServices.getDadosItem(dispatch, { id }, () => setLoadingDados(false))
        }
        if(habilidadeSelecionada){
            dispatch(alteraItem({ habilidade: habilidadeSelecionada }))
        }
    }, [])

    useEffect(() => {
        if (id && item?.professor_id) {
            if (user?.relacionado?.id !== item.professor_id) {
                navigate(ITENS)
            }
        }
    }, [id, item?.professor_id])

    useEffect(() => {
        if (!ensinos) {
            ItensServices.getEnsinos(dispatch, { limit: 0 })
        }
        if(ensinos && ensinos.dados && ensino_id){
            let ensinoAux = ensinos.dados.find(el => el.id === ensino_id)
            dispatch(alteraItem({ ensino: ensinoAux }))
        }
    }, [ensinos])

    useEffect(() => {
        if (item.ensino || ensino_id) {
            let params = { ensino_id: ensino_id? ensino_id: item.ensino.id, limit: 0 }
            if(campoSelecionado){
                params['campo_experiencia_id'] = campoSelecionado
            }
            ItensServices.getHabilidades(dispatch, params)
        }
    }, [item.ensino?.id, ensino_id])

    useEffect(() => {
        if (item.alternativas.length >= 5) {
            setFormAlternativeVisible(false)
        }
    }, [item.alternativas])

    useEffect(() => {
        return () => {
            dispatch(alteraItem())
        }
    }, [])

    const excluir = (index) => {
        if ((index - 1) === parseInt(gabarito)) {
            dispatch(alteraItem({ gabarito: "" }))
        }

        let alternativas_aux = [...alternativas]
        dispatch(alteraItem({ alternativas: alternativas_aux.filter((a, i) => i !== (index - 1)) }))
        dispatch(alteraErro({ ...erros, alternativas: undefined }))
    }


    const voltar = (somenteLoading = false) => {
        setLoadingSalvaItem(false)

        if (onCallback && !somenteLoading) {
            onCallback(habilidade)
        } else {
            if (!somenteLoading) {
                dispatch(alteraItem())
                navigate(ITENS)
            }
        }
    }

    const onSubmit = (e) => {
        e.preventDefault()
        setLoadingSalvaItem(true)
        ItensServices.salvaItem(dispatch, item, voltar);
    }

    const {
        descricao,
        descricaoImg,
        ensino,
        habilidade,
        visibilidade,
        gabarito,
        alternativas
    } = item

    return (
        <>
            {headerVisible &&
                <Header
                    onBack={() => voltar()}
                    title={item.id ? "Alterar item" : "Cadastrar novo item"}
                    tooltip="Voltar para listagem"
                />
            }
            <form onSubmit={onSubmit} >
                {loadingDados &&
                    <Grid container >
                        <Grid item xs={12}>
                            <LoadingData obj={{ loading: loadingDados }} msg="Buscando dados..." />
                        </Grid>
                    </Grid>
                }
                {!loadingDados &&
                    <Card style={!headerVisible ? { padding: '16px' } : { marginTop: 20, padding: '16px', marginBottom: 30 }} >
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={12}>
                                <div className='div-title-questao-item' >
                                    <TitleInput>Descrição:</TitleInput>
                                    <label htmlFor='contained-button-file'>
                                        <Button
                                            component="span"
                                            size='small'
                                            variant='contained'>
                                            {descricaoImg ? `Alterar imagem` : `Adicionar imagem`}
                                        </Button>
                                    </label>
                                    <input
                                        ref={descricaoImgRef}
                                        id={"contained-button-file"}
                                        type="file"
                                        style={{ display: 'none' }}
                                        accept="image/*"
                                        onChange={(e) => dispatch(alteraItem({ descricaoImg: e.target.files[0] }))}
                                    />
                                </div></Grid>
                            {descricaoImg &&
                                <Grid
                                    item xs={12}
                                    md={4}
                                    sm={4}>
                                    <InputFileCustom
                                        remover={() => {
                                            dispatch(alteraItem({ descricaoImg: undefined }))
                                            descricaoImgRef.current.value = null
                                        }}
                                        image={descricaoImg}
                                        name={descricaoImg?.name}
                                    />
                                </Grid>
                            }
                            <Grid
                                className='form-descricao-item'
                                item
                                xs={12}
                                md={descricaoImg ? 8 : 12}
                                sm={descricaoImg ? 8 : 12}>
                                <CKEditor
                                    config={configCkEditor}
                                    editor={ClassicEditor}
                                    data={descricao}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        dispatch(alteraItem({ descricao: data }))
                                    }}
                                />
                                {!!erros['descricao'] && <span style={{ color: 'red', fontSize: 12 }} >{erros['descricao']}</span>}
                            </Grid>
                            <Grid item md={6} sm={6} xs={12}>
                                <TitleInput>Ensino:</TitleInput>
                                <AutoCompleteCustom
                                    disabled={ensino_id ? true : false}
                                    placeholder={"Selecione um ensino"}
                                    onChange={(value) => dispatch(alteraItem({ ensino: value }))}
                                    options={ensinos && ensinos?.dados ? ensinos?.dados : []}
                                    value={ensino || ensino_id}
                                    error={erros['ensino']}
                                    empty={'Nenhum ensino encontrado'}
                                    loading={ensinos && ensinos.loading}
                                />
                            </Grid>
                            <Grid item md={6} sm={6} xs={12}>
                                <TitleInput>Habilidade:</TitleInput>
                                <AutoCompleteCustom
                                    placeholder={"Selecione uma habilidade"}
                                    disabled={!ensino}
                                    onChange={(value) => dispatch(alteraItem({ habilidade: value }))}
                                    options={habilidades && habilidades?.dados ? habilidades?.dados : []}
                                    value={habilidade}
                                    error={erros['habilidade']}
                                    empty={'Nenhuma habilidade encontrado'}
                                    loading={habilidades && habilidades.loading}
                                />
                            </Grid>
                            {alternativas.length === 0 && erros['alternativas'] &&
                                <Grid item xs={12}>
                                    <Alert severity='error' >
                                        {erros['alternativas']}
                                    </Alert>
                                </Grid>
                            }
                            {alternativas.length > 0 &&
                                <Grid item xs={12}>
                                    <div className='div-title-questao-item' >
                                        <TitleInput>Alternativas:</TitleInput>
                                        <FormControl
                                            className='select-alternativa-correta'
                                            sx={{ m: 1, minWidth: 180 }}
                                            size="small"
                                            error={!!erros['gabarito']}>
                                            <InputLabel id="demo-select-small">Alternativa correta</InputLabel>
                                            <Select
                                                labelId="demo-select-small"
                                                id="demo-select-small"
                                                value={gabarito+"" ? gabarito : ""}
                                                label="Alternativa correta"
                                                onChange={e => dispatch(alteraItem({ gabarito: e.target.value }))}>
                                                {alternativas.map((a, key) => {
                                                    return <MenuItem key={key} value={`${key}`}>{key + 1}</MenuItem>
                                                })}
                                            </Select>
                                            {erros['gabarito'] &&
                                                <FormHelperText>{erros['gabarito']}</FormHelperText>
                                            }
                                        </FormControl>
                                    </div>
                                    {
                                        !!erros['alternativas'] &&
                                        <Alert severity="error" style={{ marginBottom: 20 }} >
                                            {erros['alternativas']}
                                        </Alert>
                                    }
                                    {alternativas.map((alternativa, key) => {
                                        return (
                                            <ItemAlternativa
                                                key={key}
                                                excluir={excluir}
                                                correta={key === parseInt(gabarito)}
                                                index={key + 1}
                                                alternativa={alternativa}
                                            />
                                        )
                                    })}
                                </Grid>
                            }
                            {alternativas.length < 5 && !formAlternativeVisible &&
                                <Grid item xs={12}>
                                    <Button
                                        onClick={() => setFormAlternativeVisible(true)}
                                        variant='outlined'
                                        size='small'
                                        color='secondary' >
                                        Adicionar alternativa <MdPlusOne style={{ marginLeft: 5 }} />
                                    </Button>
                                </Grid>
                            }
                            {formAlternativeVisible &&
                                <FormAlternativa
                                    onClose={() => setFormAlternativeVisible(false)}
                                    onSave={(alternativa) => {
                                        dispatch(alteraItem({ alternativas: [...alternativas, alternativa] }))
                                        dispatch(alteraErro({ ...erros, alternativas: undefined }))
                                        // setFormAlternativeVisible(false);
                                    }}
                                />}
                            {alternativas.length === 0 &&
                                <Grid item xs={12}>
                                    <Alert severity="info">
                                        Nenhuma alternativa adicionada até o momento!
                                    </Alert>
                                </Grid>
                            }
                            <Grid item xs={12} style={{ marginTop: 8 }}>
                                <FormControlLabel
                                    control={<Switch checked={visibilidade} onChange={() => dispatch(alteraItem({ visibilidade: !visibilidade }))} />}
                                    label="Deseja tornar este item público? Caso sim, este item fica disponível para uso de outros professores" />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                {onClose &&
                                    <Button
                                        style={{ marginRight: 10 }}
                                        onClick={onClose}
                                        size='small'
                                        disabled={loadingSalvaItem}
                                        variant='contained'
                                        color='inherit'>
                                        FECHAR
                                    </Button>
                                }
                                <Button
                                    type='submit'
                                    size='small'
                                    variant='contained'
                                    disabled={loadingSalvaItem}
                                    color='info'>
                                    {loadingSalvaItem ? 'SALVANDO...' : 'SALVAR'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Card>
                }
            </form>
        </>
    )
}

export default Painel;