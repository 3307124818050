import { validaCPF, validaData, validaEmail, validaTelefone } from "../../../utils/validations";

export const validaLoginAluno = (dados, isCadastro) => {
    let erros = {}

    if (isCadastro) {
        if (!dados.nome) {
            erros['nome'] = 'Nome obrigatório';
        }
        if (!validaEmail(dados.email)) {
            erros['email'] = 'E-mail inválido';
        }
        if (!validaTelefone(dados.telefone)) {
            erros['telefone'] = 'Telefone inválido';
        }
        if (!dados.senha_cadastro) {
            erros['senha_cadastro'] = 'Senha obrigatória';
        }
        if (!validaCPF(dados.cpf_cadastro)) {
            erros['cpf_cadastro'] = 'CPF inválido';
        }
        if (!validaData(dados.data_nascimento)) {
            erros['data_nascimento'] = 'Data de nascimento inválida';
        }
    } else {
        if (!validaCPF(dados.cpf)) {
            erros['cpf'] = 'CPF inválido';
        }
        if (!dados.senha) {
            erros['senha'] = 'Senha obrigatória';
        }
    }

    return Object.keys(erros).length > 0 ? erros : null
}

