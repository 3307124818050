import { Button, Card, Divider, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingData from "../../../components/LoadingData";
import ModalTemplate from "../../../components/modal/ModalTemplate";
import AvaliacoesServices from "../AvaliacoesServices";
import { alteraDetalhes, listaAvaliacoesAlunoItens } from "../reducer";
import { Item } from "../../itens/components/ItemDetalhes";
import ItensServices from "../../itens/ItensServices";
import { getDataFormatadaDaDataCompleta } from "../../../utils/formatDate";
import ContainerItem from "../../../components/ContainerItem";
import { LETRAS_ALTERNATIVAS } from "../../../config/constants";

const AvaliacaoDetalhes = ({ id, onClose, avaliacao_aluno_id }) => {
  const { avaliacaoDetalhes, avaliacoesAlunoItens } = useSelector((state) => state.avaliacoes);

  const [verMais, setVerMais] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      AvaliacoesServices.getDetalhes(dispatch, { id });
    }
  }, [id]);

  useEffect(() => {
    if (avaliacao_aluno_id) {
      AvaliacoesServices.getAvaliacaoAlunoItens(dispatch, { avaliacao_aluno_id, limit: 0 });
    }
  }, [avaliacao_aluno_id]);

  const fechar = () => {
    dispatch(listaAvaliacoesAlunoItens(null));
    setVerMais(false);
    onClose();
  };

  const renderItens = (itens) => {
    if (avaliacao_aluno_id) {
      if (!verMais) {
        return null;
      }
    }

    return itens.map((item, key) => {
      let itemFormat = ItensServices.formataItem(item);
      return (
        <Item
          dados={itemFormat}
          questaoInicial={key + 1}
          key={key}
          resposta_id={avaliacoesAlunoItens?.dados ? avaliacoesAlunoItens.dados[item.id] : undefined}
        />
      );
    });
  };

  const ItemGabarito = ({ index, letra, success }) => {
    let className = success !== undefined ? (!!success ? " sucesso" : " erro") : "";

    return (
      <div className={"div-item-gabarito"}>
        <div style={{ fontSize: 14 }}>{index}</div>
        <div className={"letra" + className}>
          <span>{letra}</span>
        </div>
      </div>
    );
  };

  const renderGabarito = () => {
    if (!avaliacao_aluno_id) {
      return null;
    }

    if (!avaliacaoDetalhes || !avaliacaoDetalhes.dados) {
      return null;
    }

    if (!avaliacoesAlunoItens || !avaliacoesAlunoItens.dados) {
      return null;
    }

    return (
      <>
        <Grid item xs={12} md={6} sm={6}>
          <div style={{ fontWeight: "bold", marginBottom: 10 }}>Respostas:</div>
          <div style={{ display: "flex", flexFlow: "row wrap" }}>
            {dados.itens.map((i, k) => {
              let resposta = avaliacoesAlunoItens.dados[i.id];
              let alternativaAssinalada = i.alternativas.find((a) => a.id === resposta)?.sequencial;
              return (
                <ItemGabarito
                  success={i.item_alternativa_id === resposta}
                  index={k + 1}
                  key={k}
                  letra={LETRAS_ALTERNATIVAS[alternativaAssinalada]}
                />
              );
            })}
          </div>
        </Grid>
        <Grid item xs={12} md={6} sm={6}>
          <div style={{ fontWeight: "bold", marginBottom: 10 }}>Gabarito:</div>
          <div style={{ display: "flex", flexFlow: "row wrap" }}>
            {dados.itens.map((i, k) => {
              let alternativaCorreta = i.alternativas.find((a) => a.id === i.item_alternativa_id)?.sequencial;
              return <ItemGabarito index={k + 1} key={k} letra={LETRAS_ALTERNATIVAS[alternativaCorreta]} />;
            })}
          </div>
        </Grid>
      </>
    );
  };

  let dados = avaliacaoDetalhes && avaliacaoDetalhes.dados;

  return (
    <ModalTemplate
      open={!!id || !!avaliacao_aluno_id}
      closeModal={fechar}
      title={"Detalhes avaliação"}
      desabilitarBotao={true}
    >
      {avaliacaoDetalhes && <LoadingData obj={avaliacaoDetalhes} msg="Buscando dados..." />}
      {avaliacoesAlunoItens && <LoadingData obj={avaliacoesAlunoItens} msg="Buscando respostas..." />}
      <Grid container spacing={1}>
        {dados && Object.keys(dados).length > 0 && (
          <>
            <Grid item xs={12}>
              <ContainerItem title={"Descrição:"}>
                <div style={{ fontSize: 12, color: "#666", marginTop: 5 }}>{dados.descricao}</div>
              </ContainerItem>
            </Grid>
            <Grid item xs={12} md={12} sm={12}>
              <ContainerItem title={"Professor:"}>
                <div style={{ fontSize: 12, color: "#666", marginTop: 5 }}>{dados.professor.nome}</div>
              </ContainerItem>
            </Grid>
            <Grid item xs={12} md={3} sm={3}>
              <ContainerItem title={"Data início:"}>
                <div style={{ fontSize: 12, color: "#666", marginTop: 5 }}>
                  {getDataFormatadaDaDataCompleta(dados.data_aplicacao_inicio)}
                </div>
              </ContainerItem>
            </Grid>
            <Grid item xs={12} md={3} sm={3}>
              <ContainerItem title={"Data final:"}>
                <div style={{ fontSize: 12, color: "#666", marginTop: 5 }}>
                  {getDataFormatadaDaDataCompleta(dados.data_aplicacao_fim)}
                </div>
              </ContainerItem>
            </Grid>
            <Grid item xs={12} md={3} sm={3}>
              <ContainerItem title={"Ensino:"}>
                <div style={{ fontSize: 12, color: "#666", marginTop: 5 }}>{dados.ensino.nome}</div>
              </ContainerItem>
            </Grid>
            <Grid item xs={12} md={3} sm={3}>
              <ContainerItem title={"Ano:"}>
                <div style={{ fontSize: 12, color: "#666", marginTop: 5 }}>{dados.serie_ano.nome}</div>
              </ContainerItem>
            </Grid>
            <Grid item xs={12} style={{ margin: "10px 0px" }}>
              <Divider />
            </Grid>
            {renderGabarito()}
            {avaliacao_aluno_id && (
              <Grid item>
                <Button
                  onClick={() => setVerMais((vermais) => !vermais)}
                  variant="outlined"
                  color="info"
                  size="small"
                  style={{ padding: "4px 5px", margin: "8px 0px" }}
                >
                  {verMais ? "Ocultar detalhes" : "Ver mais detalhes"}
                </Button>
              </Grid>
            )}
            {renderItens(dados.itens)}
          </>
        )}
      </Grid>
    </ModalTemplate>
  );
};

export default AvaliacaoDetalhes;
