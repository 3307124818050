import { FormControl, Grid, InputLabel, MenuItem, Select, Alert } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CardInfo from '../PercentualAcertoErro/CardInfo';
import PercentualAcertoErro from '../PercentualAcertoErro/PercentualAcertoErro';
import DashboardServices from '../../DashboardServices';
import LoadingData from '../../../../components/LoadingData'
import { calcularPorcentagem } from '../../../../utils/Utils';

const CardEnsino = ({ ensino }) => {
    const dispatch = useDispatch()

    const {
        series,
        disciplinas,
    } = useSelector(st => st.dashboard)
    const [serie, setSerie] = useState()
    const [list, setList] = useState(null)
    const [acertosPorAreaConhecimento, setAcertosPorAreaConhecimento] = useState(null)

    useEffect(() => {
        if (!acertosPorAreaConhecimento && ensino) {
            DashboardServices.getAcertosPorAreaConhecimento(dispatch, { ensino_id: ensino.id, limit: 0 }, (res) => {
                setAcertosPorAreaConhecimento(res)
            });
        }
        if (acertosPorAreaConhecimento && acertosPorAreaConhecimento.dados && disciplinas && disciplinas.dados) {
            let listAux = []
            let objAux = {}
            acertosPorAreaConhecimento?.dados.forEach(el => {
                if (!objAux[el.campo_experiencia_id]) {
                    objAux[el.campo_experiencia_id] = el
                }
            });
            disciplinas.dados.forEach(el => {
                if (el?.area_conhecimento?.ensino_id === ensino.id) {
                    let aux = { ...el, resultados: null }
                    if (objAux[el.id]) {
                        aux.resultados = objAux[el.id]
                    }
                    listAux.push(aux)
                }
            })
            if (listAux.length === 0) {
                disciplinas.dados.forEach(el => {
                    if (el?.area_conhecimento === null) {
                        let aux = { ...el, resultados: null }
                        if (objAux[el.id]) {
                            aux.resultados = objAux[el.id]
                        }
                        listAux.push(aux)
                    }
                })
            }
            setList(listAux)
        }
    }, [acertosPorAreaConhecimento?.dados, ensino, disciplinas?.dados])

    useEffect(() => {
        if (serie) {
            let params = { ensino_id: ensino.id, limit: 0 };
            if (serie && serie != -1) {
                params.serie_ano_id = serie
            }
            DashboardServices.getAcertosPorAreaConhecimento(dispatch, params, (res) => {
                setAcertosPorAreaConhecimento(res)
            });
        }
    }, [serie])

    const renderSelect = () => {
        return (series && series.dados &&
            <FormControl
                style={{ width: 140 }}
                size={"small"} >
                <InputLabel id="test-select-label">{'Filtre por série'}</InputLabel>
                <Select
                    value={serie ? serie : '-1'}
                    fullWidth
                    onChange={(val) => {
                        setSerie(val.target.value)
                    }}
                    label={"Visibilidade de item"}
                    placeholder={"Visibilidade de item"}
                    labelId="test-select-label">
                    <MenuItem value={-1}>Todos</MenuItem>
                    {series.dados.filter(s => s.ensino_id === ensino.id).map((item, key) => <MenuItem key={key} value={item.id}>{item.nome}</MenuItem>)}
                </Select>
            </FormControl >
        )
    }

    return (
        <Grid item xs={12} md={12} sm={12}>
            <CardInfo
                title={`${ensino.nome} (acertos x erros)`}
                rightTitle={renderSelect}>
                <Grid container spacing={2} style={{ marginTop: 0 }} >
                    {acertosPorAreaConhecimento && !acertosPorAreaConhecimento.loading && list && list.map((disciplina, key) => {
                        let { acerto, erro } = calcularPorcentagem(parseInt(disciplina?.resultados?.acertos), parseInt(disciplina?.resultados?.erros))
                        return (disciplina.resultados &&
                            <Grid key={key} item xs={12} md={3} sm={3}>
                                <PercentualAcertoErro
                                    title={disciplina.nome}
                                    acerto={acerto}
                                    erro={erro} />
                            </Grid>
                        )
                    })}
                    {acertosPorAreaConhecimento && <LoadingData obj={acertosPorAreaConhecimento} msg={"Buscando acertos por area de conhecimento..."} />}
                </Grid>
                {list && list.length > 0 && list.every(el => !el.resultados) &&
                    <Alert severity="info" sx={{ marginTop: 1 }}>Não há avaliações respondidas para este ensino!</Alert>
                }
            </CardInfo>
        </Grid >
    )
}

export default CardEnsino;