
import React, { useEffect, useState } from 'react';
import { MdSpaceDashboard, MdKeyboardArrowUp, MdKeyboardArrowDown, MdOutlineClose, MdArticle, MdFactCheck, MdFormatListNumbered } from 'react-icons/md';
import { BsDot } from 'react-icons/bs';
import { ListItemButton, ListItemIcon, ListItemText, Collapse, List, Container, Drawer, IconButton, Icon, Avatar } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { FaSignOutAlt, FaUserCircle } from "react-icons/fa";
import Grid from '@mui/material/Grid';
import { useLocation } from 'react-router-dom';
import { KEY_USER_BNCC_APP, VERSAO_WEB } from '../../config/GlobalKeys'
import { NOME_PERFIL } from '../../config/constants'
import BreadCrumb from '../../components/BreadCrumb';
import Nav from './Nav';
import toastr from 'toastr'
import { useDispatch, useSelector } from 'react-redux';
import useLocalStorage from '../../hooks/useLocalStorage';
import { AVALIACOES, DASHBOARD, ITENS, ROOT, PERFIL } from '../../routes/HashRouter';
import { setUser } from '../../reducers/global'

import './main.css';


export default function Main({ path, children }) {

    const [showMenu, setShowMenu] = useState(false);
    const location = useLocation();
    const { search } = useLocation();
    const [openSubItens, setOpenSubItens] = useState({});
    const { user } = useSelector(state => state.global)

    const { remove } = useLocalStorage()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const showSubItens = (campo) => {
        setOpenSubItens({ ...openSubItens, [campo]: !openSubItens[campo] })
    };

    const navItems = [
        {
            primary: "Dashboard",
            path: DASHBOARD,
            icon: <MdSpaceDashboard />
        },
        {
            primary: "Banco de itens",
            path: ITENS,
            icon: <MdFormatListNumbered />
        },
        {
            primary: "Avaliações",
            path: AVALIACOES,
            icon: <MdArticle />
        },
    ]

    useEffect(() => {
        setSubmenu()
    }, []);

    const setSubmenu = () => {
        let opensub_aux = {}
        navItems.forEach(item => {
            if (item.subItens) {
                opensub_aux[item.primary] = false
            }
        })
        setOpenSubItens(opensub_aux)
    }

    const logout = () => {
        remove(KEY_USER_BNCC_APP);
        dispatch(setUser(null))
        toastr.success('Usuário deslogado!')
        navigate(ROOT)
    }

    const navigateProfile = () => {
        navigate(PERFIL)
        setShowMenu(false)
    }

    return (
        <>
            <Nav onOpen={() => setShowMenu(true)} />
            <Drawer
                style={{ zIndex: 99999, }}
                open={showMenu}
                onClose={() => setShowMenu(false)}>

                <div className='menu-drawer'>
                    <div className='drawer-header'>
                        <div>
                            <span>Menu</span>
                        </div>
                        <IconButton onClick={() => setShowMenu(false)} >
                            <MdOutlineClose />
                        </IconButton>
                    </div>
                    <div className='menu-drawer-user' onClick={navigateProfile}>
                        <Avatar src={user?.relacionado?.thumb} style={{ marginRight: 10 }} size={25} />
                        <div>
                            <div style={{ fontWeight: 'bold' }} >{user?.relacionado?.nome}</div>
                            <div style={{ fontSize: 12 }} >{NOME_PERFIL[user?.perfil]}</div>
                        </div>
                    </div>
                    <div>

                        {navItems.map((item, index) => (
                            <List component="div" key={index} disablePadding>
                                {!item.link
                                    ?
                                    <Link
                                        to={item.path === '' ? (location.pathname + search) : item.path}
                                        key={index}
                                        className='item-navegacao'
                                        onClick={() => {
                                            !item.subItens ? setShowMenu(false) : showSubItens(item.primary)
                                        }}>
                                        <ListItemButton selected={location.pathname === item.path} >
                                            <ListItemIcon>
                                                {item.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={item.primary} />
                                            {item.subItens ? openSubItens[item.primary] ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown /> : null}
                                        </ListItemButton>
                                    </Link>
                                    :
                                    <a href={item.path} style={{ textDecoration: 'none' }} target='_blank' rel="noreferrer" key={index} className='item-navegacao' onClick={() => setShowMenu(false)}>
                                        <ListItemButton>
                                            <ListItemIcon sx={{ maxWidth: '19px', height: '19px' }}>
                                                {item.icon}
                                            </ListItemIcon>
                                            <ListItemText primary={item.primary} />
                                        </ListItemButton>
                                    </a>}
                                {item.subItens &&
                                    <Collapse in={openSubItens[item.primary]} timeout="auto" unmountOnExit>
                                        {user && item.subItens.filter(i => i.perfil.includes(parseInt(user.perfil))).map((subItem, i) => (
                                            <Link to={subItem.path} key={i} className='item-navegacao' onClick={() => setShowMenu(false)}>
                                                <ListItemButton selected={location.pathname === subItem.path} sx={{ pl: 5 }}>
                                                    <ListItemIcon sx={{ minWidth: '30px' }}>
                                                        <BsDot />
                                                    </ListItemIcon>
                                                    <ListItemText primary={subItem.primary} />
                                                </ListItemButton>
                                            </Link>
                                        ))}
                                    </Collapse>}
                            </List>
                        ))}
                        <ListItemButton >
                            <ListItemIcon>
                                <FaSignOutAlt />
                            </ListItemIcon>
                            <ListItemText primary="Sair" action='true' onClick={() => logout()} />
                        </ListItemButton>
                        <Grid
                            container
                            sx={{ position: 'absolute', left: '33%', bottom: 10 }}>
                            Versão {VERSAO_WEB}
                        </Grid>
                    </div>
                </div>
            </Drawer>
            <Container className='container-bncc' maxWidth="xl"  >
                <div style={{ marginTop: 75, position: 'relative' }}>
                    <BreadCrumb path={path} />
                    {children}
                </div>
            </Container>
        </>
    );
}