import React from 'react';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import './styles.css'

const Header = ({ title, renderRight = null, onBack, tooltip }) => {

    const ButtonVoltar = () => {
        return (
            <Box sx={{ marginRight: 2 }} >
                <Tooltip title={tooltip ? tooltip : ""}>
                    <Button variant="contained"
                        sx={{ minWidth: '35px', maxWidth: '35px', minHeight: '35px', maxHeight: '35px', padding: '0px 0px' }}
                        onClick={onBack}>
                        <MdKeyboardArrowLeft size={30} />
                    </Button>
                </Tooltip>
            </Box>
        )
    }

    return (
        <div className='header-bncc'>
            <div className='header-div-title'>
                {onBack && <ButtonVoltar />}
                <Typography style={{ fontWeight: 'bold' }} variant='h5'>{title}</Typography>
            </div>
            {renderRight}
        </div >
    )
}

export default Header;